
/*=============================================
=            sidebar            =
=============================================*/

.sidebar {
  .widget {
    &__title {
      font-weight: 600;

      margin-bottom: 25px;

      letter-spacing: 0.3px;
      text-transform: capitalize;
    }

    &__categories {
      li {
        padding-bottom: 10px;

        list-style: none;
        a,
        button {
          display: block;

          width: 100%;
          padding: 0;

          text-align: left;
          text-transform: capitalize;

          border: none;
          background: none;
          .categories-num {
            font-size: 14px;

            float: right;
          }
          svg {
            margin-right: 5px;
          }
          &:hover,
          &.active {
            color: $theme-color--default;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &__sizes {
      li {
        display: inline-block;
        button {
          font-size: 14px;
          line-height: 28px;

          height: 32px;
          margin-right: 3px;
          margin-bottom: 3px;

          cursor: pointer;
          text-align: center;
          text-transform: uppercase;

          border: 2px solid $grey--two;
          background: none;
          &:hover,
          &.active {
            color: $white;
            border-color: $theme-color--default;
            background-color: $theme-color--default;
          }
        }
      }
    }

    &__colors {
      display: flex;
      li {
        button {
          font-size: 12px;
          line-height: 15px;

          position: relative;

          width: 15px;
          height: 15px;
          margin: 5px;
          padding: 0;

          cursor: pointer;
          vertical-align: middle;

          border: none;
          border-radius: 100%;
          &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            visibility: hidden;

            margin: -3px;

            content: "";
            transition: all 0.3s ease-in-out;

            opacity: 0;
            border: 2px solid #fff;
            border-radius: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          }

          &:hover,
          &.active {
            &:before {
              visibility: visible;

              opacity: 1;
            }
          }
        }
      }
    }

    & + .widget {
      margin-top: 30px;
      padding-top: 30px;

      border-top: 1px solid $grey--two;
    }

    &__tags {
      a,
      button {
        font-size: 14px;

        display: inline-block;

        margin-right: 5px;
        margin-bottom: 5px;
        padding: 8px 15px;

        color: $black--four;
        border: none;
        background: none;
        background-color: $grey--five;
        &:hover,
        &.active {
          color: $white;
          background-color: $theme-color--default;
        }
      }
    }

    .shop-banner {
      position: relative;

      display: table;

      margin: 0 auto;
    }

    .shop-bn-content2 {
      position: absolute;
      bottom: 20px;
      left: 20px;

      .shop-subtitle,
      .shop-title {
        color: $white;
      }
      .shop-title {
        font-weight: 700;
      }
    }
  }

  .widget-product-post {
    display: flex;

    margin-bottom: 15px;
    &__image {
      flex-basis: 80px;

      margin-right: 10px;
    }

    &__content {
      flex-basis: calc(100% - 90px);
      .product-title {
        font-size: 14px;
        font-weight: 500;
      }

      .product-price {
        font-weight: 600;

        color: $theme-color--default;

        del {
          font-size: 14px;
          font-weight: 400;

          margin-left: 5px;

          color: $black--three;
        }
      }

      .rating-wrap {
        svg {
          color: $yellow--one;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}



/*=====  End of sidebar  ======*/

