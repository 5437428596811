.blockelement .tag-rec,
.blockelement .tag-rec-selected {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 15px;
  transition: initial !important;
}

.table-scrollable {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}


.error input::placeholder {
  text-align: center;
}
.grid-brand,
.tvar {
  text-decoration: none;
}
*,
.content-list li {
  box-sizing: border-box;
}
.ellipsis,
.result-span,
.workshop-custom-h1,
.your-location-area {
  text-overflow: ellipsis;
}
.car-brand-scroll > div:first-child,
.scroll-brand > div:first-child,
.scroll-model > div:first-child {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
body {
  overflow: visible !important;
  background-color: #fff;
  color: #000;
}
.blink-service .listing-container-pl {
  animation-name: blink-service;
  animation-duration: 0.5s;
  animation-iteration-count: 3;
  background-color: #f3f4ff;
}
.action-bar-ob a,
.brand_block div strong,
.earning-container,
.super-car-faq-row.super-car-active p,
.super-car-obc-forms > div.super-car-active,
.super-car-radio-box input:checked ~ .super-car-checkmark:after {
  display: block;
}


.popover__title {
    font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
    padding: 15px 0;
  }
  
  .popover__wrapper {
    position: relative;
    margin-top: 1.5rem;
    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -150px;
    transform: translate(0, 10px);
    background-color: #bfbfbf;
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
  }
  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #bfbfbf transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
  
  .req-an-btn-tyre{
    text-align: center;
  }
  .req-an-btn-tyre-inactive{
    background: rgb(87, 112, 139) !important;
    border: 0;
  border-radius: 20px;
  padding: 7px 25px;
    color: #fff;
    outline: 0;
    margin-right: 10px;
  }

@keyframes blink-service {
  0%,
  50%,
  90% {
    background-color: #fff;
  }
  100%,
  25%,
  75% {
    background-color: #f3f4ff;
  }
}
.glare-animation-activeEstimate:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: 2s linear infinite shine;
}
.choose-car {
  justify-content: center;
  align-items: center;
}
.tag-rec {
  transition: 1s;
}
.tag-rec-selected {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 10px;
  transition: 1s;
  color: #0451be;
  border-bottom: 3px solid #0451be;
}
.blockelement .tag-rec {
  color: #000;
  background: #fff;
  margin: 0 3px;
  border-radius: 3px 3px 0 0 !important;
}
.blockelement .tag-rec-selected {
  color: #fff;
  margin: 0 3px 0 0;
  border-radius: 3px 3px 0 0;
  background: #0451be;
}
.book-inspection-button {
  font-size: 13px !important;
}
@media only screen and (min-width: 320px) {
  .workshop-button,
  .workshop-button-one-active {
    flex-direction: row;
    cursor: pointer;
    display: flex;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .filter-text,
  .mobile-card-sub,
  .workshop-button-one {
    font-size: 11px;
  }
  .workshop-button-one-active {
    height: 30px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #000;
    transition: 1s;
    font-size: 11px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .location,
  .location-mobile {
    z-index: 1;
    flex-direction: column;
    position: absolute;
    background: #fff;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 22px;
  }
  .mobile-sub,
  .top-sell-text {
    font-size: 12px;
  }
  .mobile-card-head,
  .offer-text {
    font-size: 14px;
  }
  .offer-price {
    font-size: 15px;
  }
  .google-text {
    font-size: 10px;
  }
  .header-all {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all {
    font-size: 13px;
    font-weight: 400;
    color: #848484;
  }
  .customWidth {
    width: 280px;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
    font-size: 14px;
    color: #848484;
  }
  .location {
    width: 260px;
    height: 120px;
    top: 45px;
    border-top-right-radius: 10px;
  }
  .location-mobile {
    width: 100%;
    top: 0;
    padding-top: 20px;
  }
  .workshop {
    position: absolute;
    z-index: 6;
    background: #fff;
    width: 260px;
    height: 95px;
    top: 145px;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
    transition: 0.5s;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -17px;
  }
  .right-arrow {
    right: -17px;
  }
  .discount-text {
    font-size: 14px;
    font-weight: 400;
  }
  .user-text {
    font-size: 12px;
    font-weight: 400;
  }
}
@media only screen and (min-width: 480px) {
  .prmtxt label {
    font-size: 30px;
  }
  .prmtxt {
    padding-top: 0;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 25px;
  }
  .mobile-card-sub,
  .mobile-sub {
    font-size: 14px;
  }
  .mobile-card-head,
  .offer-price {
    font-size: 16px;
  }
  .offer-text {
    font-size: 15px;
  }
  .filter-text {
    font-size: 12px;
  }
  .google-text {
    font-size: 11px;
  }
  .header-all {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all {
    font-size: 15px;
    font-weight: 400;
    color: #848484;
  }
  .customWidth {
    width: 300px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 280px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
    font-size: 14px;
    color: #848484;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 118px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -20px;
  }
  .right-arrow {
    right: -20px;
  }
  .discount-text {
    font-size: 16px;
    font-weight: 400;
  }
  .user-text {
    font-size: 14px;
    font-weight: 400;
  }
  .top-sell-text {
    font-size: 13px;
  }
}
.mb-view {
  display: none;
}
.for-mobile,
.mb-dwd {
  display: none !important;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
  }

@media only screen and (min-width: 768px) {
 
  .workshop-list-text {
    font-size: 11px;
    font-weight: 700;
    color: #fff;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 27px;
  }
  .mobile-sub {
    font-size: 17px;
  }
  .mobile-card-head {
    font-size: 20px;
  }
  .mobile-card-sub,
  .offer-text,
  .top-sell-text {
    font-size: 15px;
  }
  .offer-price {
    font-size: 16px;
  }
  .filter-text,
  .google-text {
    font-size: 12px;
  }
  .header-all {
    font-size: 28px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all,
  .text-type {
    font-size: 15px;
    color: #848484;
  }
  .subHead-all {
    font-weight: 400;
  }
  .customWidth {
    width: 330px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 300px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 115px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -37px;
  }
  .right-arrow {
    right: -37px;
  }
  .discount-text {
    font-size: 15px;
    font-weight: 400;
  }
  .user-text {
    font-size: 13px;
    font-weight: 400;
  }
}
.workshop-bottom-button-custom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 0 #000;
}
.center,
.logo-checkout-page,
.workshop-center,
.workshop-map-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-input {
  border: 1px solid #979393 !important;
}
.common-input-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 45px;
  font-size: 14px;
  margin: 10px 0;
  padding: 0 12px;
}

.expireQuotation{
    color: red;
    font-size: 14px;
    font-weight: 500 !important;
  
}
.reactivateQuotation{
    font-weight: 500;
   border-radius: 30px;
    color: white;
    font-size: 14px;
   
   justify-content: center;
    align-items: center;
    border: none;
    background:linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%); ;
}


.ServiceSelectionBox .login-input,
.login-input-custom {
  border: 1px solid #ccc !important;
}
.login-input-custom {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.island-box-scroll-bar {
  height: calc(100vh - 70px) !important;
  overflow-y: scroll;
  padding-bottom: 50px;
}
.island-box-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.island-box-scroll-bar::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.island-box-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #000;
}
.bg-yellow {
  border-color: #ff3201 !important;
}
.bg-yellow.srv-act-action {
  border: 0;
}
@media (max-width: 767px) {
  .req-an-btn-tyre{
    font-size: 13px !important;
  }
  .subline:before {
    top: -30px;
  }
  .reactivateQuotation{
    margin-left: 13px;
  }
}
@media (max-width: 480px) {
  .super-car-35,
  .super-car-ob-garage-col img {
    margin-bottom: 0 !important;
  }
  .super-car-ob-garage-col,
  .super-car-ob-partner,
  .super-car-ob-super-charge-box,
  .tpinnercell {
    margin-left: 0 !important;
  }
  .super-car-slide-heading h1 {
    left: 0 !important;
    top: 20px !important;
    font-size: 25px !important;
  }
  .order-page-slick-slider .slick-next {
    right: -5px !important;
  }
  .order-page-slick-slider .slick-track {
    left: 0 !important;
  }
  .order-page-slick-slider .slick-prev {
    left: -9px !important;
    z-index: 5;
  }
  .order-page-slick-slider .slick-next:before,
  .order-page-slick-slider .slick-prev:before {
    font-size: 25px !important;
  }
  .form-img h4,
  .super-car-slide-heading.text-left,
  .ul-tag-list li {
    margin-bottom: 20px;
  }
  .super-car-book-input {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .super-car-center-text,
  .super-car-mobile-center {
    text-align: center;
  }
  .super-car-35 {
    font-size: 35px !important;
    padding-top: 20px;
    line-height: 43px;
  }
  .super-car-ob-super-garage {
    margin-top: 30px !important;
  }
  .super-car-mobile-ob-hyperion h1,
  .super-car-ob-partner h2,
  .super-car-super-charge {
    font-size: 36px !important;
  }
  .super-car-ob-about h1 {
    font-size: 24px !important;
  }
  .super-car-ob-partner {
    margin-bottom: 20px;
  }
  .first-circle {
    left: 20px !important;
    top: 30px;
  }
  .second-circle {
    top: 30px !important;
    right: 15px !important;
    left: auto !important;
  }
  .third-circle {
    top: 0 !important;
  }
  .forth-circle {
    left: 10px !important;
    top: 150px !important;
  }
  .fifth-circle {
    right: 10px !important;
    top: 150px !important;
  }
  .super-car-ob-garage-col .col-sm-4 {
    max-width: 96% !important;
  }
  .galaxy-circle .super-car-circle {
    width: 80px !important;
    height: 80px !important;
    font-size: 10px !important;
    line-height: 11px !important;
  }
  .super-car-service-guide {
    margin: 20px !important;
  }
  .galaxy-circle {
    margin-top: 50px !important;
  }
  .super-car-bottom-box {
    margin-bottom: 30px !important;
    margin-top: 0 !important;
  }
  .super-car-service-circle-3 {
    margin-top: 77px !important;
  }
  .super-car-service-circle-4 {
    margin-top: 100px !important;
  }
  .super-car-service-circle p {
    font-size: 12px !important;
  }
  .super-car-service-circle-6 {
    margin-top: 60px !important;
  }
  .super-car-service-circle-7 {
    margin-top: 53px !important;
    margin-left: -62px;
  }
  .ob-jobs.row {
    margin-right: 0 !important;
  }
  .ob-jobs-3 {
    margin-left: 0;
    margin-right: 0;
  }
  .super-car-obj-1 button {
    margin-left: 25px;
  }
  .service-circle-1.service-circle {
    height: 50px;
  }
  .super-car-obj-1 {
    margin-left: -15px;
  }
  .super-car-service-circle-5 {
    margin-left: 15px;
  }
  .super-car-service-circle {
    margin-top: 29px !important;
  }
  .super-car-service-circle-2::before,
  .super-car-service-circle-3::before {
    width: 100vw !important;
    height: 100vh !important;
    left: -122px !important;
    right: 0 !important;
    background-size: contain !important;
    top: -111px !important;
  }
  .super-car-service-circle-3::before {
    top: -110px !important;
    margin-left: 2px;
  }
  .ob-jobs h2 {
    margin-left: 10px;
    font-size: 25px !important;
  }
  .super-car-ob-brands ul li {
    width: 27%;
  }
  .super-car-custom-border {
    border-bottom: 1px solid #ccc;
    border-right: none !important;
  }
  .super-car-common-heading ~ div {
    padding: 10px !important;
  }
  .list-guid-super-car,
  .ul-tag-list {
    flex-direction: column;
  }
  .super-car-ob-brands h4,
  .super-car-ob-brands p {
    padding: 15px;
  }
  .super-car-mobile-ob-hyperion {
    padding-top: 30px !important;
  }
  .super-car-oba-box button {
    font-size: 14px !important;
  }
  .super-car-circle {
    font-size: 13px !important;
  }
  .ob-mobile-super-car {
    font-size: 16px !important;
    line-height: 20px;
  }
  .mobile-car-book {
    padding: 0 20px !important;
  }
  .subline:before {
    top: -43px;
  }
  .my-car-button {
    padding-left: 40px !important;
  }
  .ul-tag-list-offer {
    column-count: 1 !important;
  }
  .form-div-offers {
    display: none;
  }
  .ul-tag-list li {
    margin: 0 auto 20px !important;
    width: 250px;
  }
  .thank-you h5 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .offer-main {
    margin-top: 40px !important;
  }
  .form-div .form-img {
    height: auto !important;
  }
  .choose-para {
    width: 100% !important;
  }
  .form-img {
    padding-left: 15px;
  }
  .custom-bottom-footer {
    padding: 15px 0 90px;
  }
  .cs-pagination .pagination a {
    padding: 2px 8px !important;
  }
  .cs-pagination .pagination li {
    margin-right: 4px;
  }
  .dashboard .job-history-card [class^="col-"] {
    padding-left: 15px !important;
  }
}
.check-address-modal,
.mobile-car-book,
.super-car-ob-car-types.super-car-ob-un-exp ul,
.super-car-ob-car-types.super-car-policy-cover.super-car-uae-boxes
  .super-car-blue-circle,
.super-car-ob-hyperion .super-car-col-sm-6,
.super-car-surf-quote-box ul {
  padding: 0;
}
.pay-now-btn:hover {
  background: #ff3201 !important;
}
.Otp-Button-request:hover {
    background: #ff3201 !important;
  }

.proceed-btn {
  display: flex;
  align-items: center;
  border: 0;
}
@media only screen and (max-width: 550px) {
  .levelup-promo .item-small h5 {
    font-size: 15px;
  }
  .arrow-active:before {
    font-size: 15px !important;
    bottom: -4px !important;
  }
  .levelup-promo .item-small .item-img {
    padding: 14px;
    height: 80px;
    width: 80px;
  }
  .levelup-promo .rounded-item {
    height: 250px;
    width: 250px;
  }
  .levelup-promo .rounded-item .text-blue {
    margin-bottom: 5px;
  }
  .levelup-promo .rounded-item h3 {
    font-size: 23px;
  }
  .levelup-promo .rounded-item .otp-button {
    padding: 10px;
    height: 38px;
  }
  .levelup-row button {
    height: 35px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .level-up-bg h1 {
    font-size: 30px;
  }
}

.missingCar{
  margin-top: 15px;
    color:#0451be ;
    text-decoration: underline;
    text-align: center !important;
  }

@media only screen and (max-width: 480px) {
  .mobile-view-google-head {
    margin-left: 25px;
    margin-top: 20px !important;
  }
  #review .google-text span,
  .mobile-content-list li,
  .workshop-distance {
    font-size: 12px !important;
  }
  .cart-header .car-img,
  .cart-list .description,
  .desktop.rowMap.workshop,
  .get-home,
  .input-search,
  .level-up-box.desktop-box,
  .location .car-icon,
  .mobile-mechanice-description,
  .ob-login,
  .product-time,
  .req-btn,
  .sos,
  .top-selling-card .product-discount,
  .workshop-tag-choice span {
    display: none;
  }
  .hide-in-mobile {
    display: none !important;
  }
  .for-mobile,
  .offer .service-contract-offer {
    display: block !important;
  }
  .main-bg {
    background: #fff !important;
    padding-top: 50px !important;
  }
  .checkout-page-main-div {
    padding: 50px 0 !important;
  }
  .main-heading-checkout {
    padding-top: 5px;
  }
  .new-pickdrop {
    padding-left: 10px;
  }
  .whtsapp-field {
    padding-left: 30px !important;
  }
  #review,
  .common_box {
    padding: 10px !important;
  }
  .label_text {
    margin: 5px 0;
  }
  .main_box {
    border-bottom: 10px solid #ccc;
  }
  .level-up-box img,
  .mobile-sm,
  .nav-brand-logo {
    width: 120px;
  }
  .total-logo {
    width: 65px;
  }
  .menu-bars {
    display: flex;
  }
  .location .columnMap,
  .offer .car-detail,
  .offer .offer-text {
    width: 100% !important;
  }
  .location {
    width: 210px;
    height: 100px;
  }
  .mobile.rowMap.workshop .inputMap,
  .workshop {
    height: auto;
  }
  .workshop {
    width: 210px;
    top: 145px;
  }
  .grey-bar,
  .service-items {
    margin-top: 10px;
  }



  #review .review-title,
  .level-up-box.mobile-box,
  .mobile.rowMap.workshop,
  .sos-btn-mobile,
  .sos-mob {
    display: block;
  }
  .slick-slide .service-buttons img {
    height: 40px;
    object-fit: contain;
  }
  .slick-slide .service-buttons .service-name {
    font-size: 12px !important;
    line-height: 12px;
  }
  .cart-mobile-btn {
    display: block;
    position: fixed;
    bottom: 10px;
    border: none;
    background: #000;
    color: #fff;
    padding: 8px 25px;
    width: 90%;
    border-radius: 30px;
    box-shadow: 0 0 20px 0 #000;
  }
  .cart-bottom-area,
  .cart-view,
  .level-up-box,
  .timeline,
  .workshop-bar.change-garage {
    width: 100%;
  }
  .cart-header {
    width: 100%;
    min-height: 90px;
  }
  .timeline {
    margin-top: 80px;
  }
  .cart-list title {
    margin-bottom: 0;
  }
  .workshop-list-text,
  .workshop-page .amenties-label,
  .workshop-price-difference {
    font-size: 11px;
  }
  .tag-rec {
    font-size: 11px !important;
    margin: 0 6px;
  }
  .filter-text {
    margin-right: 6px;
  }
  .tag-rec-selected {
    font-size: 11px !important;
    margin: 0 4px;
  }
  .cart-list .title {
    font-size: 14px !important;
  }
  .cart-list .logo {
    width: 60px;
    height: 60px;
  }
  .cart-list .cost {
    margin-left: 3% !important;
    font-size: 13px;
    font-weight: 600;
  }
  .level-up-box.mobile-box .title {
    font-size: 15px;
  }
  .cart-bottom-area .title,
  .total-pay-area h5 {
    font-size: 17px;
  }
  .cart-bottom-area .title .vat-extra,
  .map-alert p,
  .ob-highlight h5,
  .pickup-drop-area .price {
    font-size: 12px;
  }
  .cart-bottom-area p {
    font-size: 10px;
  }
  .how-ob h3,
  .proceed-btn {
    font-size: 20px;
  }
  .cart-list-addons .workshop-button-one {
    padding: 10px 30px;
  }
  .cart-list-addons img {
    height: 40px;
    width: 40px;
  }
  .service-buttons {
    height: 100px !important;
    margin-left: 0;
    margin-right: 0;
  }
  .categories-box a,
  .checkout-login-section {
    padding: 10px;
  }
  .img.top-selling,
  .recommend-image-container > .img {
    height: 180px;
  }
  #recommend .item-head,
  .top-selling-head {
    padding: 5px;
  }
  .top-selling-card .overlay {
    height: 80%;
  }
  .top-selling-card .top-selling-details .top-sell-text {
    font-size: 13px !important;
  }
  .top-selling-details {
    padding: 2px;
  }
  #topSelling {
    margin-bottom: 20px !important;
  }
  #review {
    margin-bottom: 0 !important;
  }
  .google-head {
    margin-top: 0 !important;
  }
  .google-text {
    min-height: auto !important;
  }
  #review .google-logo {
    width: 30%;
    left: 0;
    top: 45px;
  }
  #review .google-text {
    font-size: 14px;
    line-height: 16px;
  }
  .req-btn-mob {
    display: block;
    margin-top: 10px;
  }
  .mobile-padding-remove,
  .my-orders,
  .offer {
    padding: 0 !important;
  }
  .partner {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .brand-logo {
    height: 80px;
    width: 80px;
  }
  .footLogo,
  img.product-image-container {
    height: 100px;
    width: 100px;
  }
  .footLogo {
    top: -50px;
  }
  .product-discount {
    font-size: 10px;
    top: 100%;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: inline-block;
  }
  .quote-amount,
  .quote-count {
    right: auto;
    top: auto;
    position: relative;
  }
  .pickup-drop-area {
    padding-left: 5px;
    font-size: 13px;
  }
  .order-sub-text,
  .small-font {
    font-size: 13px;
  }
  .oba-container {
    padding: 10px;
    display: none;
  }
  .oba-container.mobile-view {
    display: block;
    height: auto;
  }
  .oba-image-center {
    width: 40%;
  }
  .mobile-mechanice-img {
    transform: rotate(90deg);
    display: none;
  }
  .mobile-mechanice-title {
    padding: 0 !important;
    width: 100%;
  }
  .mobile-mechanice-title h2 {
    display: block;
    text-align: center;
  }
  .mobile-mechanice-main-title {
    width: 100%;
    justify-content: center !important;
  }
  .offer-text li {
    line-height: 18px;
    margin-bottom: 13px;
  }
  .map-alert {
    width: 90%;
    left: 5%;
    padding: 5px;
  }
  .account-box {
    min-height: 100%;
  }
  .service-buttons-active {
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  .package-service-head {
    font-size: 16px;
  }
  .package-service-btn {
    font-size: 13px;
    padding: 5px 10px;
  }
  .your-mobile-location {
    display: block;
    font-size: 14px;
  }
  .your-mobile-location .badge {
    border-radius: 20px;
    padding: 5px 10px;
    background: #ff3201;
    color: #fff;
  }
  .b3g1 .preview-img {
    width: 60%;
  }
  .ob-highlight {
    border: none;
    padding: 7px;
    margin-bottom: 5px;
    flex-direction: column;
  }
  .ob-highlight h3 {
    font-size: 15px;
    margin-top: 10px;
  }
  .ob-highlight .img {
    height: 45px !important;
    width: 45px !important;
  }
  .main-banner .search-box {
    position: initial;
    width: auto;
  }
  .main-banner .search-box h1 {
    letter-spacing: 1px;
  }
  .location-change-popup.mobile-view .car-icon {
    width: 20% !important;
  }
  .location-change-popup.mobile-view .search-area {
    width: 80% !important;
  }
  .main-banner {
    margin-top: 0;
  }
  .show-on-map {
    background: #a5a5a5;
    color: #000;
  }
  .workshop-map-data {
    width: 100%;
    bottom: 20px;
  }
  .workshop-services {
    overflow: hidden;
  }
  .workshop-head-img {
    height: 100px !important;
  }
  .mulkia-img {
    width: 50%;
  }
  .personal-details {
    margin: 0;
  }
  .how-ob img {
    height: 200px;
    object-fit: contain;
  }
  .table-scroll {
    width: 100%;
    overflow: scroll;
  }
  .workshop-image {
    width: 100px !important;
  }
  .workshop-page h1 {
    font-size: 27px;
  }
  .for-mobile.flex {
    display: flex !important;
  }
}
@media only screen and (max-width: 400px) {
  .total-logo {
    width: 55px;
  }
  .nav-brand-logo {
    width: 115px;
  }
  .levelup-promo .item-small h5 {
    font-size: 12px;
  }
}
.auth-validation-custom-span {
  position: absolute;
  font-size: 12px;
  left: 15px;
  bottom: -9px;
}
.auth-validation-mft-span {
  position: absolute;
  font-size: 12px;
  left: 15px;
  bottom: 23px;
}
.auth-validation-custom-span-em {
  position: absolute;
  font-size: 12px;
  left: 15px;
  bottom: -17px;
}
@media (max-width: 989px) {
  .auth-validation-mft-span {
    bottom: 0;
  }
}
@media (max-width: 768px) {
  .book-inspection-button {
    font-size: 13px !important;
  }
  .inspection-report-container-head {
    justify-content: center !important;
  }
  .bee-row-content:not(.no_stack) {
    display: block;
  }
  .bee-row-1 .bee-col-1 .bee-block-8 {
    text-align: center !important;
    font-size: 23px !important;
  }
  .bee-row-1 .bee-col-1 .bee-block-4,
  .bee-row-1 .bee-col-1 .bee-block-5,
  .bee-row-1 .bee-col-1 .bee-block-7,
  .bee-row-2 .bee-col-1 .bee-block-1 {
    padding: 5px !important;
  }
  .bee-row-1 .bee-col-1 .bee-block-7 .bee-separator,
  .bee-row-2 .bee-col-1 .bee-block-1 .bee-separator {
    margin: auto !important;
    float: right !important;
  }
  .bee-row-1 .bee-col-1 .bee-block-3,
  .bee-row-1 .bee-col-1 .bee-block-6 {
    padding: 10px !important;
  }
  .bee-row-1 .bee-col-1 .bee-block-6 img,
  .bee-row-2 .bee-col-2 .bee-block-1 img {
    float: none !important;
    margin: 0 auto !important;
  }
  .bee-row-1 .bee-col-1 .bee-block-3 h1 {
    font-size: 26px !important;
  }
  .bee-row-2 .bee-col-1 .bee-block-2,
  .bee-row-2 .bee-col-1 .bee-block-2 .bee-button-content {
    text-align: center !important;
  }
  .bee-row-2 .bee-col-2 .bee-block-1 {
    padding: 0 !important;
  }
}
.discount-text-new,
.phone,
.phone-num,
.simple_text,
.whatsapp-num {
  font-size: 14px;
  font-weight: 400;
}
.offer-banner,
.pay-card-block {
  align-items: center;
  display: flex;
}
.ServiceSelectionBox .phone-number-field::placeholder {
  font-weight: 400;
  color: #7f7f7f;
}
.offer-banner {
  justify-content: center;
  flex-direction: column;
}
.pdf-card .Consumables-div .font-weight-bold,
.sub-heading_text {
  font-weight: 600 !important;
}
.captalize-txt-ck,
.text-transform-capitalize {
  text-transform: capitalize;
}
.payment-area p {
  color: #3c444a;
  font-weight: 500;
}
.pay-card-block {
  gap: 4px;
}
.common_box {
  height: -webkit-fit-content;
}
.quote-request-btn {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
html {
  overflow-x: hidden;
}
.estimate .modal-dialog {
  max-width: 800px !important;
}
.disabled-pagination {
  cursor: not-allowed;
  opacity: 0.5;
}
.table-header {
  background-color: #ccc;
  padding: 10px;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
.table-row {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
}
.table-cell {
  border: none;
  padding: 10px;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
}
.header,
.ob-button {
  align-items: center;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  border-bottom: 2px solid #ff3201 !important;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 999;
}
.center-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cart-mobile-btn,
.checkblc,
.hide,
.info-hisoryicon,
.level-up-box.mobile-box,
.login-head > .ob-login,
.mb-dwd,
.menu-bars,
.mobile-mechanice-title h2,
.mobile.rowMap.workshop,
.none,
.ob-button button,
.ob-button:hover .your-location,
.oba-container.mobile-view,
.phone,
.phone > .phone-num,
.req-btn-mob,
.show-section-mobile,
.super-car-email-form,
.super-car-faq-row p,
.super-car-ob-hyperion .super-car-owl-theme .super-car-owl-nav,
.super-car-obc-forms > div,
.super-car-pop-box,
.super-car-pop-custo-info,
.super-car-slider-owl-box.super-car-owl-theme .super-car-owl-nav,
.whatsapp > .whatsapp-num,
.your-mobile-location {
  display: none;
}
.bdg-txt-block,
.desktop.rowMap.workshop,
.line_box,
.login-head,
.phone:hover > .phone-num,
.review-sub-title,
.super-car-ptlb-left,
.super-car-row-km-box,
.whatsapp:hover > .whatsapp-num {
  display: flex;
}
.phoneP:hover > .phone,
.phonePL:hover > .phoneL {
  display: block;
  width: 100%;
  transition: 0.25s;
}
.phoneP > .phone,
.phonePL > .phoneL {
  display: none;
  width: 0;
  transition: 1s;
}
.phoneP:hover > .phone {
  margin-left: 3px;
}
.ob-point {
  font-size: 11px;
  font-weight: 400;
  color: #000;
}
.ob-value {
  font-size: 15px;
  font-weight: 700;
  color: #0451be;
}
.welcome-font {
  font-size: 10px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
}
.username {
  font-size: 15;
  font-weight: 500;
  color: #000;
}
.ob-login {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.account-box .slick-slider .selected-car p,
.account-box p.brand,
.dashboard .relationship-summary .total p,
.my-orders .slick-slider .selected-car p,
.ob-button,
.ob-login:hover,
.ob-name,
.text-custom-blue,
.thank-you .steps .active small {
  color: #0451be;
}
.login-head:hover > .ob-login {
  display: flex;
  animation-name: slideInLeft 1s all;
  animation-delay: 0.5s;
}
.ob-name {
  font-size: 15px;
  font-weight: 500;
}
.ob-button {
  border: 2px solid #0451be;
  border-radius: 30px;
  padding: 5px 14px 7px;
  font-size: 16px;
  font-weight: 400;
  transition: 1s;
  display: flex;
}
.service-buttons-deactivate,
.service-buttons-land {
  height: 120px;
  width: 120px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 19px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
}
.ob-button:hover {
  background: #0451be;
  color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}
.ob-button button {
  border-radius: 20px;
  padding: 3px 18px;
  margin-left: 5px;
}
.ob-button:hover button {
  border-radius: 20px;
  padding: 6px 18px;
}
.service-buttons-deactivate {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.005);
}
.service-buttons-land {
  justify-content: center;
}
.service-buttons-active,
.service-buttons-land-active,
.service-buttons-land:hover {
  width: 120px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 19px;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  height: 120px;
  display: flex;
}
.service-buttons-land:hover {
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.005);
}
.service-buttons-active,
.service-buttons-land-active {
  transition: 0.2s ease-in-out;
  justify-content: center;
  background-color: #f3f4ff;
}
.service-buttons-active > span,
.service-buttons-land-active > span,
.service-buttons-land:hover > span,
.service-buttons:hover > span {
  color: #ff3201;
  transition: 0.25s;
}
.service-buttons-land:hover,
.service-buttons:hover {
  transition: 0.25s;
  transform: scale(1.1);
  border: 1px solid #0451be;
}
.service-button1 > span,
.service-buttons-land > span,
.service-buttons > span {
  color: #000;
  transition: 1s;
}
.service-buttons-land > .imgSe,
.service-buttons > .imgSe {
  width: 42px;
  transition: 1s;
}
.service-buttons {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  height: 120px;
  border-radius: 7px;
  margin: 10px 14px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.005);
}
.grow {
  transition: 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.service-button:hover > .imgSe {
  width: 55px;
  transition: 0.25s;
}
.service-button1:hover > span {
  color: #0451be;
  transition: 0.5s;
}
.service-button1 > .imgSer {
  width: 95px;
  transition: 1s;
}
.service-button1:hover > .imgSer {
  width: 110px;
  transition: 0.25s;
}
.rent-car-st-open {
  height: 250px;
  display: "flex";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 25px 25px;
}
.google-card,
.google-container,
.recommend-card,
.recommend-card-new,
.recommend-container,
.recommend-container-new {
  height: auto;
  border-radius: 30px;
  transition: 1s;
  width: 100%;
}
.job-head {
  position: relative;
  padding: 15px;
}
.job-head:hover {
  transition: 0.25s;
  padding: 7px;
}
.item-head-new {
  position: relative;
  transition: 1s;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}
.recommend-image-container-new {
  position: relative;
  border-radius: 30px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s;
}
.recommend-discount-new {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 10px;
}
.item-head {
  position: relative;
  transition: 1s;
  padding: 30px;
}
.recommend-image-container > .img {
  position: relative;
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.item-head:hover {
  border: 3px solid #0451be;
  border-radius: 30px;
  transition: 0.25s;
  padding: 15px;
}
.google-discount,
.recommend-discount,
.top-selling-discount {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 25px;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 10px;
}
.workshop-list .image-area {
  width: 122px;
}
.workshop-list .image-area .recommend-discount {
  padding: 2px 5px;
  font-size: 12px;
}
.google-user,
.recommend-user,
.top-selling-user {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10px;
  min-width: 70px;
}
.card-why,
.cardRev,
.recom1,
.sell-card {
  position: relative;
}
.recommend-user.right {
  right: 0;
  margin-right: 10px;
}
.recom1 {
  border-radius: 30px;
  height: 280px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.15s;
  padding-bottom: 10px;
}
.rec-selcted1 {
  height: 290px;
  background: #0451be;
}
.sell-card,
.top {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.rec-selcted1:hover > .recom1 {
  transform: scale(1.1);
  transition: 0.25s;
}
.rec-selcted1:hover {
  border-radius: 30px;
  transition: 0.25s;
  background: #0451be;
  margin: "50px";
}
.sell-card {
  border-radius: 27px;
  height: 300px;
  transition: 0.15s;
}
.sell-selcted:hover {
  border: 4px solid #0451be;
  border-radius: 30px;
  width: 100%;
  transition: 0.25s;
}
.sell-selcted {
  margin: 10px;
  width: 100%;
}
.card-offer,
.top-selcted {
  margin: 15px;
}
.top {
  border-radius: 29px;
  height: 280px;
}
.top-selcted:hover {
  border: 3px solid #0451be;
  border-radius: 30px;
}
.card-offer {
  height: 300px;
  width: 100px;
  padding: 20px;
}
.small-card {
  width: 250px;
  background: #f0f0f0;
  padding: 8px;
  margin: 5px;
  box-shadow: 0 4.64117px 18.5647px rgba(158, 158, 158, 0.25);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}
.cardRev,
.cardRev:hover {
  width: 100%;
  overflow: visible;
  z-index: 999;
}
.cardRev {
  background: #fff;
  border-radius: 27px;
  height: 310px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  margin: 10px;
  cursor: pointer;
  transition: 0.7s;
  padding: 25px;
}
.grid-brand,
.scroll-vertical {
  overflow-y: scroll;
}
.cardRev:hover {
  height: 320px;
  bottom: 10px;
  transition: 0.15s;
  border: 3px solid #0451be;
}
.partner-card {
  box-shadow: 0 4px 16px rgba(158, 158, 158, 0.25);
  background: #fff;
  height: 180px;
  width: 200px;
  border-radius: 25px;
}
.button-offer-one:hover,
.button-offer:hover,
.card-why {
  box-shadow: 0 4.64117px 18.5647px rgba(158, 158, 158, 0.25);
}
.button-offer {
  background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  text-align: center;
  color: #fff;
  border-radius: 30px;
  padding: 8px 20px;
  border: none;
  transition: 1s;
  font-weight: 500;
  font-size: 20px;
}
.button-offer-one,
.button-submit {
  height: 40px;
  border-radius: 20px;
  margin: 5px 10px;
  transition: 1s;
  text-align: center;
}
.account-box .info p,
.font-size-14,
.label_text,
.location-view div,
.map-alert p,
.order-item .info p,
.round-sos,
.round-sos-map,
.why-text {
  font-size: 14px;
}
.button-submit {
  width: 80%;
  background: #fff200;
  color: #000;
  padding: 6px;
}
.button-offer-one {
  width: 100%;
  background: #000;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-offer:hover {
  border: 2px solid #000;
  color: #000;
  transition: 0.25s;
}
.button-offer-one:hover {
  border: 2px solid #fff200;
  transition: 0.25s;
}
.card-app {
  width: "100%";
  background: #e6f1f9;
  border-radius: 18px;
  padding: 26px;
  display: flex;
  flex-direction: column;
}
.card-why,
.workshop-mobile {
  display: flex;
  flex-direction: column;
}
.card-why {
  height: 100%;
  width: 100%;
  background: center/cover no-repeat #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-height: 400px;
}
.blue-screen {
  height: 50%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.location-mobile,
.workshop-mobile {
  position: absolute;
  z-index: 6;
  background: #fff;
  width: 150px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  left: 0;
}
.workshop-mobile {
  top: 192px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 3px solid #0451be !important;
}
.column-brand,
.location-mobile {
  flex-direction: column;
  display: flex;
}
.location-mobile {
  top: 80px;
}
.round-sos,
.round-sos-map,
.sos,
.sos-map {
  position: fixed;
}
.sos {
  top: 250px;
  z-index: 998;
}
.round-sos {
  top: 265px;
  z-index: 999;
  background: #f8d213;
  height: 55px;
  width: 55px;
  border-radius: 35px;
  border: 4px solid #e9e9e9;
  color: "#fff";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.round-sos:hover {
  background: #ff3201;
  transition: 0.35s;
}
.sos-map-class {
  height: 100px;
  width: 100%;
  color: "#000";
  margin-top: 100px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.choose-car {
  margin-top: 91px;
  height: 100vh !important;
  width: 100%;
  top: -20px !important;
  background: #fff;
  color: "#000";
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 9;
  justify-content: center;
  align-items: center;
}
.google-card,
.google-card-custom {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  cursor: pointer;
}
.grid-brand {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  max-height: 100%;
  cursor: pointer;
}
.column-brand {
  justify-content: center;
  align-items: center;
  width: 30%;
}
.column-brand:hover {
  border: 2px solid #0451be;
  border-radius: 18px;
  color: #000;
}
.column-brand-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: 2px solid #0451be;
  background: #0451be;
  color: #fff;
  border-radius: 18px;
}
.round-sos-map,
.user-image {
  width: 55px;
  transition: 1s;
}
.user-image:hover {
  width: 40px;
  transition: 0.25s;
}
.text_circle:after,
.text_circle_offer:after {
  height: 3px;
  width: 50%;
  display: block;
  z-index: -1;
  content: "";
}
.sos-map {
  top: 120px;
  z-index: 996;
}
.round-sos-map {
  top: 165px;
  z-index: 999;
  background: #0451be;
  height: 55px;
  border-radius: 35px;
  border: 4px solid #e9e9e9;
  color: "#fff";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.location-map,
.workshop-map {
  z-index: 6;
  flex-direction: column;
  width: 100%;
  background: #fff;
  display: flex;
}
.round-sos-map:hover {
  background: #034571;
  transition: 0.35s;
}
.location-map {
  position: relative;
  height: 120px;
  transition: 0.5s;
  border-top-right-radius: 10px;
}
.workshop-map {
  height: 95px;
  border-bottom-right-radius: 10px;
  border-top: 3px solid #0451be !important;
  transition: 0.5s;
}
.inputMap,
.inputMap-map {
  border: none;
  height: 35px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
}
.columnMap {
  display: flex;
  flex-direction: column;
}
.bBorder {
  border-bottom: 2px solid grey !important;
}
.title-anim {
  animation-name: slideInLeft;
  animation-delay: 1s;
}
.google-main {
  padding: 10px;
  transition: 0.5s;
}
.google-card {
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.google-card:hover {
  padding-top: 30px;
  padding-bottom: 30px;
  border: 3px solid #0451be;
  transition: 0.25s;
}
.google-card-custom {
  width: 100%;
  background: #fff;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.google-main:hover {
  border: 1px solid #0451be;
  border-radius: 30px;
  padding: 10px;
  transition: 0.25s;
}
.partner-slide {
  height: "200px";
  width: "340px";
  padding: 15px;
  border-radius: 20px;
  border-radius: "15px";
  justify-content: "center";
  align-items: "center";
  display: "flex";
  flex-direction: "column";
  box-shadow: 0 4px 12px rgba(158, 158, 158, 0.25);
  margin: 15px;
}
.top-selling-card,
.top-selling-container {
  height: auto;
}
.top-selling-card,
.top-selling-container,
.top-selling-image-container {
  width: 100%;
  border-radius: 10px;
  transition: 1s;
}
.top-selling-head {
  position: relative;
  transition: 0.75s;
  padding: 15px;
}
.top-selling-image-container {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}
.google-image-container > img,
.top-selling-image-container > img {
  position: relative;
  width: 100%;
}
.top-selling-head:hover {
  border-radius: 10px;
  transition: 0.25s;
  padding: 5px;
  border: 3px solid #0451be;
}
.top-selling-details {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  border-radius: 30px;
  padding: 7px;
  margin-left: 5px;
  z-index: 2;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  border-radius: 10px;
  background: linear-gradient(to top, #000, #231f2000);
}
.brand-logo {
  height: 110px;
  width: 110px;
  border-radius: 70px;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  transition: 1s;
}
.footLogo {
  position: absolute;
  top: -100px;
  left: 40px;
  width: 151px;
  height: 151px;
  z-index: 4;
  border-radius: 100px;
  background: #fff;
}
.footer-subscribe,
.footer-subscribe-mobile {
  width: 100%;
  background: #0451be;
  border-radius: 30px;
  display: flex;
}
.footer-subscribe {
  align-items: center;
  justify-content: center;
}
.footer-subscribe-mobile {
  padding: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.footer-button-subscribe,
.footer-button-subscribe-mobile {
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.footer-input-mobile {
  height: 50px;
  width: 60%;
  background: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-button-subscribe-mobile {
  height: 50px;
  width: 30%;
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.footer-button-subscribe,
.footer-input {
  height: 50px;
  border-radius: 30px;
  width: 100%;
  display: flex;
}
.footer-input {
  background: #fff;
  justify-content: flex-start;
  align-items: center;
}
.footer-button-subscribe {
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);;
  justify-content: center;
  align-items: center;
  border: none;
}
.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.foot-text {
  color: #231f20;
  font-size: 18px;
  font-weight: 400;
}
.google-head {
  position: relative;
  transition: 0.75s;
  padding: 45px 30px;
}
.google-image-container,
.recommend-image-container {
  position: relative;
  border-radius: 30px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}
.google-head:hover {
  border-radius: 30px;
  transition: 0.25s;
  padding-top: 0;
  padding-bottom: 0;
}
.sos-menu-class {
  height: 100%;
  width: 100%;
  background: #fff;
  color: "#000";
}
.product-content {
  font-size: 12px;
  font-weight: 400;
  padding: 3px 10px;
}
.tag-rec {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  margin: 0 3px;
}
.workshop-button-one {
  height: 30px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  cursor: pointer;
  transition: 1s;
}
.workshop-button-one.active {
  background: #fff;
  color: #000;
  transition: 1s;
}
.workshop-bottom {
  height: 50px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workshop-button-one.workshop-select-btn {
  border: 1px solid #000;
  padding: 12px 35px;
  border-radius: 16px;
}
.product-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.oba-white-background {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: url("/assets/oba/back.png") center/cover;
}
.oba-container {
  background: #0451be;
  height: 300px;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 57px;
}
.oba-image {
  width: 65px;
}
.oba-text {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}
.oba-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.change-location {
  position: absolute;
  border-radius: 10px;
  transition: 0.5s;
  box-shadow: 0 0 5px 0 #00000073;
  z-index: 6;
  background: #fff;
  width: 340px;
  right: 0;
  top: 70px;
}
.change-location .workshop-area {
  border-top: 3px solid #0451be !important;
}
@media only screen and (min-width: 320px) {
  .workshop-button,
  .workshop-button-one-active {
    flex-direction: row;
    cursor: pointer;
    display: flex;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .filter-text,
  .mobile-card-sub,
  .workshop-button-one {
    font-size: 11px;
  }
  .workshop-button-one-active {
    height: 30px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #000;
    transition: 1s;
    font-size: 11px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .location,
  .location-mobile {
    z-index: 1;
    flex-direction: column;
    position: absolute;
    background: #fff;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 22px;
  }
  .mobile-sub,
  .top-sell-text {
    font-size: 12px;
  }
  .mobile-card-head,
  .offer-text {
    font-size: 14px;
  }
  .offer-price {
    font-size: 15px;
  }
  .google-text {
    font-size: 10px;
  }
  .header-all {
    font-size: 20px !important;
    font-weight: 600;
    color: #000;
  }
  .subHead-all {
    font-size: 13px;
    font-weight: 400;
    color: #848484;
  }
  .customWidth {
    width: 280px;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
    font-size: 14px;
    color: #848484;
  }
  .location {
    width: 260px;
    height: 120px;
    top: 45px;
    border-top-right-radius: 10px;
  }
  .location-mobile {
    width: 100%;
    top: 0;
    padding-top: 20px;
  }
  .workshop {
    position: absolute;
    z-index: 6;
    background: #fff;
    width: 260px;
    height: 95px;
    top: 145px;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
    transition: 0.5s;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -17px;
  }
  .right-arrow {
    right: -17px;
  }
  .discount-text {
    font-size: 14px;
    font-weight: 400;
  }
  .user-text {
    font-size: 12px;
    font-weight: 400;
  }
}
.cart-view,
.location-details {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 480px) {
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .common-font-size-home {
    font-size: 25px !important;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 25px;
  }
  .mobile-card-sub,
  .mobile-sub {
    font-size: 14px;
  }
  .mobile-card-head,
  .offer-price {
    font-size: 16px;
  }
  .offer-text {
    font-size: 15px;
  }
  .filter-text {
    font-size: 12px;
  }
  .google-text {
    font-size: 11px;
  }
  .header-all {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all {
    font-size: 15px;
    font-weight: 400;
    color: #848484;
  }
  .customWidth {
    width: 300px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 280px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
    font-size: 14px;
    color: #848484;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 118px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }

 

  .rent-car-text {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -20px;
  }
  .right-arrow {
    right: -20px;
  }
  .discount-text {
    font-size: 16px;
    font-weight: 400;
  }
  .user-text {
    font-size: 14px;
    font-weight: 400;
  }
  .top-sell-text {
    font-size: 13px;
  }
  .header-fix-common {
    margin-top: -107px !important;
  }
}
@media only screen and (min-width: 768px) {
  .workshop-list-text {
    font-size: 11px;
    font-weight: 700;
    color: #fff;
  }
  .offer-banner {
    height: calc(100vh - 14px) !important;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 27px;
  }
  .mobile-sub {
    font-size: 17px;
  }
  .mobile-card-head {
    font-size: 20px;
  }
  .mobile-card-sub,
  .offer-text,
  .top-sell-text {
    font-size: 15px;
  }
  .offer-price {
    font-size: 16px;
  }
  .filter-text,
  .google-text {
    font-size: 12px;
  }
  .header-all {
    font-size: 28px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all,
  .text-type {
    font-size: 15px;
    color: #848484;
  }
  .subHead-all {
    font-weight: 400;
  }
  .customWidth {
    width: 330px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 300px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 115px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -37px;
  }
  .right-arrow {
    right: -37px;
  }
  .discount-text {
    font-size: 15px;
    font-weight: 400;
  }
  .user-text {
    font-size: 13px;
    font-weight: 400;
  }
}
@media only screen and (min-width: 992px) {
  .workshop-list-text {
    font-size: 11px;
    font-weight: 700;
    color: #fff;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 170px;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
    margin-bottom: 10px;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one {
    font-size: 13px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head {
    font-size: 30px;
  }
  .mobile-sub,
  .offer-text {
    font-size: 16px;
  }
  .mobile-card-head {
    font-size: 18px;
  }
  .mobile-card-sub {
    font-size: 15px;
  }
  .offer-head {
    font-size: 29px;
  }
  .offer-price,
  .top-sell-text {
    font-size: 17px;
  }
  .filter-text,
  .google-text {
    font-size: 14px;
  }
  .header-all {
    font-size: 30px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all,
  .text-type {
    font-size: 17px;
    color: #848484;
  }
  .subHead-all {
    font-weight: 400;
  }
  .customWidth {
    width: 430px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 320px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 97px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .google-left {
    margin-left: 200px;
  }
  .google-width {
    width: 86%;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -37px;
  }
  .right-arrow {
    right: -37px;
  }
  .discount-text {
    font-size: 18px;
    font-weight: 400;
  }
  .user-text {
    font-size: 15px;
    font-weight: 400;
  }
}

.choose-tyre-brand{
   /* height: px; */
   width: 162px;
   font-size: 12px;
}

@media only screen and (min-width: 1200px) {
  .workshop-list-text {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
  }
  .product-header-text {
    font-size: 22px;
    font-weight: 600;
  }
  .product-header {
    border-top: 10px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-image-container {
    width: 170px;
    height: 170px;
    border-radius: 10px;
    background: #f5f5f5;
    margin-bottom: 10px;
  }
  .product-service {
    font-size: 17px;
    font-weight: 700;
  }
  .product-service-container {
    padding: 10px;
  }
  .product-content-image {
    width: 23px;
    height: 23px;
    padding: 2px;
  }
  .workshop-button {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: #0451be;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .workshop-count {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background: #fff;
    color: #0451be;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    gap: 10px;
    margin-right: 5px;
  }
  .workshop-button-one:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    transition: 0.25s;
  }
  .rowMap,
  .workshop-row {
    display: flex;
    flex-direction: row;
  }
  .product-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-head,
  .offer-head {
    font-size: 30px;
  }
  .filter-text,
  .mobile-sub {
    font-size: 16px;
  }
  .mobile-card-head,
  .offer-price {
    font-size: 18px;
  }
  .mobile-card-sub {
    font-size: 15px;
  }
  .offer-text {
    font-size: 17px;
  }
  .google-text {
    font-size: 14px;
  }
  .header-all {
    font-size: 32px;
    font-weight: 600;
    color: #000;
  }
  .subHead-all {
    font-size: 19px;
    font-weight: 400;
    color: #848484;
  }
  .customWidth {
    width: 430px;
  }
  .location,
  .workshop {
    position: absolute;
    background: #fff;
    width: 340px;
    left: 0;
    display: flex;
    transition: 0.5s;
  }
  .text-type {
    font-weight: 700;
    border-style: none;
    font-size: 17px;
    color: #848484;
  }
  .location {
    z-index: 10;
    height: 120px;
    top: 83px;
    flex-direction: column;
    border-top-right-radius: 10px;
  }
  .workshop {
    z-index: 6;
    height: 95px;
    top: 205px;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #0451be !important;
  }
  .location-header {
    font-size: 14px;
    color: #888;
    font-weight: 400;
  }
  .location-image {
    width: 100%;
  }
  .location-target {
    width: 25px;
  }
  .location-change {
    font-size: 12px;
    font-weight: 400;
    color: #1400ff;
  }
  .oba-image-center {
    width: 180px;
  }
  .google-left {
    margin-left: 200px;
  }
  .google-width {
    width: 86%;
  }
  .left-arrow-selling,
  .right-arrow-selling {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
  }
  .rent-car-text {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .rent-car-text-sub {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .left-arrow-selling {
    left: -25px;
    border: none;
    background: 0 0;
  }
  .right-arrow-selling {
    right: -25px;
    border: none;
    background: 0 0;
  }
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 30%;
    border: none;
    background: 0 0;
  }
  .left-arrow {
    left: -37px;
  }
  .right-arrow {
    right: -37px;
  }
  .discount-text {
    font-size: 18px;
    font-weight: 400;
  }
  .user-text {
    font-size: 15px;
    font-weight: 400;
  }
  .top-sell-text {
    font-size: 19px;
  }
}
.button-map,
.map-tag {
  height: 40px;
  font-weight: 500;
}
.left-arrow,
.right-arrow {
  z-index: 1;
}
.faq-model {
  width: 100%;
  border-radius: 30px;
  background: #0451be;
  padding-bottom: 10px;
}
.faq-q,
.faq-q-a {
  padding: 13px;
  margin: 15px 25px;
}
.faq-q {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 0.25px solid #fff;
  transition: 1s;
}
.faq-q-a,
.map-tag {
  border-radius: 20px;
}
.faq-q-a {
  align-items: center;
  border: 0.25px solid #fff;
  transition: 0.25s;
}
.map-tag,
.why-container {
  padding: 20px;
  display: flex;
}
.why-container {
  align-items: center;
  justify-content: center;
  position: relative;
}
.location-details {
  width: 100%;
  background: #fff;
}
.button-map,
.logo-close {
  background: #ff3201;
  color: #fff;
}
.logo-map {
  border-radius: 15px;
  border: 1px solid grey;
}
.map-tag {
  width: 100%;
  border: 1px solid #000;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 6px;
}
.button-map {
  border-radius: 20px;
  padding: 13px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo-close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px 8px;
  border-radius: 25px;
  z-index: 10;
}
.cart-view {
  height: 88%;
  bottom: 0;
  width: 40%;
  right: 0;
  background: #fff;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.workshop-header {
  font-size: 16px;
  font-weight: 600;
}
#review,
.arrow-active,
.bee-row,
.bee-row-content,
.buttonBox,
.change-garage,
.custom-color .rldiv,
.custom-color .slick-current,
.ob-jobs,
.searcblock,
.sticker-container,
.super-car-ob-contact-forms-box .super-car-container,
.super-car-ob-refer .super-car-container,
.workshop-main-head {
  position: relative;
}
.workshop-tag-sponsored {
  font-size: 10px;
  font-weight: 700;
  padding: 5px 16px;
  border: 1px solid #000;
  color: #000;
  border-radius: 15px;
  position: absolute;
  top: 0;
  right: 6px;
}
.workshop-tag-assure,
.workshop-tag-choice {
  font-size: 10px;
  font-weight: 700;
  padding: 5px 16px;
  border-radius: 18px;
  position: absolute;
  top: 0;
  right: 6px;
  color: #fff;
}
.workshop-tag-assure {
  background: #0451be;
}
.workshop-tag-choice {
  background: #098d2e;
}
.workshop-distance {
  font-size: 12px;
  font-weight: 400;
}
.tag-button {
  height: 25px;
  width: 100%;
  font-size: 8px;
  font-weight: 500;
  padding: 15px;
  border-radius: 15px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
}
.workshop-list {
  display: flex;
  flex-direction: row;
  background: #fff;
  width: 100%;
}
.workshop-bar {
  border-bottom: 1px solid #000;
  width: 100%;
  
}
.workshop-bottom-button,
.workshop-bottom-button-custom {
  height: 50px;
  width: 30%;
  background: #0451be;
  cursor: pointer;
  margin: 0 5%;
  border-radius: 50px;
  position: fixed;
  display: flex;
}
.workshop-bottom-button {
  bottom: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 0 #000;
}
.workshop-bottom-button-custom {
  color: #fff;
  bottom: 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 7px 0 #0000009c;
}
.workshop-select {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.workshop-select-ob {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-left: 6px;
  margin-right: 6px;
}
.workshop-main-header {
  position: fixed;
  top: 90px;
  width: 100%;
  background: #fff;
  z-index: 999;
}
.workshop-map-data {
  position: fixed;
  bottom: 80px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.down-arrow {
  padding: 5px;
  background: #d9d9d9;
  height: 25px;
  border-radius: 10px;
}
.workshop-map-card {
  background: #fff;
  width: 100%;
}
.workshop-map-bottom {
  height: 40px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.offCanvas {
  background: red;
}
.workshop-sidebar {
  position: fixed;
  z-index: 980;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#greyOut,
#greyText,
.tooltip {
  position: absolute;
}
.login-input {
  width: 100%;
  height: 45px;
}
.login-input-datePicker {
  width: 100%;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.otp-button {
  min-height: 45px;
  border-radius: 25px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 6px 15px;
}

.verify-otp{
  min-height: 45px;
  border-radius: 25px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 6px 15px;
background-color: #57708b;
}

.verify-otp-orange{
  min-height: 45px;
  border-radius: 25px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 6px 15px;
background:linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
;
}

.bee-row-1 .bee-col-1 .bee-block-2,
.bee-row-1 .bee-col-1 .bee-block-4,
.bee-row-1 .bee-col-1 .bee-block-5,
.bee-row-1 .bee-col-1 .bee-block-7,
.bee-row-1 .bee-col-1 .bee-block-8,
.bee-row-2 .bee-col-1 .bee-block-1,
.common_info_div,
.repairTag,
.search-bar-inside,
.tooltip,
.tpsm-flex {
  padding: 10px;
}
.otp-text-input {
  width: "50px";
  height: "50px";
  margin: "10px";
}
.profile-sidebar-button,
.profile-sidebar-button-active {
  height: 50px;
  cursor: pointer;
}
.marker-repair {
  background: linear-gradient(transparent 50%, #000 50%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 200% 0;
  transition: 2.5s;
}
.marker-repair:hover {
  background-position: 100% 0;
  transition: 2.5s;
}
.repairTag {
  background: #ff3201;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
}
.repairTag:hover,
.super-car-ins-button.super-car-slide-button.super-car-blue-bg {
  background: #0451be;
}
.tooltip {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}
.profile-sidebar-button-active,
.profile-sidebar-button:hover {
  background: #fff;
  border-right: 3px solid #0451be;
}
#greyOut {
  display: block;
  width: 100%;
  height: 255px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
}
#greyText {
  top: 130px;
  left: 50%;
  z-index: 2;
  font-size: 30px;
  color: #fff;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.profile-sidebar-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.firstOffer{
    /* opacity: 0.6; */
    /* margin: 5px 36px; */
    width: 50px;
    height: 50px;
    border:none;
    background: rgb(33 186 232 / 59%);
    border-radius: 50%;
    color: white;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
}

.secondOffer{
    border: none;
    /* margin: 5px 5px; */
    width: 50px;
    height: 50px;
    background: rgb(253 146 221 / 95%);
    border-radius: 50%;
    color: white;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
}


/* {
    margin: 5px 5px;
    width: 59%;
    height: 84%;
    background: rgb(252, 75, 199);
    border-radius: 50%;
    color: white;
    font-size: 31px;
    font-weight: bold;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    padding: 5px;
} */

.rowOffer{

/* margin-top: 10px; */
margin-bottom: 5px;
min-height: 50px;
}

.rowSecondOffer{
    margin-bottom: 5px;   
    min-height: 50px;
}

.imageOffer{
    margin-left: -16px;
    /* Width: 100%; */
     height: 100%;
      position: absolute;
     
}

.thirdOffer{
    border: none;
    /* opacity: 0.6; */
    /* margin: 5px 36px; */
    width: 50px;
    height: 50px;
    background: #ed9e82;
    border-radius: 50%;
    color: white;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
}

.fourthOffer{
    flex-direction: column;
    /* opacity: 0.6; */
    /* margin: 5px 5px; */
    border: none;
    width: 50px;
    height: 50px;
    background: #c7c4c4;
    border-radius: 50%;
    color: #fb5e37;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
}


.search-bar-option {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 10px;
}
.bg-grey-official,
.search-bar-option:hover {
  background: #e2e2e2;
}
.btn-login {
  box-shadow: 0 0 6px 0 #00000054;
}
.island-box {
  box-shadow: -6px 0 12px 0 #0000002e;
  position: fixed;
  right: 0;
  height: 89%;
  background: #fff;
  justify-content: center;
  align-items: center;
}
.btn-profile-update {
  background: #000;
  color: #fff;
  width: 100%;
  padding: 2px;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  box-shadow: 0 0 6px 2px #0000007a;
}
.address-list-item,
.bg-light-grey {
  background: #efefef;
}
.address-list-item .details,
.cart-list-addons,
.custom-color .item,
.order-item,
.pay-box:hover h3,
.pay-box:hover h5,
.sos-outer,
.super-car-ob-car-types.super-car-ob-un-exp.super-car-white-bg {
  background: #fff;
}
.address-list-item .details p {
  color: #2e2e2e;
  font-size: 14px;
}
.address-list-item .address-btn {
  padding: 15px 5px 8px;
}
.address-list-item .address-btn button {
  padding: 4px 10px;
}
.bg-black {
  background: #000;
  color: #fff;
}
.bg-black:hover {
  background: #181818 !important;
  color: #fff;
}
.bg-yellow,
.quote-yellow {
  background:linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);;
  color: #fff;
}
.bg-yellow:hover {
  background: #ff3201 !important;
  color: #fff;
  border: none;
}
.otp-input {
  text-align: center;
  padding: 7px;
  border-radius: 6px;
  border: 2px solid #35c2c1;
}
.amenties-label {
  border-radius: 24px;
  padding: 5px 6px;
  font-size: 12px;
  display: inline-block;
}
.brand-item:hover {
  box-shadow: 0 0.5rem 1rem #00000080 !important;
}
.dropzone {
  position: relative;
  height: 50px;
}
.dropzone label {
  position: absolute;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #b8b8b8;
  border-radius: 40px;
  cursor: pointer;
}
.line_box h4,
.line_box p {
  padding: 0 5px;
  margin-bottom: 0;
}
.car-title {
  color: #0451be;
  font-size: 13px;
}
.recommend-time {
  position: absolute;
  bottom: 12px;
  left: 12px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  padding: 5px 10px;
}
.bg-layer {
  width: 100%;
  height: 100%;
  background: #0303036e;
  position: fixed;
}
.layer {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  position: absolute;
  cursor: pointer;
}
.account-box {
  box-shadow: -6px 0 12px 0 #0000002e;
  position: fixed;
  top: 85px;
  right: 0;
  background: #fff;
  z-index: 10;
}
.account-box .col a {
  background: #e9e9e9;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  color: #848484;
}
.account-box .info h4 {
  font-weight: 400;
  font-size: 20px;
}
.account-box .slick-slider,
.super-car-blue-circle,
.super-car-ob-car-types.super-car-policy-cover.super-car-dark-bg,
.super-car-rent-left-box {
  background: #f5f5f5;
}
.Consumables-div p,
.rg-colm,
.slick-slider p {
  font-size: 12px;
}
.account-box .slick-slider .selected-car,
.my-orders .slick-slider .selected-car {
  border-bottom: 2px solid #0451be;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.account-box button {
  border-radius: 20px;
}
.account-box p,
.custom-content,
.super-car-ptlb-discount h4,
body,
h1,
p {
  margin: 0;
}
.account-box .green {
  background: #00c767;
  color: #fff;
}
.account-box .red {
  background: #ff4100;
  color: #fff;
}
.account-box .blue {
  background: #004470;
  color: #fff;
}
.account-box .notification p,
.ob-points .info p {
  color: #fff;
  font-size: 14px;
}
.account-box .view-all-block {
  font-size: 16px;
  text-transform: uppercase;
  background: #0451be;
}
.btn-faded {
  background: #ccc;
  border: none;
  color: #636363;
}
.btn-faded:hover {
  background: #9c9c9c;
}
.slick-next:before,
.slick-prev:before {
  color: #414141 !important;
}
.product-time {
  position: absolute;
  bottom: 17px;
  left: 9px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  padding: 4px 7px;
  font-size: 13px;
  align-items: center;
}
img.product-image-container {
  object-fit: cover;
  width: 200px;
  height: 200px;
}
.workshop-button {
  border: none;
  padding: 18px 25px;
  border-radius: 24px;
}
.workshop-button-one {
  border: 1px solid;
  padding: 18px 20px;
  min-width: 100px;
  border-radius: 24px;
}
.workshop-button-one-report {
  height: 30px;
  display: flex;
  flex-direction: row;
  padding: 18px 20px;
  border-radius: 24px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  cursor: pointer;
  transition: 1s;
  border: 1px solid;
  min-width: 100px;
}
.cart-header {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  min-height: 100px;
  z-index: 10 !important;
  background: #fff;
}
.change-car {
  background: #ff3201;
  border-radius: 20px;
  color: #fff;
  padding: 6px 20px;
  font-weight: 600;
}
.cart-header .choose-car-btn {
  background: #fff;
  border-radius: 20px;
  color: #0172bd;
  padding: 9px 40px;
  font-weight: 600;
  border: 2px solid #0172bd;
}
.stav_projektu .text_circle:after,
.stav_projektu .text_circle:before,
.stav_projektu .text_circle:not(:first-child):before,
.stav_projektu .text_circle_offer:after,
.stav_projektu .text_circle_offer:before,
.text_circle:after,
.text_circle_offer:after {
  background-color: #aac6d8;
}
.grey-bar {
  margin-top: 18px;
}
.text_circle,
.text_circle_offer {
  flex: 1;
  text-align: center;
  position: relative;
}
.text_circle_offer:after {
  bottom: 1em;
  position: absolute;
  right: 0;
}
.mobile-thank-you .text_circle:after,
.mobile-thank-you .text_circle:before,
.mobile-thank-you .text_circle_offer:after,
.mobile-thank-you .text_circle_offer:before {
  margin-bottom: 6px;
}
.text_circle:after {
  bottom: 1.25em;
  position: absolute;
  right: 0;
}
.stav_projektu .text_circle.done + .text_circle:before,
.stav_projektu .text_circle.done:after,
.subline:before,
.text_circle.done + .text_circle:before,
.text_circle.done:after,
.text_circle.sub:after,
.tvar.done span {
  background-color: #0172bd;
}
.line_box h4 {
  color: #0172bd;
  font-weight: 700;
  font-size: 18px;
}
.line_box p {
  font-size: 12px;
}
.subline {
  position: absolute;
  right: -25px;
  bottom: -43%;
}
.subline:before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 14px;
  right: 15px;
  border-radius: 50%;
  top: -25px;
}
.address-checkout-div p,
.subline h6,
.super-car-b-box p {
  margin-bottom: 0;
}
.timeline {
  position: fixed;
  width: 40%;
  z-index: 9;
  background: #fff;
}
.tvar,
.tvar span {
  width: 24px;
  border-radius: 100%;
}
.text_circle:not(:first-child):before {
  bottom: 1.25em;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  left: 0;
  width: 50%;
  z-index: -1;
  background-color: #aac6d8;
}
.text_circle:last-child:after {
  width: 0;
}
.circle,
.form-div .form-img,
.form-div .form-img img,
.levelup-promo .item-small .item-img img,
.map,
.mob-mac-img img,
.notification-custom-hight {
  height: 100%;
}
.tvar {
  height: 32px;
  display: flex;
  position: relative;
  top: -43px;
  margin: 3px auto;
  background-color: #fff;
}
.footer-link,
.my-car-button,
.ob-points .earn-more,
.pagination li.active a,
.paragraph-link,
.read-more-button,
.super-car-serve-link {
  text-decoration: underline;
}
.tvar span {
  margin: 20% auto;
  height: 24px;
  background-color: #88cefd;
  color: #002d4a;
}
.tvar.done span {
  color: #fff;
}
.stav_projektu .tvar {
  border: 2px solid #aac6d8;
}
.stav_projektu .done .tvar,
.stav_projektu .sub .tvar {
  border: 2px solid #0172bd;
}
.subline h6 {
  color: #0172bd;
}
.timeline .card-header:hover {
  background-color: #ececec;
  cursor: pointer;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -43px;
  }
}
@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -31px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .subline:before {
    top: -29px;
  }
}
@media (min-width: 767px) {
  .workshop-button-one-report {
    border: 1px solid;
    padding: 0;
    height: 24px !important;
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    cursor: pointer;
    transition: 1s;
    min-width: 100px;
  }
}
@media (max-width: 767px) {
  .subline:before {
    top: -30px;
  }
  .inspection-report-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .subline:before {
    top: -23px;
  }
  .card-names h5 {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  .subline:before {
    top: -43px;
  }
  .ul-tag-list {
    flex-direction: column;
  }
  .my-car-button {
    padding-left: 40px !important;
  }
  .ul-tag-list-offer {
    column-count: 1 !important;
  }
  .form-div-offers {
    display: none;
  }
  .ul-tag-list li {
    margin: 0 auto 20px !important;
    width: 250px;
  }
  .thank-you h5 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .form-img h4,
  .ul-tag-list li {
    margin-bottom: 20px;
  }
  .offer-main {
    margin-top: 40px !important;
  }
  .form-div .form-img {
    height: auto !important;
  }
  .choose-para {
    width: 100% !important;
  }
  .form-img {
    padding-left: 15px;
  }
}
.workshop-list-area {
  padding-bottom: 60px;
  height: calc(100vh - 260px);
}
.workshop-list-area .amenties-label {
  border-radius: 24px;
  padding: 1px 4px;
  font-size: 10px;
  display: inline-block;
}
.workshop-price-difference {
  font-weight: 700;
  color: red;
  font-size: 12px;
}
.workshop-price-difference-low {
  font-weight: 700;
  color: #383838;
  font-size: 12px;
}
.chat-box .chat-head h6,
.custom-bold-span,
.font-weight-semi-bold,
.md-txt,
.overlapblock strong,
.pickup-drop-area,
.profile-level-up p,
.semi-bold-txt,
.switch-tab a,
.thank-you h1,
.tyre .tab p {
  font-weight: 600;
}
.workshop-img {
  border-radius: 10px;
  object-fit: contain;
  height: 100%;
}
.workshop-bar.change-garage {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
}
.workshop-bar.change-garage button {
  background: #fff;
  color: #000;
  text-decoration: underline;
  padding: 5px 12px;
  border: none;
}
.bee-button a,
.bee-icon .bee-icon-label-right a,
.custom-pdf-ob a,
.super-car-lsi-box a,
.super-car-pop-box a:hover,
a:hover {
  text-decoration: none;
}
.job-list-area {
  height: calc(100vh - 430px);
}
.cart-bottom-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 7px;
  width: 40%;
  background: #000;
  color: #fff;
}
.border-none {
  border: none !important;
}
.cart-list-addons .workshop-button-one {
  padding: 10px 45px;
}
.cart-list-addons .workshop-button-one.remove {
  padding: 10px 32px;
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  border: 1px solid #ff3201;
}
.workshop-button-one.remove {
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  padding: 18px 35px;
  border: 1px solid #ff3201;
}
.level-up-box {
  background: #ebf0f5;
  color: #000;
  width: 40%;
}
.level-up-box h4 {
  font-size: 17px;
  text-align: center;
  color: #fff;
}
.level-up-box .already-member {
  font-size: 11px;
  text-align: right;
  width: 100%;
  color: #fff;
  font-weight: 700;
  position: absolute;
  right: 5px;
  bottom: 0;
}
.choose-car .search-brand,
.choose-car .search-model {
  width: 100%;
  position: fixed;
  box-shadow: rgb(0 0 0 / 15%) 0 5px 15px;
  z-index: 9;
}
.choose-car .search-brand {
  top: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.choose-car .search-model {
  top: 50px;
  background: #fff;
}
.choose-car .brand img {
  object-fit: contain;
  height: 120px;
  width: 120px;
}
.choose-car .brand:hover img {
  padding: 0.5rem !important;
}
.choose-car .brand:hover p {
  color: #0172bd;
  font-weight: 700;
}
.results-wrapper {
  margin: 1rem 1rem 1rem 0;
  padding: 0.5rem;
}
.result-span {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
}
.bee-button .content,
.mob-mac-block,
.pay-box,
.pay-box-view,
.super-car-bd-download,
.super-car-bd-stores,
.super-car-buy-buton-box,
.super-car-ob-about .super-car-col-sm-6,
.super-car-ob-car-types.super-car-policy-cover.super-car-ob-un-exp h2,
.super-car-service-circle,
.super-car-service-circle-2 {
  text-align: center;
}
.product-details .workshop-button {
  position: absolute;
  right: 0;
  padding: 15px 11px;
}
.product-details .price-area {
  position: absolute;
  bottom: 0;
  right: 0;
}
.workshop-button-one:disabled,
.workshop-button-one[disabled] {
  background-color: #ccc;
  color: #666;
}
.selected-product img {
  height: 40px;
  border: 2px solid #0072bd;
  border-radius: 27px;
  width: 40px;
  margin-right: 5px;
  cursor: pointer;
}
.margin-nav {
  margin-top: 120px;
}
.switch-tab a {
  font-size: 17px;
  margin-right: 10px;
  padding: 0 3px;
}
.ob-points .tab .active,
.switch-tab a.active {
  color: #0451be;
  border-bottom: 3px solid #0451be;
}
.amenties-group {
  white-space: nowrap;
  width: calc(100% - 110px);
  overflow-x: scroll;
  height: 44px !important;
}
.bg-grey {
  background: #f6f6f6;
}
.bg-grey-dark {
  background: #d9d9d9;
}
.bg-grey-dark-2x {
  background: #bdbdbd;
}
.bg-gray {
  background-color: #848484;
}
.pickup-drop-area {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}
.location-view input {
  border-radius: 3px;
  position: relative;
}
.map-imgo {
  position: absolute;
  right: 28px;
  top: 30px;
}
.pay-now-btn {
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  color: #fff;
  border: none;
}
.location-view,
.super-car-field-row {
  margin-top: 15px;
}
.pickup-drop-area label {
  margin: 0 0 0 10px;
  font-size: 12px;
  line-height: 15px;
}
.pickup-drop-area .price {
  font-size: 13px;
  font-weight: 500;
}
a.active {
  color: #0451be;
  border-bottom: 3px solid;
}
.bg-light-blue {
  background: #ecfdfe;
}
.bg-light-blue-2x {
  background: #dbf0fa;
}
.bg-light-grey-2x {
  background: #e3e3e3;
}
.dashboard .relationship-summary .saving p,
.ob-points .list-item p,
.terms p,
.text-black {
  color: #000;
}
.font-weight-500 {
  font-weight: 500;
}
.text-custom-green {
  color: #28d146;
}
.img.top-selling {
  border-radius: 15px;
  height: 280px;
  object-fit: cover;
}
.blank-space {
  height: 95px;
}
.workshop-title {
  color: #ff5722;
  font-weight: 800;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.vat-extra {
  font-size: 15px;
  color: #696565;
  margin-left: 5px;
}
.cart-bottom-box {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.proceed-btn {
  font-weight: 700;
  background:  linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  font-size: 22px;
  padding: 2px 15px 2px 23px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  color: #fff;
}
.proceed-btn.disabled,
.quote-request-btn.disabled {
  background: #57708b;
  color: #fff;
  cursor: initial;
}
.proceed-btn:hover,
.quote-request-btn:hover {
  background: #0451be;
  color: #fff;
}
.checkout-login-section {
  background: #e4e4e48f;
  padding-top: 90px;
  padding-bottom: 30px;
  margin-top: 0 !important;
}
.text-orange,
.thank-you .order-title {
  color: #ff3201;
}
.pay-box {
  border-radius: 6px;
  margin-top: 40px;
  padding: 10px;
  background: #e1f3ff;
  cursor: pointer;
  box-shadow: 0 0 4px 0 #b6c2ca;
}
.pay-box:hover {
  background: #1f82c5;
}
.pay-box h3 {
  color: #1870a8;
  border-radius: 10px;
}
.pay-box h5 {
  margin-top: 10px;
  color: #002d4a;
  border-radius: 5px;
}
.chat-box .recieve p,
.chat-box .sent p,
.dashboard .offers h3,
.dashboard .offers h5,
.menu svg,
.pay-box:hover small,
.slide .title h2,
.slide .title p,
.super-car-ob-appworks button a,
.super-car-ob-blue-button a,
.super-car-ob-faqs,
.super-car-ob_wu_more a,
.super-car-oba-box button a,
.super-car-slide-button a {
  color: #fff;
}
.upgrade-btn {
  background: #fff;
  color: red;
  border: none;
  padding: 7px 15px;
  border-radius: 4px;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 50px;
  z-index: 10;
  box-shadow: 0 0 4px 0 #000;
}
.suggestion-item,
.suggestion-item--active {
  padding: 3px 6px;
}
.location-search-input {
  border: none;
  width: 100%;
}
.product-service.package-title {
  font-size: 20px;
  margin-bottom: 0;
}
.dashboard .active-car {
  margin-top: 20px;
  border-top: 3px solid #0451be;
  text-align: start;
  padding: 5px 10px;
  background: #f3f4ff;
}
.dashboard .active-car p {
  font-size: 13px;
  color: #000;
}
.dashboard .inspection-report {
  margin-top: 20px;
  background: #efefef;
  padding: 10px 15px;
}
.dashboard .ob-point,
.dashboard .offers {
  padding-top: 20px;
  flex-direction: column;
  text-align: center;
  display: flex;
}
.dashboard .inspection-report p {
  color: #000;
  border-bottom: 1px solid #c5c5c5;
  padding: 7px 5px;
  margin: 0;
  font-size: 14px;
}
.dashboard .offers {
  background: #395ab2;
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-around;
}
.dashboard .offers button {
  background: #fff200;
  color: #000;
  padding: 18px;
  width: auto !important;
}
.dashboard .ob-point {
  background: linear-gradient(180deg, #fede00 0, #f3a900 100%);
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-around;
}
.dashboard .ob-point .point {
  font-size: 20px;
  border: 2px solid;
  border-radius: 50px;
  padding: 20px;
  display: inline-block;
}
.dashboard .relationship-summary {
  border: 1px solid #bfbfbf;
  text-align: center;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.dashboard .relationship-summary div {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}
.dashboard .relationship-summary p {
  margin: 0;
  font-size: 14px;
}
.dashboard .relationship-summary .expence p {
  color: #de0000;
}
.dashboard .relationship-summary button {
  background: #395ab2;
  padding: 18px;
}
.dashboard .recommend,
.thank-you .recommend {
  background: #ff3201;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.dashboard .recommend p,
.ob-points .recommend p,
.thank-you .recommend p {
  color: #fff;
  margin: 0;
}
.dashboard .recommend button,
.ob-points .recommend button,
.thank-you .recommend button {
  padding: 18px;
}
.thank-you .recommend-row {
  display: flex;
  justify-content: center;
}
.product-time.package {
  font-size: 15px;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
.tyre .description {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
 .descriptionLine {
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #5A5A5A;
}
.tyre .tab p {
  border-bottom: 1px solid #ababab;
  cursor: pointer;
  text-align: center;
  border: 2px solid;
 
}
.tyre .tab.active p {
  border-bottom: 2px solid #ff3201;
  color: #ff3201;
}
.btn-tyres-gray{
 background:  rgb(87, 112, 139) !important
}
.btn-tyres-orng{
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%)!important 
 }

.tyre .tab{
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.open-bonnet-btn {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 5px 20px;
  border-radius: 25px;
}
.package-service-btn {
  background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 30px;
}
.repair-btn {
  border: none;
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  color: #fff;
  padding: 6px 14px;
  border-radius: 20px;
}
.thank-you h1 {
  color: #0451be;
  font-size: 45px;
}
.thank-you h5 {
  color: #0451be;
  padding-bottom: 20px;
}
.thank-you .steps .active span {
  margin: 0;
  padding: 10px 18px;
  background: #0451be;
  color: #fff;
  border-radius: 35px;
}
.thank-you .steps .active h6 {
  margin-top: 20px;
  margin-bottom: 0;
  color: #0451be;
}
.thank-you .steps span {
  margin: 0;
  padding: 10px 18px;
  background: #f3f4ff;
  color: #000;
  border-radius: 35px;
}
.thank-you .steps h6 {
  margin-top: 20px;
  margin-bottom: 0;
}
.thank-you button.cta {
  background: #ff3201;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
}
.product-discount {
  position: absolute;
  top: 17px;
  left: 0;
  background: #fff;
  border-radius: 0 10px 10px 0;
  display: flex;
  padding: 4px 7px;
  font-size: 14px;
  align-items: center;
  font-weight: 500;
  color: #ff3201;
}
.product-discount img {
  height: 17px;
  margin-right: 5px;
}
.thank-you-bg {
  background: url(/assets/thank-you-bg.jpg) bottom/contain no-repeat;
  height: 320px;
}
.my-orders .slick-slider,
.next,
.prev {
  cursor: pointer;
}
.bg-green {
  background: #098d2e;
}
.order-item h5 {
  font-weight: 800;
  color: #000;
  font-size: 18px;
}
.order-item p {
  color: #000;
  font-size: 15px;
}
.chat-box .chat-head {
  background: #f3f3f3;
  box-shadow: 0 2px 2px 0 #00000026;
}
.chat-box .chat-head p {
  font-weight: 400;
  font-size: 12px;
}
.chat-box .chat-head .logged-in {
  color: #00c767;
}
.chat-box .recieve {
  padding: 10px 5px 10px 15px;
  background: #0451be;
  border-radius: 12px 12px 12px 0;
}
.chat-box .sent {
  padding: 10px 5px 10px 15px;
  background: #687188;
  border-radius: 12px 12px 0;
}
.chat-box .chat-body {
  height: 50vh !important;
}
.chat-box .chat-body .typing {
  font-weight: 700;
  display: inline-block;
  font-family: monospace;
  font-size: 18px;
  clip-path: inset(0 3ch 0 0);
  animation: 1s steps(4) infinite typing;
  color: #404040;
}
@keyframes typing {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
.order-item .list-item {
  background: #f9f9f9;
}
.estimate-pricing {
  background: #0451be;
  margin: 0;
}
.border-grey {
  border: 1px solid #d6d6d6;
}
.border-grey-bottom {
  border-bottom: 1px solid #d6d6d6;
}
.list-styled {
  list-style: initial;
  padding-left: 18px;
}
.quote-amount,
.quote-count {
  right: 15px;
  padding: 5px 10px;
  color: #fff;
  position: absolute;
}
.pagination {
  justify-content: center;
}
.loader,
.menu {
  display: flex;
  align-items: center;
}
.pagination li {
  margin-right: 7px;
}
.pagination li.active a {
  color: #006ab0;
  font-weight: 600;
}
.quote-count {
  top: 15px;
  background: #6c757d;
  border-radius: 6px;
}
.quote-amount {
  top: 0;
  background: #000ac6;
}
.bg-blue,
.super-car-ob-cheap-ci,
.super-car-oc-cal-next {
  background: #0451be;
  color: #fff;
}
.menu-bars .bars {
  background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  color: #e9ecef;
}
.menu {
  border-radius: 30px;
  background: #17a2b8;
  color: #000;
  height: 35px;
  width: 35px;
  justify-content: center;
  margin-left: 10px;
}
.mobile-car-selection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  margin-top: 11%;
  height: 100%;
  z-index: 10;
}
.blink {
  background-color: #ff3201 !important;
  font-family: sans-serif;
}
.ob-points .point {
  font-size: 39px;
  border: 4px solid;
  border-radius: 70px;
  padding: 20px;
  display: inline-block;
  margin: 40px 0;
  font-weight: 700;
}
.b3g1,
.slide img {
  border-radius: 20px;
}
.ob-points .earn-more {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 14px;
  color: #000;
}
.ob-points .tab p {
  color: #000;
  cursor: pointer;
}
.ob-points .recommend {
  background: #ff3201;
  padding: 20px;
}
.card-why .title,
.slide .title {
  position: absolute;
  bottom: 0;
  padding: 80px 10px 10px;
  color: #fff;
  text-align: center;
}
.image-layer {
  background: linear-gradient(#00000059, #000000eb);
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.image-layer:hover {
  background: linear-gradient(#0000008b, #000000f0);
}
.one-line {
  white-space: nowrap;
  color: #000 !important;
  margin: auto 5px;
}
@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}
.slide img {
  margin: 0 auto;
  width: 100%;
  height: 380px;
  object-fit: cover;
}
.slide .title {
  background: linear-gradient(359deg, #000000c4, transparent);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
#rent .slick-list,
.Consumables-div,
.anm-txt li label:hover::before,
.basic-single,
.basic-single .select__control,
.bee-row-1 .bee-col-1 .bee-block-1,
.bee-row-1 .bee-col-1 .bee-block-6,
.bee-row-2 .bee-col-2 .bee-block-1,
.offer-row,
.super-car-img-fullwidth,
.super-car-ob_app_dimg a img,
.super-car-save-money-boxes img,
.table-invoice {
  width: 100%;
}
.card-why .title {
  background: linear-gradient(359deg, #000000e8, transparent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.slide {
  cursor: pointer;
  transform: scale(0.6);
  transition: transform 0.3s;
  opacity: 0.9;
}
.activeSlide {
  transform: scale(1);
  opacity: 1;
}
.loader {
  position: fixed;
  background: #ffffffdb;
  height: 100vh;
  width: 100vw;
  z-index: 998;
  top: 0;
  left: 0;
  justify-content: center;
  flex-direction: column;
}
.add-md-header,
.b3g1,
.bee-icon .bee-content,
.centered-label {
  display: flex;
  align-items: center;
}
.b3g1 {
  background: linear-gradient(#9e5624, #efc384);
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid #ff3201;
  padding: 20px;
}
.b3g1 p,
.ob-highlight h5 {
  font-size: 18px;
  font-weight: 500;
}
.text-yellow {
  color: #fff200;
}
.red {
  border-color: red;
  border-radius: 5px;
}
.subscription-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.your-location-area,
.your-location-area:hover {
  display: inline-block;
  min-width: 200px;
  max-width: 250px;
  white-space: nowrap;
}
.read-more-button {
  color: #004470;
  text-transform: capitalize;
}
.text-blue {
  color: #0451be;
  font-weight: 500;
}
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-size: 14px;
  font-weight: 900;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  color: #000;
  background: #cbcbcb;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  box-shadow: 0 0 3px 0 #000000a8;
  right: 4px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #000;
  background: #cbcbcb;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  box-shadow: 0 0 3px 0 #000000a8;
  left: 4px;
}
.search-this-area,
.your-location {
  background: #fff;
  position: absolute;
}
.addon-border {
  border-left: 5px solid #bbb;
}
.text-grey {
  color: #696565;
}
.your-location {
  font-size: 11px;
  top: 0;
  padding: 2px;
  color: #0451be;
}
.service-items {
  margin-top: 30px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
}
.your-location-area {
  overflow-x: hidden;
}
.your-location-area:hover {
  text-overflow: initial;
}
.search-this-area {
  bottom: 55px;
  color: #555;
  border: none;
  border-radius: 20px;
  padding: 5px 14px;
  left: calc(50% - 80px);
  font-weight: 500;
  box-shadow: 0 0 2px 0 #000;
}
.error-txt {
  font-size: 60px;
}
.map-alert {
  position: absolute;
  bottom: 55px;
  background: #ebebeb;
  color: #000;
  border: 3px solid #919191;
  border-radius: 20px;
  padding: 6px 10px;
  left: calc(50% - 25%);
  width: 50%;
}
.sos-btn-mobile,
.sos-mob {
  display: none;
  position: fixed;
}
.sos-btn-mobile {
  bottom: 70px;
  right: 12px;
  border-radius: 40px;
  z-index: 10;
  padding: 10px;
  background: #ffc107;
  border: none;
  box-shadow: 0 0 4px 0 #000;
}
.sos-mob {
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 20;
  height: calc(100vh - 90px);
  padding-top: 20px;
  top: 90px;
  overflow-y: scroll;
  padding-bottom: 20px;
}
.ribbon,
.ribbon span,
.sos-close {
  position: absolute;
}
.sos-close {
  top: 20px;
  right: 10px;
}
.ob-highlight {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
  border: 1px solid #0003;
}
.ob-highlight h3 {
  font-size: 25px;
  font-weight: 600;
  color: #16d0d0;
}
.ribbon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  z-index: 2;
}
.ribbon::after,
.ribbon::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 2px solid #d87917;
}
.ribbon span {
  display: block;
  width: 112px;
  padding: 7px 0;
  background-color: #ff3201;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 600 12px/1 Lato, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.ribbon-bottom-right {
  bottom: -5px;
  right: -5px;
}
.ribbon-bottom-right::after,
.ribbon-bottom-right::before {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 1px;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 1px;
}
.ribbon-bottom-right span {
  left: -12px;
  bottom: 15px;
  transform: rotate(315deg);
}
.copy-field {
  background: #fff !important;
}
.copy-field::placeholder {
  color: #0451be;
  opacity: 1;
}
.copy-field:-ms-input-placeholder {
  color: #0451be;
}
.copy-field::-ms-input-placeholder {
  color: #0451be;
}
.ob-points .copy-field::placeholder {
  color: #000;
  opacity: 1;
}
.ob-points .copy-field:-ms-input-placeholder {
  color: #000;
}
.ob-points .copy-field::-ms-input-placeholder {
  color: #000;
}
.main-banner .banner-img {
  width: 90%;
  max-height: calc(85vh - 120px);
  min-height: 50vh;
}
.main-banner {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.main-banner .search-box {
  position: absolute;
  width: 30%;
  top: 50%;
  left: 40px;
  background: #fff;
  border-radius: 5px;
}
.main-banner .search-box h1 {
  font-size: 26px;
}
.input-search {
  padding: 0;
  border-radius: 35px;
  border: 2px solid rgba(0, 114, 188, 0.5);
  overflow: hidden;
}
.input-search-mob {
  padding: 0;
  border-radius: 35px;
  border: 2px solid #5757577d;
}
.input-search .custom-width {
  width: 240px;
}
.search-text-type {
  font-size: 12px;
  color: #5e5e5e;
}




/* .sliding-text-container {
    overflow: hidden;
    white-space: nowrap;
  }
  
  .search-text-type {
    display: inline-block;
    animation: slide 15s linear infinite;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  } */
  



.cart-btn {
  border: 2px solid #ff3201;
  border-radius: 25px;
  color: #ff3201;
  height: 40px;
  width: 40px;
  font-size: 22px;
}
.common-font-size,
.job-status-custom,
.profile-level-up h6,
.refer-box p,
.table-invoice-data {
  font-size: 13px;
}
.workshop-brand {
  padding-bottom: 35px;
  overflow: hidden;
  padding-top: 35px;
}
.workshop-brand .brand-item {
  height: 100px;
  width: 100px;
  box-shadow: #00000078 0 4px 10px 0;
  border-radius: 50px;
  background: #fff;
}
.workshop-services {
  overflow: visible;
}
.workshop-highlights {
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
  flex-direction: column;
}
.workshop-highlights .workshop-tag-assure,
.workshop-highlights .workshop-tag-choice {
  position: relative;
  font-size: 17px;
}
.workshop-bar .workshop-distance {
  font-size: 16px;
  font-weight: 500;
}
.super-car-pop-box-left h2,
.super-car-pop-box-left h5,
.workshop-bar .workshop-distance p {
  color: #000;
}
.workshop-page .amenties-label {
  border: 1px solid;
  font-size: 14px;
}
.ob-points .main-num {
  width: 70px;
  height: 70px;
  font-size: 25px;
  font-weight: 700;
  background: #0059b9;
  color: #fff;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  padding: 13px;
}
.mulkia-img {
  margin-top: 5px;
}
#mapInfo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0.9;
  border-radius: 10px;
}
.refer-box p {
  font-style: italic;
}
.profile-completion {
  background: #0059b9;
  color: #fff;
  border-radius: 20px;
}
.level-up-bg,
.levelup-promo {
  background-repeat: no-repeat;
}
.refer-input {
  border-radius: 40px;
  overflow: hidden;
}
.refer-input button {
  border-radius: 40px !important;
}
.level-up-bg {
  margin-top: -10px;
  background-image: linear-gradient(
      to left,
      rgba(245, 246, 252, 0),
      rgba(0, 0, 0, 0.9)
    ),
    url(/assets/level-up-main.png);
  background-position: top;
  background-size: cover;
  height: 520px;
}
.login-input-light {
  width: 100%;
  height: 45px;
  border: 1px solid #a8a8a8;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.border-rounded {
  border-radius: 100px;
}
.levelup-promo {
  background-image: url(/assets/level-rounded.png);
  background-position: center;
  background-size: contain;
}
.brand-bg,
.brand-event,
.workshop-login-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.levelup-promo .rounded-item {
  border-radius: 500px;
  box-shadow: 0 0 5px 0 #0000003b;
  height: 300px;
  width: 300px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.levelup-promo .item-small .item-img {
  border-radius: 100px;
  box-shadow: 0 0 5px 0 #0000003b;
  padding: 30px;
  height: 130px;
  width: 130px;
  background: #fff;
}
.workshop-login-bg {
  margin-top: -10px;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(/assets/workshop-login-bg.jpg);
  height: 420px;
}
.brand-bg {
  margin-top: -10px;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(/assets/brand-bg.png);
  height: 500px;
}
.brand-event {
  background-image: url(/assets/brand-event-bg.png);
}
.brand-event img {
  height: 500px;
  object-fit: contain;
  background: #fff;
}
@media only screen and (max-width: 1400px) {
  .choose-para {
    width: 100% !important;
  }
  .arrow-img {
    width: 75px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .input-search .custom-width {
    width: 200px;
  }
}
@media only screen and (max-width: 1120px) {
  .input-search .custom-width {
    width: 150px;
  }
  .nav-link {
    padding: 0.5rem;
  }
  .arrow-img {
    width: 65px !important;
    right: -35px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .input-search .custom-width {
    width: 130px;
  }
  .nav-link {
    padding: 0.5rem 0.25rem;
  }
}
@media only screen and (max-width: 800px) {
  .levelup-promo .item-small .item-img {
    padding: 22px;
    height: 100px;
    width: 100px;
  }
}
@media only screen and (max-width: 700px) {
  .levelup-promo .item-small h5 {
    font-size: 17px;
  }
  .levelup-row h4 {
    font-size: 20px;
    text-align: center;
  }
  .levelup-row button {
    height: 35px;
  }
}
@media only screen and (max-width: 550px) {
  .workshop-button-one.workshop-select-btn {
    padding: 12px 11px;
  }
  .levelup-promo .item-small h5 {
    font-size: 15px;
  }
  .levelup-promo .item-small .item-img {
    padding: 14px;
    height: 80px;
    width: 80px;
  }
  .levelup-promo .rounded-item {
    height: 250px;
    width: 250px;
  }
  .levelup-promo .rounded-item .text-blue {
    margin-bottom: 5px;
  }
  .levelup-promo .rounded-item h3 {
    font-size: 23px;
  }
  .levelup-promo .rounded-item .otp-button {
    padding: 10px;
    height: 38px;
  }
  .levelup-row button {
    height: 35px;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .level-up-bg h1 {
    font-size: 30px;
  }
}

.expired-button:hover {
    background-color: #687188 !important; /* Keep the background color same on hover */
  }

@media only screen and (max-width: 480px) {
  .mobile-view-google-head {
    margin-left: 25px;
    margin-top: 20px !important;
  }
  #review .google-text span,
  .mobile-content-list li,
  .workshop-distance {
    font-size: 12px !important;
  }
  .cart-header .car-img,
  .cart-list .description,
  .desktop.rowMap.workshop,
  .get-home,
  .input-search,
  .level-up-box.desktop-box,
  .location .car-icon,
  .mobile-mechanice-description,
  .ob-login,
  .product-time,
  .req-btn,
  .sos,
  .top-selling-card .product-discount,
  .workshop-tag-choice span {
    display: none;
  }
  .hide-in-mobile {
    display: none !important;
  }
  .for-mobile,
  .offer .service-contract-offer {
    display: block !important;
  }
  .main-bg {
    background: #fff !important;
    padding-top: 50px !important;
  }
  .checkout-page-main-div {
    padding: 50px 0 !important;
  }
  .main-heading-checkout {
    padding-top: 5px;
  }
  .new-pickdrop {
    padding-left: 10px;
  }
  .whtsapp-field {
    padding-left: 30px !important;
  }
  #review,
  .common_box {
    padding: 10px !important;
  }
  .label_text {
    margin: 5px 0;
  }
  .main_box {
    border-bottom: 10px solid #ccc;
  }
  .mobile-sm,
  .nav-brand-logo {
    width: 120px;
  }
  .total-logo {
    width: 65px;
  }
  .menu-bars {
    display: flex;
  }
  .location .columnMap,
  .offer .car-detail,
  .offer .offer-text {
    width: 100% !important;
  }
  .location {
    width: 210px;
    height: 100px;
  }
  .mobile.rowMap.workshop .inputMap,
  .workshop {
    height: auto;
  }
  .workshop {
    width: 210px;
    top: 145px;
  }
  .grey-bar,
  .service-items {
    margin-top: 10px;
  }
  #review .review-title,
  .mobile.rowMap.workshop,
  .sos-btn-mobile,
  .sos-mob {
    display: block;
  }
  .slick-slide .service-buttons img {
    height: 40px;
    object-fit: contain;
  }
  .slick-slide .service-buttons .service-name {
    font-size: 12px !important;
    line-height: 12px;
  }
  .cart-mobile-btn {
    display: block;
    position: fixed;
    bottom: 10px;
    border: none;
    background: #000;
    color: #fff;
    padding: 8px 25px;
    width: 90%;
    border-radius: 30px;
    box-shadow: 0 0 20px 0 #000;
  }
  .cart-bottom-area,
  .cart-view,
  .level-up-box,
  .timeline,
  .workshop-bar.change-garage {
    width: 100%;
  }
  .cart-header {
    width: 100%;
    min-height: 90px;
  }
  .timeline {
    margin-top: 80px;
  }
  .cart-list title {
    margin-bottom: 0;
  }
  .workshop-list-text,
  .workshop-page .amenties-label,
  .workshop-price-difference {
    font-size: 11px;
  }
  .tag-rec {
    font-size: 11px !important;
    margin: 0 6px;
  }
  .filter-text {
    margin-right: 6px;
  }
  .tag-rec-selected {
    font-size: 11px !important;
    margin: 0 4px;
  }
  .cart-list .title {
    font-size: 14px !important;
  }
  .cart-list .logo {
    width: 60px;
    height: 60px;
  }
  .cart-list .cost {
    margin-left: 3% !important;
    font-size: 13px;
    font-weight: 600;
  }
  .level-up-box.mobile-box {
    display: block;
    height: 70px;
    padding-top: 3px;
  }
  .mb-bn-tipe {
    position: absolute;
    color: #000 !important;
    font-size: 13px;
    left: 62px;
    bottom: -6px;
  }
  .level-up-box img {
    width: 50px;
    margin: 5px 5px -9px 7px;
    height: 50px;
  }
  .mb-lv-logo-space {
    padding-left: 0 !important;
  }
  .subscription-btn {
    top: 8px;
  }
  .level-up-box.mobile-box .title {
    font-size: 14px;
    color: #000;
  }
  .cart-bottom-area .title,
  .total-pay-area h5 {
    font-size: 17px;
  }
  .cart-bottom-area .title .vat-extra,
  .map-alert p,
  .ob-highlight h5,
  .pickup-drop-area .price {
    font-size: 12px;
  }
  .cart-bottom-area p {
    font-size: 10px;
  }
  .how-ob h3,
  .proceed-btn {
    font-size: 20px;
  }
  .cart-list-addons .workshop-button-one {
    padding: 10px 30px;
  }
  .cart-list-addons img {
    height: 35px;
    width: 35px;
  }
  .service-buttons {
    height: 100px !important;
    margin-left: 0;
    margin-right: 0;
  }
  .categories-box a,
  .checkout-login-section {
    padding: 10px;
  }
  .img.top-selling,
  .recommend-image-container > .img {
    height: 180px;
  }
  #recommend .item-head,
  .top-selling-head {
    padding: 5px;
  }
  .top-selling-card .overlay {
    height: 80%;
  }
  .top-selling-card .top-selling-details .top-sell-text {
    font-size: 13px !important;
  }
  .top-selling-details {
    padding: 2px;
  }
  #topSelling {
    margin-bottom: 20px !important;
  }
  #review {
    margin-bottom: 0 !important;
  }
  .google-head {
    margin-top: 0 !important;
  }
  .google-text {
    min-height: auto !important;
  }
  #review .google-logo {
    width: 30%;
    left: 0;
    top: 45px;
  }
  #review .google-text {
    font-size: 14px;
    line-height: 16px;
  }
  .req-btn-mob {
    display: block;
    margin-top: 10px;
  }
  .mobile-padding-remove,
  .my-orders,
  .offer {
    padding: 0 !important;
  }
  .partner {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .brand-logo {
    height: 80px;
    width: 80px;
  }
  .footLogo,
  img.product-image-container {
    height: 100px;
    width: 100px;
  }
  .footLogo {
    top: -50px;
  }
  .product-discount {
    font-size: 10px;
    top: 100%;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: inline-block;
  }
  .custom-product-discount {
    top: 17px;
    display: inline-flex;
    font-size: 12px;
    padding: 10px;
    width: 150px;
    text-align: center;
    right: auto !important;
    left: 3px !important;
    margin: 0 auto;
  }
  .quote-amount,
  .quote-count {
    right: auto;
    top: auto;
    position: relative;
  }
  .pickup-drop-area {
    padding-left: 5px;
    font-size: 13px;
  }
  .order-sub-text,
  .small-font {
    font-size: 13px;
  }
  .oba-container {
    padding: 10px;
    display: none;
  }
  .oba-container.mobile-view {
    display: block;
    height: auto;
  }
  .oba-image-center {
    width: 40%;
  }
  .mobile-mechanice-img {
    transform: rotate(90deg);
    display: none;
  }
  .mobile-mechanice-title {
    padding: 0 !important;
    width: 100%;
  }
  .mobile-mechanice-title h2 {
    display: block;
    text-align: center;
  }
  .mobile-mechanice-main-title {
    width: 100%;
    justify-content: center !important;
  }
  .offer-text li {
    line-height: 18px;
    margin-bottom: 13px;
  }
  .map-alert {
    width: 90%;
    left: 5%;
    padding: 5px;
  }
  .account-box {
    min-height: 100%;
  }
  .service-buttons-active {
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  .package-service-head {
    font-size: 16px;
  }
  .package-service-btn {
    font-size: 13px;
    padding: 5px 10px;
  }
  .your-mobile-location {
    display: block;
    font-size: 14px;
  }
  .your-mobile-location .badge {
    border-radius: 20px;
    padding: 5px 10px;
    background: #ff3201;
    color: #fff;
  }
  .b3g1 .preview-img {
    width: 60%;
  }
  .ob-highlight {
    border: none;
    padding: 7px;
    margin-bottom: 5px;
    flex-direction: column;
  }
  .ob-highlight h3 {
    font-size: 15px;
    margin-top: 10px;
  }
  .ob-highlight .img {
    height: 45px !important;
    width: 45px !important;
  }
  .main-banner .search-box {
    position: initial;
    width: auto;
  }
  .main-banner .search-box h1 {
    letter-spacing: 1px;
  }
  .location-change-popup.mobile-view .car-icon {
    width: 20% !important;
  }
  .location-change-popup.mobile-view .search-area {
    width: 80% !important;
  }
  .main-banner {
    margin-top: 0;
  }
  .show-on-map {
    background: #a5a5a5;
    color: #000;
  }
  .workshop-map-data {
    width: 100%;
    bottom: 45px;
  }
  .workshop-services {
    overflow: hidden;
  }
  .workshop-head-img {
    height: 100px !important;
  }
  .mulkia-img {
    width: 50%;
  }
  .personal-details {
    margin: 0;
  }
  .how-ob img {
    height: 200px;
    object-fit: contain;
  }
  .table-scroll {
    width: 100%;
    overflow: scroll;
  }
  .workshop-image {
    width: 100px !important;
  }
  .workshop-page h1 {
    font-size: 27px;
  }
  .for-mobile.flex {
    display: flex !important;
  }
}
.common_box input,
.cs-row .offers-summary .row {
  margin: 0 !important;
}
@media only screen and (max-width: 500px) {
  .show-section-mobile {
    margin-right: 10px !important;
  }
  .home_top-selling-image-container__35pQK {
    box-shadow: none !important;
  }
  .workshop-map-data {
    bottom: 72px;
  }
}
@media only screen and (max-width: 400px) {
  .total-logo {
    width: 55px;
  }
  .inspection-report-body {
    padding-left: 5px;
    padding-right: 5px;
  }
  .inspection-report-date {
    font-size: 10px;
  }
  .nav-brand-logo {
    width: 115px;
  }
  .levelup-promo .item-small h5 {
    font-size: 12px;
  }
}
.order-download-popup-modal .modal-dialog {
  max-width: 500px;
  width: 100%;
}
.order-download-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lead-page,
.offer-banner {
  max-width: 100%;
}

@media (max-width: 576px) {
  .steps {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
  }
  .layer {
    left: 90%;
  }
  .steps .row {
    flex-wrap: nowrap;
  }
  .thank-you .steps h6 {
    font-size: 12px;
    overflow-wrap: break-word;
  }
  .steps .col {
    flex: 0 0 auto;
    width: 100%;
  }
  .steps::-webkit-scrollbar {
    display: none;
  }
  #button-mobile-view {
    padding: 10px;
    display: grid;
  }
  #button-request-mobile {
    padding: 10px;
  }
  .cta {
    width: auto;
    margin: 4px 0;
  }
  .steps > div {
    display: inline-block;
    vertical-align: top;
  }
}
.map-container {
  height: 70vh;
  width: 100vw;
}
._1YOWG {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  position: static !important;
  left: 0 !important;
  -webkit-transform: translate(0) !important;
  -moz-transform: translate(0) !important;
  transform: translate(0) !important;
  bottom: 0 !important;
  margin-bottom: 90px !important;
}
.gray {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.workshop-tag-choice-mobile,
.workshop-tag-choice-smart-selection {
  color: #fff;
  border-radius: 18px;
  background: #848484;
  font-weight: 700;
}
.slider-issue-mobile .slick-track {
  padding: 8px 0 !important;
}
.Facing_issues-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 160px;
}
.workshop-tag-choice-smart-selection {
  padding: 5px 12px;
  height: fit-content;
}
.workshop-tag-choice-mobile {
  font-size: 10px;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  right: 5px;
}
#fifth,
#fifth:hover {
  border: 1px solid rgba(255, 255, 255, 0);
}
#fifth {
  overflow: hidden;
  transition: 0.2s ease-in-out;
}
#fifth:before {
  content: " ";
  display: block;
  width: 200px;
  height: 80px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: -10px;
  left: -400px;
  transform: rotate(-45deg);
  transition: 0.5s ease-in-out;
}
#fifth:hover:before {
  margin-left: 400%;
}
.width-height-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-control {
  background-image: url(/assets/drop-down-background.png);
  background-repeat: no-repeat;
  background-position: center -3px;
  background-size: 100% 100px;
}
.phone-number-field::placeholder {
  font-weight: 700;
  color: #000;
}
.sale-offer {
  color: red;
  font-weight: 700;
  margin: 10px;
  text-align: center;
}
.sale-desc,
.sale-desc-mobile {
  text-align: center;
  line-height: 1.2;
  margin: 22px auto !important;
  word-spacing: 0.5px;
  color: #fff;
  font-size: 14px;
}
.sale-header {
  text-align: center;
  color: #fff;
}
.sale-desc {
  width: 35%;
}
.sale-desc-mobile {
  width: 74%;
}
#myBar,
.title-bar-annual label strong,
.ul-tag-list-offer {
  line-height: 30px;
}
.offer-list-slider button.slick-arrow.slick-next:before,
.offer-list-slider button.slick-arrow.slick-prev::before,
.sub-heading_text {
  font-size: 15px !important;
}
.offer-banner {
  background-image: url("/assets/banner/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #333;
  width: 100vw;
  height: calc(100vh - 71px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-shadow: "0px 4px 4px 0 #00000040,inset 0 0 0 1000px rgba(0,0,0,.5)";
}
.offer-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.serv-name {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline !important;
}
.font-normal {
  font-weight: 400;
}
.common_box input {
  padding: 0 7px;
  font-size: 14px;
  height: 40px;
  background: 0 0;
}
.common-box,
.ul-tag-list li {
  background: #fff;
  padding: 20px;
}
.total-pay-area h5 {
  font-size: 16px;
  color: #000 !important;
}
.select-box-custom div > div > div {
  padding: 0 0 0 10px !important;
}
.select-box-custom div > div {
  margin: 0 !important;
  padding: 0 5px 15px !important;
}
.sub-heading_text {
  color: #333;
}
.d-none {
  font-size: 15px;
}
.payment-area h5 {
  font-size: 16px;
  margin-bottom: 0;
  text-align: left;
}
.payment-select h6 {
  display: block;
  position: absolute;
  right: 4px;
  top: 7px;
}
.custom-font-size-payment small {
  display: block;
  margin-top: -8px;
  font-size: 10px;
  color: #029700;
}
.prmtxt,
.pw-mamo,
.pw-tabby {
  padding-top: 20px;
}
.pw-mamo img {
  width: 130px !important;
}
.card-names {
  margin-left: -40px;
}
.car-detail-row {
  margin-top: 10px;
}
.remmed-field {
  border: none;
  color: #000;
}
.offers-summary h6 {
  font-size: 14px;
  font-weight: 400 !important;
  color: #000;
}
.whtsapp-field {
  font-size: 14px;
  margin-bottom: 10px;
}
.common-box {
  margin: 10px;
  height: fit-content;
}
.custom-checkout-date-picker .react-date-picker__wrapper {
  border: 1px solid #ccc !important;
  border-radius: 5px;
}
.custom-checkout-date-picker .react-date-picker {
  border: none;
}
.request-header {
  text-align: start;
  font-weight: 700;
  color: #ff3201;
  text-transform: capitalize;
}
.inro-txt span,
.prmtxt label,
.prmtxt small,
.prmtxt span,
.sptxt-bloxk,
.timeline-text,
.title-bar-annual h2,
.txt-bdg p {
  text-transform: uppercase;
}
.my-car-button {
  cursor: pointer;
  width: 100px;
  padding-left: 10px;
}
.form-img img {
  min-height: 550px;
  max-height: 660px;
  object-fit: cover;
}
.form-div-offers img {
  min-height: 400px;
  max-height: 610px;
  object-fit: cover;
}
.ul-tag-list {
  list-style-type: decimal;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0 30px;
}
.content-list li,
.list-li,
.super-car-ul li {
  list-style-type: disc;
}
.ul-tag-list li {
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  border-radius: 3px;
  min-width: 250px;
  text-align: center;
  font-family: Poppins;
}
.ul-tag-list-offer {
  text-align: center;
  padding: 20px 0;
}
.offer-heading,
.super-car-car-plan.super-car-doc-required-box * {
  text-align: left;
}
.ul-tag-list-offer svg {
  margin-right: 15px;
  color: #0072c2;
  font-weight: 700;
  font-size: 20px;
}
.ul-tag-list-offer li {
  text-align: start;
  line-height: 30px;
  font-family: Poppins;
  font-weight: 500;
}
ul.ul-tag-list svg {
  color: #0451be;
  font-size: 35px;
  margin-bottom: 15px;
}
.offer-heading {
  color: #000 !important;
  line-height: 24px;
  padding-bottom: 0 !important;
}
.arrow-img {
  width: 43px;
  height: auto;
  position: absolute;
  right: -20px;
  top: 26px;
}
.car-box {
  position: relative;
  align-items: center;
  justify-content: center;
}
.css-8h3gbh-menu {
  z-index: 2222 !important;
}
.offer-container {
  max-height: 600px;
  overflow-y: auto;
}
.custom-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
#addButton {
  color: #000;
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 0 5px;
}
.login-pop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0172bb;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  border: none;
}
.content-list,
.super-car-ob-car-types.super-car-policy-cover.super-car-uae-boxes
  .super-car-container {
  flex-wrap: wrap;
  display: flex;
}
.offer-text-1 {
  right: 17px;
}
.common_box {
  background: #fff;
  padding: 20px;
  height: fit-content;
}
.offer-text-2 {
  color: #fdbf1e;
  right: 45px;
  font-size: 14px;
  width: 87px;
  top: 17px;
}
.cs-member {
  width: 100%;
  padding: 10px;
}
.cs-member img {
  width: 107px;
}
.cs-member .text-white {
  line-height: 16px;
  padding-left: 6px;
  font-size: 13px;
}
.csdflex {
  padding: 10px;
  color: #fff;
  border-top: 1px solid red;
}
@media only screen and (min-width: 1300px) {
  .cs-row .container-fluid {
    max-width: 1200px;
  }
  .checkout-container .container-fluid {
    max-width: 85%;
  }
}
.main-heading-checkout {
  font-size: 17px !important;
  font-weight: 600 !important;
}
.image-container {
  position: relative;
  display: block;
}
.overlay-custom,
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay-custom {
  width: 98%;
  height: 10px;
  z-index: 1;
}
.text {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 100%;
  z-index: 2;
  border-radius: 0;
}
.content-list {
  margin: 15px 0;
  list-style: none;
}
.content-heading {
  font-size: 22px;
  margin-bottom: 10px;
  padding: 8px 0;
}
.content-sub-heading {
  font-weight: 700;
  font-size: 18px;
  padding: 8px 0;
}
.content-main-div {
  background: #f3f4ff;
  padding: 20px;
  margin-top: 25px;
}
.content-paragraph,
.content-second-list li {
  font-size: 14px;
  margin: 10px 0;
}
.content-list li {
  flex-basis: 50%;
  padding: 5px;
  font-size: 14px;
  display: inline-block;
}
.cnt-bl-block h4,
.cnt-block h4 {
  border-top: 1px dashed #a1a1a1;
  padding-top: 14px;
  padding-left: 7px !important;
}
.content-list li:before {
  content: "\2022";
  padding-right: 5px;
}
.list-li span {
  font-weight: 700;
  font-size: 14px;
}
.content-second-list {
  padding: 10px 16px;
}
.content-paragraph {
  color: #000;
}
.paragraph-link {
  color: #0451be
}
.quote-request-btn {
  font-weight: 700;
  background:  linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  font-size: 22px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  color: #fff;
}
.super-car-radio-box {
  display: block;
  font-weight: 700;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.super-car-radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.super-car-checkmark {
  position: absolute;
  top: 5px;
  left: 7px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #000;
}
.super-car-radio-box:hover input ~ .super-car-checkmark {
  background-color: #ccc;
}
.super-car-radio-box input:checked ~ .super-car-checkmark {
  background-color: #fff;
  border: 2px solid #0451be;
}
.super-car-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.super-car-radio-box .super-car-checkmark::after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0451be;
}
.super-car-btn-round {
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  color: #fff;
  border-radius: 34px;
  font-size: 16px;
  padding: 14px 25px;
  transition: 1s;
  -webkit-transition: 1s;
  margin-top: 10px;
}
.sp-btbar {
  margin-top: -20px !important;
}
.super-car-custom-border {
  border-right: 1px dashed #a1a1a1;
  padding: 10px;
}
.super-car-ul li {
  text-align: start;
}
.super-car-rent-car-col h5 {
  font-weight: 700;
  font-size: 16px;
}
.super-car-rac-choose p,
.super-car-rent-car-col p {
  font-size: 16px;
  color: #848484;
}
.super-car-btn-round:hover {
  color: #fff;
  background: #0451be;
}
.super-car-rent-left-box h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.super-car-rent-text {
  font-size: 22px;
  color: #848484;
  font-weight: 400;
}
.super-car-ob-car-types .super-car-exp-box::after,
.super-car-rac-choose::after {
  clear: both;
  content: "";
  display: block;
}
.super-car-rac-choose {
  background: #fff;
  padding: 20px 30px;
  margin-top: 40px;
}
.super-car-faci-image {
  min-height: 55px;
}
.super-car-services-column h3 {
  font-size: 21px;
}
.super-car-services-three-columns {
  margin-top: 40px;
  margin-bottom: 40px;
}
.super-car-adImageBox {
  margin-top: 50px;
  margin-bottom: 30px;
}
.super-car-car-plan .super-car-doc-required h2 {
  color: #000;
  font-size: 48px;
  text-align: left;
}
.super-car-car-plan.super-car-doc-required-box {
  margin-bottom: 80px;
}
.super-car-blue-box p,
.super-car-car-plan.super-car-doc-required-box p,
.super-car-car-plan.super-car-doc-required-box ul {
  font-size: 16px;
}
.super-car-car-plan.super-car-doc-required-box .super-car-car-plan-box {
  box-shadow: 5px 7px 10px 10px #ebebeb;
  padding: 0 50px 50px;
  border-radius: 20px;
}
.super-car-car-plan.super-car-doc-required-box ul {
  padding-left: 20px;
}
.super-car-car-plan.super-car-doc-required-box .super-car-car-plan-box img {
  margin-top: 88px;
}
.super-car-ob-car-comp h2 {
  text-align: center;
  font-size: 48px;
  font-weight: 400;
}
#rangeValue1,
.super-car-ob-car-comp p {
  text-align: center;
  font-size: 22px;
}
.super-car-ob-car-types.super-car-policy-cover .super-car-col-sm-4 {
  border: 15px solid #f5f5f5;
  padding: 30px 20px;
}
.super-car-new-changes-para,
.super-car-oba-box p {
  font-size: 22px;
}
.super-car-blue-circle h4 a,
.super-car-slide-button {
  font-size: 18px;
  color: #fff;
}
.super-car-lsi-box,
.super-car-ob-car-comp {
  margin-bottom: 20px;
}
.super-car-ob-car-types.super-car-policy-cover.super-car-uae-boxes
  .super-car-ins-button.super-car-slide-button {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
}
.super-car-ob-car-types.super-car-policy-cover.super-car-uae-boxes
  .super-car-col-sm-4 {
  padding-bottom: 90px;
}
.super-car-blue-circle h4 {
  background: #0451be;
  height: 300px;
  width: 300px;
  padding-top: 110px;
  border-radius: 100%;
}
.super-car-ob-benefit button,
.super-car-ob-car-types.super-car-policy-cover.super-car-uae-boxes
  .super-car-blue-circle
  h4 {
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .super-car-rent-left-box h2 {
    margin: 20px 0;
    font-size: 22px;
    line-height: 32px;
  }
  .super-car-rent-time-selection li {
    display: block;
    width: 200px;
    text-align: left;
    margin: auto;
  }
  .super-car-rent-time-selection {
    margin-bottom: 20px;
  }
  .super-car-adImageBox img {
    object-fit: none;
  }
  .super-car-services-column {
    margin-bottom: 80px;
  }
  .super-car-rac-choose {
    margin-bottom: 40px;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: -100% !important;
    display: block;
    margin-left: auto !important;
    margin-right: auto;
  }
  .request-page-slider .slick-track {
    margin-left: 20px !important;
  }
}
.super-car-item,
.super-car-slider-container {
  background: url("/assets/super-car/slide-circle.webp") top center no-repeat
    #edf2f4;
  padding-bottom: 40px;
}
.super-car-slide-heading h1 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  position: relative;
  top: 100px;
  left: 80px;
}
.super-car-slide-button {
  margin-top: 150px;
  width: 300px;
  padding: 17px;
  border: 0;
  background: #ff3201;
  border-radius: 72px;
}
.super-car-slide-heading h1 strong {
  color: #ff3201;
  font-weight: 800;
}
.super-car-slider-owl-box .super-car-owl-dots {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}
.super-car-row.super-car-buy-contract-banner {
  background: #fff200;
  padding: 20px;
}
.super-car-buy-contract-banner h1 {
  font-weight: 700;
  font-size: 30px;
  margin-top: 31px;
}
.super-car-buy-contract-banner button {
  background: #000;
  color: #fff;
  border: 0;
  padding: 12px 40px;
  font-size: 20px;
  border-radius: 47px;
  margin-top: 20px;
}
.super-car-b-box {
  background: #000;
  color: #fff200;
  text-align: center;
  padding: 10px;
  font-size: 25px;
}
.super-car-ob_why_use h1 {
  display: block;
  text-align: center;
  margin: 40px auto;
  font-weight: 700;
  font-size: 60px;
}
.heading-guid span,
.super-car-cl-blue,
.super-car-ob-about h1 span,
.super-car-ob_app_downloads b {
  color: #0451be;
}
.super-car-cl-orange,
.super-car-ob-cheap-ci h2 strong,
.super-car-ob-hyperion h1 strong {
  color: #ff3201;
}
.super-car-ob_why_use {
  background: url("/assets/super-car/mapbase.webp") center 118px no-repeat
    #edf2f4;
  padding-bottom: 50px;
}
.super-car-ob_wu_box p {
  background: #fff;
  padding: 35px 30px;
  border-radius: 25px;
  text-align: justify;
  min-height: 400px;
}
.super-car-bg-orange p {
  background: #ffefde;
  margin-top: 100px;
  z-index: 99999;
  position: relative;
}
.super-car-ob_wu_box {
  width: 32%;
}
.super-car-ob_wu_first {
  margin-top: 70px;
  margin-left: 3%;
}
.super-car-bg-orange img {
  position: absolute;
  z-index: 1;
  right: 50px;
  top: -24px;
}
.super-car-ob_wu_first img {
  position: absolute;
  left: -100px;
  width: 100px;
  top: 14%;
}
.super-car-ob_wu_more {
  color: #fff;
  background: #0451be;
  padding: 11px 21px;
  display: block;
  border-radius: 43px;
  border: 0;
  margin-top: 20px;
}
.super-car-service-circle-2::before,
.super-car-service-circle-3::before {
  content: "";
  background: url("/assets/super-car/circle-group.webp") no-repeat;
  height: 667px;
  position: absolute;
  bottom: 0;
  top: -225px;
  right: -190px;
  width: 667px;
  left: auto;
  z-index: 1;
}
.ob-jobs h3 {
  background: #efe4fc;
  display: inline-block;
  padding: 10px 18px;
  border-radius: 6px;
  font-size: 16px;
}
.ob-jobs h2 {
  font-weight: 700;
  font-size: 32px;
  padding-left: 30px;
  position: relative;
  z-index: 999999999999999999;
}
.super-car-service-circle-1 a {
  margin: auto;
  display: block;
  text-align: center;
}
.super-car-service-circle p {
  color: #000;
  font-size: 18px;
}
.ob-jobs h2::before {
  width: 10px;
  height: 100%;
  background: #61a0ff;
  content: "";
  left: 0;
  position: absolute;
  border-radius: 23px;
}
.ob-jobs h2 span {
  color: #61a0ff;
}
.super-car-service-circle-1 {
  position: relative;
  top: -16px;
}
.super-car-obj-but-1 {
  background: #0451be;
  border: 0;
  padding: 13px 25px 12px;
  border-radius: 29px;
}
.super-car-obj-but-1 a {
  color: #fff;
  font-size: 20px;
}
.super-car-obj-but-2 {
  background: #fff200;
  padding: 11px 25px;
  border-radius: 38px;
  border: 0;
  padding-top: 14px;
}
.super-car-obj-1 button {
  display: block;
  margin-bottom: 20px;
}
.super-car-obj-but-2 a {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.super-car-car-plan,
.super-car-service-circle-3 {
  margin-top: 80px;
}
.super-car-service-circle-4 {
  margin-top: 160px;
}
.ob-jobs .col-sm-3 > * {
  position: relative;
  z-index: 21;
}
.super-car-ob-earn-download {
  display: block;
  margin: 40px auto auto;
  border: 0;
  border-radius: 10px;
  padding: 14px 20px;
  background: #e7eefa;
  font-size: 14px;
}
.super-car-ob-earn-download a {
  color: #1e344f;
}
.super-car-ob-contact-forms {
  width: 545px;
  margin: 40px auto auto;
}
.super-car-obc-buttons button {
  background: 0 0;
  border: none;
  font-size: 20px;
  margin-right: 20px;
}
.super-car-obc-buttons button.super-car-active {
  background: #0451be;
  color: #fff;
  padding: 10px 25px;
  border-radius: 17px;
}
.super-car-obc-forms p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.super-car-obc-forms form {
  display: flex;
  border: 1px solid;
  padding: 7px;
  border-radius: 11px;
  width: 90%;
}
.super-car-obc-forms input[type="text"] {
  width: 81%;
  padding: 13px;
}
.super-car-obc-forms input {
  border: 0;
  background: 0 0;
}
.super-car-obc-forms input[type="submit"] {
  font-weight: 600;
  color: #0451be;
  font-size: 16px;
}
.super-car-service-circle-6 {
  text-align: center;
  margin-top: 60px;
}
.super-car-service-circle-6 img {
  width: 65px;
  margin-top: 65px;
}
.ob-jobs-3 {
  position: relative;
  top: -75px;
}
.super-car-ob-earn-sphere {
  position: absolute;
  width: 30px;
  margin-top: 30px;
  margin-left: 120px;
}
.super-car-ob-refer-spehere {
  position: absolute;
  right: 80px;
  width: 70px;
}
.super-car-ob-contact-forms-box .super-car-container img {
  width: 65px;
  position: absolute;
  left: 0;
}
.ob-jobs::after {
  background-image: linear-gradient(#edf2f4, #fff);
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  position: absolute;
}
.super-car-ob-refer {
  margin-top: 0;
  background: #fff;
}
.super-car-ob-super-garage {
  margin-top: 100px;
}
.super-car-ob-partner {
  width: 400px;
  border-radius: 20px;
  padding: 40px;
  background: #edf2f4;
  box-shadow: 50px 25px 100px rgba(9, 20, 50, 0.15);
  margin-left: 40px;
}
.super-car-ob-partner h2 {
  font-weight: 700;
  font-size: 32px;
}
.super-car-ob-partner h4 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.super-car-ob-benefit h5,
.super-car-ob-car-types h5,
.super-car-ob-partner p {
  font-size: 18px;
}
.super-car-ob-prem-service {
  display: inline-block;
  background: #efe4fc;
  padding: 10px 20px;
  border-radius: 11px;
  font-weight: 500;
}
.super-car-super-charge {
  font-weight: 700;
  font-size: 32px;
  position: relative;
  padding-left: 40px;
}
.super-car-super-charge::before {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  background: #0451be;
  border-radius: 21px;
}
.super-car-ob-super-charge-box p {
  font-weight: 600;
  font-size: 18px;
}
.super-car-ob-blue-button {
  background: #0451be;
  padding: 13px 20px;
  border: 0;
  border-radius: 40px;
  font-size: 18px;
}
.super-car-super-charge img {
  position: absolute;
  top: 35%;
}
.super-car-ob-garage-col .col-sm-4 {
  margin: 10px 10px 0 0;
  box-shadow: 0 0 200px rgba(9, 20, 50, 0.15);
  text-align: center;
  padding: 20px;
  border-radius: 27px;
  background: #fff;
  max-width: calc(31% - 0px);
}
.super-car-ob-garage-col img {
  margin-bottom: 40px;
}
.super-car-service-img {
  border-radius: 50%;
  box-shadow: 0 0 20px 20px #00000014;
}
.super-car-ob-garage-col h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}
.super-car-ob-garage-col {
  margin-left: 27px;
  margin-top: 40px;
}
.super-car-ob-super-charge-box {
  margin-left: 30px;
}
.super-car-ob-about {
  margin-top: 50px;
}
.super-car-ob-about h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}
.super-car-oba-heading img {
  margin-right: 14px;
  position: relative;
  top: -5px;
}
.super-car-oba-box button {
  background: #0451be;
  padding: 15px 30px;
  border: 0;
  border-radius: 37px;
  font-size: 17px;
  margin-top: 10px;
}
.super-car-oba-box {
  background: url("/assets/super-car/circle-group.webp") center no-repeat;
  padding: 110px 90px 34px;
  text-align: center;
}
.super-car-foucs {
  background-color: #ff3201;
  padding: 8px 20px;
  color: #fff;
  font-size: 18px;
  border-radius: 25px;
  width: 200px;
  margin-bottom: 10px;
}
.super-car-ob-highlights {
  margin-top: 214px;
  font-size: 30px;
  font-weight: 700;
}
.super-car-ob-appworks {
  background: #efe4fc;
  padding: 21px;
  border-radius: 20px;
}
.sl-block .slick-slide img {
  height: 200px;
  object-fit: cover !important;
}
.super-car-ob-appworks h4,
.super-car-ob_app_downloads {
  font-weight: 700;
  font-size: 25px;
}
.super-car-div-hr {
  height: 10px;
  background: #2659c3;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
}
.super-car-div {
  background: #f3f4ff;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 30px;
}
.super-car-div-hr::after {
  content: "";
  display: block;
  background: #fff;
  position: absolute;
  right: 0;
  width: 70px;
  height: 100%;
  border-right-radius: 25px;
}
.super-car-ob-appworks button {
  background: #2659c3;
  border: 0;
  padding: 15px 28px;
  border-radius: 33px;
  margin-bottom: 15px;
}
.super-car-ob-vieall {
  text-align: center;
  margin-top: 40px;
}
.heading-guid,
.super-car-ob_img_bg h3 {
  text-align: center;
  font-weight: 600;
}
.super-car-ob-vieall a {
  color: #000aff;
  font-size: 16px;
}
.super-car-ob-hyperion .super-car-owl-theme .super-car-owl-dots {
  position: absolute;
  bottom: 57px;
  z-index: 99999999999;
  text-align: center;
  left: 0;
  right: 0;
}
.super-car-ob-hyperion h1 {
  font-size: 60px;
  font-weight: 700;
  color: #1e344f;
  margin-bottom: 30px;
}

.google_pay{
    height: 80%;
    width: 80%;
}

.apple_pay{
    height: 80%;
    width: 80%;
}

.super-car-hy-para {
  padding-left: 17px;
  font-size: 17px;
}
.super-car-ob-get-app,
.super-car-ob-hyperion {
  padding-top: 200px;
  padding-bottom: 100px;
  background: url("/assets/super-car/lines.webp") 0 -500px no-repeat;
  background-size: 100% !important;
}
.super-car-ob-brand-logos {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
  width: 100% !important;
}
.super-car-ob-brand-logos a {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding: 56px 59px;
  border: 3px solid #fff;
  border-radius: 122px;
  box-shadow: 0 0 46px -3px #f3f4ff;
  transition: 1s;
  background-size: 53% !important;
}
.super-car-ob-brand-logos li:hover a {
  border: 3px solid #0451be;
}
.super-car-ob-brands h4 {
  font-size: 36px;
  font-weight: 600;
}
.super-car-ob-brands p {
  color: #0451be;
  font-size: 22px;
  font-weight: 500;
}
.super-car-ob-faqs .super-car-container {
  background: #0451be;
  border-radius: 30px;
  padding: 40px 60px 60px;
}
.super-car-ob-faqs h5 {
  text-align: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.super-car-ob-faqs h3 {
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}
.custom-font-weight,
.super-car-faq-row.super-car-active h4 {
  font-weight: 700;
}
.super-car-faq-row {
  margin: 10px;
  border: 1px solid;
  border-radius: 46px;
  padding: 18px 80px 18px 18px;
  cursor: pointer;
  position: relative;
}
.super-car-faq-row.super-car-active::after,
.super-car-faq-row::after {
  right: 41px;
  top: 10px;
  font-size: 22px;
  width: 27px;
  border-radius: 30px;
  height: 27px;
  line-height: 27px;
  position: absolute;
  text-align: center;
}
.super-car-faq-row.super-car-active::after {
  content: "-";
  background: #ff3201;
}
.super-car-faq-row::after {
  content: "+";
}
.super-car-ob_app_dimg {
  display: flex;
  padding: 0;
  margin-top: 0;
}
.super-car-ob_app_dimg a {
  width: 50%;
}
.super-car-ob_appr {
  background: #e6f1f9;
  padding: 10px 10px 10px 38px;
  border-radius: 34px;
}
.super-car-ob_appr h5 {
  color: #ff3201;
  font-weight: 700;
}
.super-car-ob_appr ul {
  list-style: none;
  padding: 0;
  font-size: 16px;
}
.super-car-ob_app_downloads strong {
  color: 72BC;
  font-size: 88px;
  color: #0451be;
}
.super-car-ob_mob_img {
  width: 80%;
  margin-top: 73px;
}
.super-car-ob_img_bg {
  background: url(/assets/super-car/elipse-2.webp) center top/contain no-repeat;
  padding: 100px 131px;
}
.super-car-ob_img_bg p {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}
.super-car-ob_appr li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
.super-car-ob_appr li::before {
  content: "";
  display: block;
  background: red;
  position: absolute;
  left: 0;
  width: 11px;
  height: 11px;
  border-radius: 10px;
  outline: red solid 1px;
  border: 2px solid #fff;
  top: 6px;
}
.super-car-ob-get-app {
  padding-bottom: 20px;
}
.super-car-ins-slider-container {
  background: #000;
  background-color: #000 !important;
  color: #fff;
  width: 100%;
  background-position: right !important;
  background-repeat: no-repeat !important;
  padding: 50px;
  text-align: center;
}
.super-car-ins-button.super-car-slide-button {
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.super-car-ins-slide-heading {
  padding-left: 50px;
  padding-bottom: 50px;
  padding-top: 50px;
}
.super-car-save-money h4 {
  font-size: 36px;
  font-weight: 500;
}
.super-car-save-money p {
  color: #848484;
  font-size: 22px;
  margin-bottom: 30px;
}
.super-car-save-money-boxes p {
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
}
#myRange1,
#myRange2,
.header-lead-page,
.super-car-fsb-box-left,
.super-car-save-money {
  margin-top: 20px;
}
.super-car-sufr-quote-container {
  background: url("/assets/super-car/sufr-quote.webp") center/cover no-repeat;
  margin-top: 30px;
}
.super-car-surf-quote-box h2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 20px;
}
.super-car-surf-quote-box {
  background: #fff;
  border-radius: 17px;
  padding: 10px 40px 30px;
  margin: 40px 0;
  width: 440px;
}
.super-car-surf-quote-box li {
  list-style: none;
  margin-bottom: 10px;
  font-size: 12px;
  padding-left: 25px;
  position: relative;
}
.super-car-ob-car-types.super-car-ob-un-exp li::before,
.super-car-ob-cheap-box li::before,
.super-car-surf-quote-box li::before {
  content: "";
  display: block;
  position: absolute;
  background: url("/assets/super-car/righ_icon.webp") 2px 3px/72% no-repeat;
  left: 0;
  border: 2px solid #0451be;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  height: 20px;
}
.super-car-ob-benefit {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
  border-bottom: 10px solid #f5f5f5;
}
.super-car-ob-benefit h2 {
  text-align: left;
  margin-bottom: 30px;
  font-size: 36px;
}
.super-car-ob-car-type p {
  color: #848484;
  font-size: 22px;
}
.super-car-ob-car-type h2 {
  font-size: 36px;
}
.super-car-ob-car-type,
.super-car-row-progress-bar {
  margin-bottom: 30px;
}
.super-car-ob-car-types {
  text-align: center;
  background: #f5f5f5;
  padding: 40px;
}
.super-car-ob-car-types .super-car-col-sm-6 {
  background: #fff;
  border: 10px solid #f5f5f5;
  padding: 40px;
}
.super-car-ob-car-types .super-car-ob_ben_icon {
  margin-bottom: 30px;
  display: block;
}
.super-car-ob-cheap-ci h2 {
  font-size: 48px;
  font-weight: 700;
  margin-top: 80px;
}
.super-car-ob-cheap-box {
  background: #fff;
  color: #000;
  padding: 40px 40px 30px;
  border-radius: 30px;
  width: 459px;
  margin-top: 40px;
}
.super-car-ob-cheap-box li {
  font-size: 12px;
  margin-bottom: 20px;
  list-style: none;
  position: relative;
  padding-left: 28px;
}
.super-car-ob-cheap-box button {
  margin: auto;
  display: block;
  margin-top: 40px !important;
}
.super-car-cheap-para {
  font-size: 16px;
  padding-bottom: 70px;
}
.super-car-ob-car-types.super-car-policy-cover {
  background: #fff;
  padding: 0;
  margin-bottom: 20px;
}
.super-car-ob-car-types.super-car-policy-cover h2 {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
}
.super-car-ob-car-types.super-car-policy-cover .super-car-col-sm-6 {
  height: 300px;
  border: 15px solid #f5f5f5;
  padding: 30px 20px;
}
.super-car-ob-car-types.super-car-policy-cover
  .super-car-ins-button.super-car-slide-button {
  padding: 15px 30px;
}
.super-car-car-plab-box {
  box-shadow: 5px 7px 10px 10px #ebebeb;
  border-radius: 10px;
  margin-bottom: 70px;
}
.super-car-car-plan h2 {
  color: #0451be;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  margin-top: 60px;
  margin-bottom: 40px;
}
.super-car-cpb-left {
  padding-left: 40px;
  text-align: center;
}
.super-car-ob-car-types.super-car-policy-cover
  .super-car-exp-box
  .super-car-col-sm-6 {
  height: auto;
  text-align: left;
}
.super-car-ob-car-types .super-car-exp-box .super-car-col-sm-6 {
  border: 10px solid #fff;
  text-align: left;
  border-radius: 4px;
}
.super-car-ob-car-types.super-car-ob-un-exp li {
  list-style: none;
  margin-bottom: 15px;
  font-size: 12px;
}
.super-car-ob-car-types.super-car-ob-un-exp .super-car-exp-box {
  display: flex;
  font-size: 12px;
}
.super-car-ob-car-types.super-car-ob-un-exp h2 {
  margin-bottom: 40px;
  font-size: 52px;
}
.super-car-blue-box {
  background: #0451be;
  text-align: left;
  margin: 20px 10px 0;
  color: #fff;
  padding: 25px;
  border-radius: 10px;
}
.super-car-blue-box strong {
  font-size: 20px;
}
.super-car-ob-car-types.super-car-file-claim h2 {
  font-size: 48px;
}
.super-car-ob-car-types.super-car-file-claim .super-car-col-sm-6 {
  background: #0451be;
  border-radius: 20px;
}
.super-car-pop-box {
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  width: 90%;
  margin: 50px auto auto;
  padding: 40px;
}
.fifth-circle,
.first-circle,
.forth-circle,
.second-circle,
.third-circle {
  position: absolute;
}
.super-car-ptl-box {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}
.super-car-pop-box .super-car-container {
  background: #fff;
  z-index: 99999;
  padding: 0;
  border-radius: 18px;
  box-shadow: 0 0 1500px 0 #000;
}
.super-car-ptlb-blue-option,
.super-car-ptlb-grey-option {
  background: #0451be;
  color: #fff;
  padding: 10px;
  font-size: 14px;
}
.super-car-ptlb-add-button {
  background: #000;
  border: 0;
  color: #fff;
  padding: 10px 25px;
  border-radius: 33px;
  font-size: 12px;
}
.super-car-ptlb-remove-button {
  background: red;
  border: 0;
  padding: 10px 20px;
  color: #fff;
  border-radius: 31px;
  font-size: 12px;
}
.super-car-field-row-half,
.super-car-ptlb-image,
.super-car-ptlb-list ul li {
  margin-right: 10px;
}
.super-car-pop-box-left {
  text-align: center;
  padding: 80px;
}
.super-car-ptlb-list ul {
  display: flex;
  padding: 10px;
  background: #f5f5f5;
  list-style: inside;
  margin: 0 10px;
}
.super-car-pop-box-left h5 {
  color: #848484;
  font-size: 24px;
  font-weight: 400;
}
.super-car-ptlb-grey-option {
  background: #848484;
}
.super-car-ptlb-add-button.super-car-added {
  background: #28d146;
}
.super-car-pop-box-right {
  background: #f5f5f5;
  height: 100%;
  padding-top: 10;
}
.super-car-pop-tab-box {
  background: #fff;
  padding: 10px 0;
}
.super-car-pop-box-right > h4 {
  font-weight: 700;
  padding-top: 5px;
}
.super-car-pop-tab-box > .super-car-ptl-box:nth-child(3) {
  border-top: 1px solid;
  padding-top: 15px;
}
.super-car-pop-box-right > .super-car-pop-tab-box {
  padding-top: 0;
}
.super-car-next-button {
  padding: 10px;
  text-align: right;
  margin: 20px 0 20px 20px;
}
.super-car-next-button button {
  font-size: 16px;
  color: #fff;
  background: #000;
  border: 0;
  padding: 10px 30px;
  border-radius: 35px;
}
.super-car-pop-close {
  position: absolute;
  top: -22px;
  right: -28px;
  z-index: 99999999;
  cursor: pointer;
}
.super-car-custo-info-form label {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.super-car-field-row input {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  font-size: 20px;
  padding-left: 15px;
  border: 1px solid #9c9c9c;
}
.custom-alignment-flex,
.super-car-field-row-bottom,
.super-car-field-row.super-car-field-cols,
.super-car-oc-cal-box,
.super-car-row-vehi-boxes {
  display: flex;
  justify-content: space-between;
}
.super-car-request-booking {
  color: #fff;
  background: #000;
  border: 0;
  padding: 15px 20px;
  border-radius: 35px;
}
.super-car-field-row-bottom a {
  color: #1400ff;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  top: -10px;
  margin-left: 10px;
}
.super-car-field-row-bottom input[type="checkbox"] {
  height: 30px;
  width: 30px;
}
.super-car-pop-box-left p {
  color: #848484;
  font-size: 18px;
}
.super-car-custo-info-form {
  padding-bottom: 10px;
}
.super-car-fleet-saving-cal {
  margin-top: 50px;
  margin-bottom: 50px;
}
.super-car-fleet-saving-cal h4 {
  text-align: center;
  font-size: 36px;
  color: #0451be;
  font-weight: 600;
  margin-bottom: 40px;
}
.super-car-fsc-right h5 {
  color: 000000;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 4px solid #ff3201;
  padding-bottom: 10px;
}
.super-car-field-row select {
  background: #fff;
  border: 1px solid #9c9c9c;
  width: 100%;
  font-size: 24px;
}
.super-car-field-row-submit input {
  width: 100%;
  background: #0451be;
  color: #fff;
  border: 0;
  padding: 10px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
}
#myProgress {
  width: 100%;
  background-color: #ddd;
}
#myBar {
  width: 0%;
  height: 30px;
  background-color: #4caf50;
  text-align: center;
  color: #fff;
}
.super-car-row-vehi-boxes div.super-car-rvb-left {
  border: 1px solid #848484;
  display: block;
  width: 70%;
  padding: 20px 9px 20px 25px;
  font-size: 16px;
}
.super-car-row-vehi-boxes div.super-car-rvb-right {
  width: 30%;
  border: 1px solid #848484;
  font-size: 30px;
  text-align: center;
  letter-spacing: 7px;
}
.super-car-row-vehi-boxes > div {
  padding: 9px;
  font-size: 18px;
}
.super-car-fsb-box-left > div {
  border: 1px dotted;
  text-align: center;
  padding: 30px 30px 15px;
  border-radius: 28px;
}
.super-car-fsbb-circle {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #f3f4ff;
  border-radius: 56px;
}
.super-car-fsbb-price {
  font-size: 14px;
  font-weight: 700;
}
.super-car-fsb-box-left p {
  font-size: 16px;
  margin-top: 10px;
}
.super-car-fsb-butn {
  text-align: center;
  margin-top: 20px;
}
.super-car-fsb-butn button {
  width: 100% !important;
}
.super-car-fsbb-aed {
  padding-top: 19px;
}
#myRange2 {
  margin-top: 40px;
}
.super-car-box-due-cal {
  background: #f5f5f5;
  padding: 50px 0;
}
.super-car-bd-middle {
  background: #fff;
  padding-bottom: 40px;
}
.super-car-bd-middle h2 {
  text-align: center;
  color: #0451be;
  font-size: 36px;
  font-weight: 600;
  padding-top: 40px;
}
.super-car-row-competiion {
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.super-car-rc-right a {
  display: flex;
  font-size: 16px;
  text-decoration: none;
}
.super-car-rc-right a p {
  margin-top: 30px;
  margin-left: 10px;
}
.super-car-rc-left {
  margin-right: 50px;
}
.super-car-row-km-reading {
  margin-left: 0;
  margin-top: 60px;
}
.super-car-row-km-reading h2 {
  color: #000;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
}
.super-car-rc-right img {
  width: 20%;
  margin-top: 10px;
}
.super-car-bd-mid-container {
  width: 85%;
  margin: auto;
}
.super-car-rkb-left {
  width: 100px;
  text-align: center;
  font-size: 18px;
  padding: 19px;
  border: 1px solid #848484;
}
.super-car-rkb-right {
  border: 1px solid #848484;
  display: block;
  width: 100%;
  padding: 10px 10px 10px 30px;
  font-size: 30px;
  letter-spacing: 10px;
}
.super-car-oc-cal-box > button {
  width: 48%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border: 0;
  border-radius: 6px;
}
.super-car-bd-side-box {
  text-align: center;
  background: #fff;
  padding: 20px 20px 1px;
}
.super-car-bd-side-box h4 {
  color: #0451be;
  font-weight: 500;
  font-size: 14px;
}
.super-car-bd-side-box p {
  color: #000;
  font-weight: 600;
  font-size: 12px;
}
.super-car-lsi-box a {
  color: #000;
}
.super-car-ls-image-boxes {
  margin-top: 30px;
}
.super-car-bd-right > div h5 {
  color: #0451be;
  font-size: 14px;
  text-align: center;
}
.super-car-bd-download h3,
.super-car-bd-download h4 {
  color: #0451be;
  font-size: 34px;
  font-weight: 700;
}
.super-car-bd-download h4 {
  color: #000;
  font-weight: 400;
}
.super-car-slide-call-button {
  margin-top: 20px;
  width: 300px;
  padding: 10px;
  border: 0;
  background: #ff3201;
  color: #fff;
  font-size: 18px;
  border-radius: 72px;
}
.super-car-top-bar {
  margin-top: -10px !important;
}
.slider-hyperion .slick-dots li,
.slider-super-car .slick-dots li {
  width: 10px !important;
}
.slider-super-car .slick-dots {
  bottom: 10px !important;
}
.slider-hyperion .slick-dots li button:before,
.slider-super-car .slick-dots li button:before {
  font-size: 12px !important;
}
.slider-hyperion .slick-dots {
  bottom: 25px !important;
}
.super-car-book-input {
  border-radius: 10px;
  margin-top: 120px;
  width: 300px;
}
.super-car-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #0451be;
  border-radius: 50%;
  padding: 10px;
  width: 150px;
  height: 150px;
  text-align: center;
  color: #0451be;
  font-size: 14px !important;
  line-height: 1.2;
  font-weight: 500;
  background: #f5fbff;
}
.first-circle {
  left: 100px;
}
.second-circle {
  left: 160px;
  top: -7px;
}
.third-circle {
  top: -100px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.forth-circle {
  right: 160px;
  top: -7px;
}
.fifth-circle {
  right: 100px;
}
.galaxy-circle {
  margin-top: 120px;
  position: relative;
}
.common-invoice-bill,
.common-invoice-para {
  margin: 0;
  font-size: 13px;
  color: #000;
  padding: 0;
}
.footer-link {
  color: #1672be !important;
  font-family: poppins;
  font-size: 16px !important;
}
.footer-heading {
  font-size: 16px;
  font-family: poppins;
}
.footer-button-custom {
  background-color: #1672be;
  border-radius: 10px;
  color: #fff;
  border: 0;
  padding: 6px 12px;
}
.common-invoice-para {
  text-align: right;
  line-height: 26px;
}
.common-invoice-bill {
  line-height: 26px;
}
.custom-invoice-para {
  margin: 0;
  padding: 0;
  text-align: right;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.table_invoice_heading {
  font-size: 14px;
  background-color: #444;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 5px;
}
.table-invoice-tr {
  margin: 0 5px !important;
  border: 1px solid #000;
  text-align: center;
}
.table-invoice-tr td:first-child {
  text-align: left !important;
  padding-left: 10px;
}
.table-invoice-tr td {
  border: 1px solid #000;
  padding-left: 10px;
}
.custom-note-para {
  margin: 15px;
  padding-top: 5px;
  border-top: 2px dotted #000;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}
.invoice-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 13px;
  padding-bottom: 10px;
  vertical-align: bottom;
}
.invoice-custom-footer {
  border-top: 3px groove grey;
  color: #000;
  text-align: center;
}
.custom-height {
  height: 18px;
}
.custom-para-invoice {
  text-align: right;
  padding-right: 10px;
  font-weight: 700;
}
.table-invoice tr th:first-child {
  text-align: left;
  padding-left: 10px;
}
.custom-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-alignment-flex-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.job-history-card {
  background-color: #fff;
  box-shadow: 0 0 5px 2px #dddddd24;
  border-radius: 10px;
  padding: 15px;
}
.job-history-para {
  color: #36a4bf;
  margin: 0 5px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}
.job-history-para img {
  margin-right: 4px;
}
.job-history-heading {
  font-size: 12px;
  font-weight: 600;
}
.job-history-sub-heading {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}
.jb-title {
  color: #0451be;
  font-weight: 600;
}
.custom-color {
  background: #eef8fd;
  padding: 10px;
}
.singleline {
  height: 1px;
  background: #e5f1f7;
  margin: 5px 15px;
  width: calc(100% - 30px);
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
}
.btn-light-green {
  font-size: 12px;
  background: #c5f4be !important;
  color: #189007;
  font-weight: 500;
}
.btn-light-green:hover {
  background: #a5d79d !important;
  color: #0c4c03;
}
.blockelement {
  border-bottom: 2px solid #0451be;
  width: 100%;
}
.br-logo {
  position: absolute;
  right: 10px;
  top: 10px;
}
.br-logo img {
  width: 25px;
  height: auto;
}
.custom-color .slick-track {
  margin-bottom: 27px;
}
.selected-car::before {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #0451be;
  position: absolute;
  content: "";
  bottom: -13px;
  z-index: 100000;
  margin-left: -11px;
}
.custom-color .slick-initialized .slick-slide {
  padding: 0 4px;
}
.searcblock input {
  width: 150px;
  border: 1px solid #d2e1e9;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
}
.sort-block {
  cursor: pointer;
  height: 30px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d2e1e9;
  margin-left: 6px;
  width: 29px;
  text-align: center;
}
.hst-txt h3 {
  font-size: 16px;
  font-weight: 700;
}
.searcblock img {
  width: 18px;
  position: absolute;
  right: 5px;
  top: 6px;
  opacity: 0.8;
}
.sort-block img {
  width: 18px;
  opacity: 0.8;
  padding-top: 4px;
}
.cs-pagination .pagination a {
  padding: 3px 12px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
}
.cs-pagination .next a[aria-disabled="true"],
.cs-pagination .previous a[aria-disabled="true"] {
  background: #becad3;
  color: #ffff;
}
.cs-pagination .active a {
  border-color: #0451be;
}
.overlapblock {
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 15px;
  z-index: 100;
  font-size: 11px;
  color: #314165;
  height: 0;
  transition: 0.5s;
  overflow: hidden;
  bottom: 0;
}
.custom-color .item:hover .overlapblock {
  height: 97%;
  padding: 15px;
}
.brand_block > div {
  display: flex;
  padding-bottom: 12px;
}
.brand_block div span {
  color: #596670;
}
.brand_block img {
  width: 45px;
}
.overlapblock .unlist li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.job-history-card .ml-2 {
  width: 90%;
}
.job-history-card .row {
  margin-right: -5px !important;
  margin-left: -5px !important;
}
.job-history-card [class^="col-"] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.history-left-section,
.ob_history-section {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}
.common_history_font {
  font-size: 12px;
  padding: 2px 0;
}
.common_history_para {
  font-size: 14px;
  line-height: 22px;
}
.history-right-section {
  background: #fff;
  padding: 10px;
}
.invoice_sub_heading {
  font-size: 15px;
  color: #7d8291;
  font-family: Poppins;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .job-history-sub-heading {
    font-size: 11px;
  }
  .job-history-card {
    padding: 15px 10px;
  }
}
.light-heading {
  color: #7d828f;
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 6px;
}
.cs-hg {
  height: 65px;
}
.custom-font-size-payment,
.pdf-card .job-history-heading {
  font-size: 10px;
}
.pdf-card {
  padding: 13px 5px;
}
.pdf-card .job-history-sub-heading {
  font-size: 10px;
  white-space: initial;
  overflow: inherit;
  text-overflow: inherit;
  width: 100%;
}
.rounder-widget {
  border-radius: 10px;
}
.pdf-card img {
  width: 37px;
  height: 35px;
}
.pdf-card .Consumables-div p {
  font-size: 10px !important;
}
.super-car-workshop-card {
  margin: 10px;
  box-shadow: 0 0 10px rgba(9, 20, 50, 0.15);
  text-align: center;
  padding: 20px;
  border-radius: 27px;
  background: #fff;
}
.super-car-workshop-card img {
  width: 100% !important;
  height: 170px !important;
  object-fit: cover !important;
  border-radius: 10px;
  margin-bottom: 23px;
}
.super-car-modal-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.super-car-custom-button {
  background: #ff3201;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px;
}
.super-car-custom-button:hover {
  background: #e56f0a;
  color: #fff;
}
.super-car-modal-inner {
  padding: 0 !important;
  max-width: 100% !important;
  margin-top: 20px;
}
.super-car-modal-div {
  background: #dee2e6;
}
.super-car-modal-brand {
  padding: 5px 15px !important;
}
.super-car-modal-input {
  margin: 10px 0 0;
  padding: 0 15px !important;
}
.super-car-modal-input small {
  white-space: nowrap;
}
.super-car-modal-input input {
  font-size: 14px;
  padding: 5px 15px !important;
}
.super-car-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.super-car-serve-link {
  font-size: 13px !important;
  cursor: pointer;
  margin-top: 12px;
}
.custom-rounded-circle {
  border-radius: 30%;
}
.common-h6 {
  padding: 12px 0 13px;
}
.op-no-space {
  padding-left: 0;
  padding-right: 0;
}
.custom_ob_wu_box__3BG86 p {
  text-align: inherit !important;
}
.order-page-slick-slider .slick-next {
  right: -20px;
}
.order-page-slick-slider .slick-prev {
  left: -20px;
}
.offer-list-slider .slick-next {
  right: -20px;
  top: 32px;
}
.offer-list-slider .slick-prev {
  left: -20px;
  top: 32px;
}
.disable-pay-now :hover {
  background: grey !important;
}
.disable-pay-now {
  background-color: grey !important;
}
@media (max-width: 768px) {
  .tyre .tab p {
    width: 90px;
    margin-top: 5px;
  }
  .tyre .tab.active p{
    font-size: 14px;
    width: 131px;
    margin-top: 5px;
  }
  .tyre .tab.active p img{
   width: 15px !important;
  }
  .job-history-sub-heading,
  .mb-dwd {
    width: 100%;
  }
  .date-view {
    display: none;
  }
  .hide-in-mobile {
    display: none !important;
  }
  .job-history-card .tpcell {
    padding-bottom: 10px;
  }
  .mb-dwd {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: block !important;
  }
  .cnt-block h4 {
    border-top: 0;
  }
  .order-item-activeEstimateValue {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .order-item-activeEstimate {
    font-size: 13px !important;
  }
  .quoteSlider .slick-prev {
    z-index: 1;
    left: -6px;
}
.quoteSlider .slick-next {
  right: -6px;
}
}
@media (max-width: 480px) {
  .mobile-scroll-modal {
    max-height: 82vh !important;
  }
  .section-partner .slick-next,
  .section-partner .slick-prev,
  .section-top-selling .slick-next,
  .section-top-selling .slick-prev,
  .sm-brand-slider .slick-next,
  .sm-brand-slider .slick-prev {
    display: none !important;
  }
  .mb-block {
    flex-direction: column;
  }
  .info-hisoryicon {
    position: absolute;
    left: 15px;
    color: #0451be;
    font-size: 17px;
    display: block;
  }
  .custom-color .item:hover .hide-car-info,
  .jb-name,
  .mb-no,
  .super-car-service-circle-2::before,
  .super-car-service-circle-3::before {
    display: none;
  }
  .my-orders .tag-rec,
  .my-orders .tag-rec-selected {
    margin: 0 2px 0 0 !important;
  }
  .job-history-para {
    width: 100%;
    justify-content: center;
    padding: 4px;
    background: #eaf4f7;
    border-radius: 35px;
    margin-bottom: 10px;
  }
  .job-history-para:last-child {
    border-bottom: 0;
  }
  .no-space-block {
    padding: 0 !important;
  }
  .job-history-heading {
    font-size: 11px;
    font-weight: 600;
  }
  .Consumables-div .font-weight-bold {
    font-weight: 600;
  }
  .custom-color {
    background: #eef8fd;
    padding: 10px 0;
  }
  .mb-view {
    display: block;
    opacity: 0.5;
  }
  .first-circle,
  .second-circle,
  .third-circle {
    position: initial !important;
  }
  .offer-list-slider ul.slick-dots {
    bottom: -18px !important;
  }
  .custom_ob_wu_first__UZnDs {
    margin-top: 36px !important;
  }
  .custom_ob_why_use__DDup0 {
    padding-bottom: 12px !important;
  }
  .super-car-obj-but-2 a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
  .service-circle-1 {
    height: 15px !important;
  }
  .super-car-service-circle p {
    font-size: 16px !important;
  }
  .super-car-service-circle-3,
  .super-car-service-circle-4 {
    margin-top: 20px !important;
  }
  .super-car-service-circle {
    padding-left: 15px;
  }
  .super-car-service-circle-3 {
    margin-top: 0 !important;
  }
  .super-car-service-circle-4 img {
    width: 70% !important;
  }
  .second-circle {
    top: -5px !important;
    right: 15px !important;
    left: auto !important;
  }
  .first-circle {
    left: 20px !important;
    top: -8px;
  }
  .third-circle {
    top: -38px !important;
    margin: 0 !important;
  }
  .forth-circle {
    margin: 0 !important;
    position: absolute !important;
    top: 226px !important;
    left: calc(50% - 64px) !important;
  }
  .super-car-ob-about h1 {
    font-size: 25px !important;
  }
  .super-car-oba-box {
    padding-top: 20px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ob-mobile-super-car {
    font-size: 19px !important;
  }
  .super-car-oba-box button {
    font-size: 18px !important;
  }
  .fifth-circle {
    right: 10px !important;
    bottom: 15px !important;
    top: auto !important;
    position: initial !important;
  }
  .opp-block {
    margin-top: -26px;
    padding: 20px 0;
    flex-wrap: wrap;
    gap: 39px;
  }
  .super-car-service-circle img {
    width: 130px !important;
    height: 130px !important;
  }
  .custom_buy-contract-banner__1yw_h h1 {
    font-size: 25px;
    padding-bottom: 20px;
  }
  .super-car-35 {
    font-size: 25px !important;
    padding-top: 25px !important;
  }
  .galaxy-circle .super-car-circle {
    width: 125px !important;
    height: 125px !important;
    font-size: 13px !important;
    line-height: 15px !important;
    padding: 10px;
  }
  .ob-jobs h2 {
    font-size: 30px !important;
  }
  .ob-jobs-3 {
    top: inherit !important;
  }
  .super-car-service-circle-7 {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
  .super-car-ob-partner h2 {
    font-size: 38px !important;
  }
  .op-offer-a p,
  .super-car-ob-partner p {
    font-size: 14px;
  }
  .super-car-ob-brands ul li {
    width: 50%;
    text-align: center;
    margin-bottom: 25px;
  }
  .super-car-ob-brand-logos a {
    width: inherit !important;
    height: inherit !important;
  }
  .super-car-ob-super-charge-box {
    margin-top: 33px;
  }
  .super-car-hy-para p {
    font-size: 14px;
    margin-top: 10px;
  }
  .super-car-serve-link {
    text-align: center;
  }
  .heading-guid {
    font-size: 30px;
  }
  .super-car-custom-border {
    border-bottom: 0 dashed #a3a3a3 !important;
    margin: 0 10px;
    padding: 20px 0;
  }
  .list-guid-super-car ul,
  .super-car-ob-garage-col .col-sm-4 {
    margin-bottom: 20px;
  }
  .super-car-ob-garage-col img {
    margin-bottom: 10px !important;
    border-radius: 10px;
  }
  .super-car-ob-brands h4 {
    font-size: 30px;
    font-family: inherit !important;
  }
  .custom_ob-get-app__1R--X,
  .custom_ob-hyperion__3mWQI {
    padding-bottom: 52px !important;
  }
  .cnt-bl-block h4 {
    border-top: 0;
    padding-top: 0 !important;
  }
  .list-guid-super-car ul li {
    padding-bottom: 5px;
    font-size: 14px;
  }
  .subblock-head {
    padding-bottom: 0;
    font-size: 14px;
  }
  .sp-btbar {
    margin-left: -12px;
  }
  .super-car-slide-heading h1 {
    letter-spacing: inherit !important;
  }
  .brpads {
    padding-top: 30px;
  }
  .brpads .super-car-service-circle {
    padding-left: 0;
  }
  .super-car-oba-box p {
    padding: 20px 0;
  }
  .custom_ob_wu_box__3BG86 p {
    text-align: inherit !important;
  }
  .super-car-ob-prem-service {
    margin-bottom: 24px !important;
  }
  .txtblock-new p {
    padding: 15px 0;
  }
  .super-car-service-circle-5 {
    margin-left: -5px !important;
  }
  .super-car-service-circle p {
    padding-top: 10px;
  }
  .op-offer-a {
    margin-top: -10px !important;
  }
  .super-car-custom-border img {
    width: 47px;
  }
  .super-car-custom-border p {
    font-size: 13px;
    height: 34px;
  }
  .newcustom-check {
    margin: 6px 0;
  }
  .iconblock-first p {
    padding: 0 13px;
  }
  .custom-slder-section-why .slick-dots {
    bottom: 11px;
  }
  .custom-slder-section-why .slick-dots li button:before {
    font-size: 15px;
  }
}
p,
section p,
section p:last-child {
  margin-bottom: 1rem;
}
.top-fix {
  height: 121px;
}
.top-fix-service {
  height: 115px;
}
p,
section p {
  margin-top: 0;
}
.choose-car-top-common {
  top: 0 !important;
}
.workshop-badge-custom {
  position: absolute;
  right: 0;
  top: 0;
}
.slider-text-lup .slick-dots,
.slider-why .slick-dots {
  cursor: default;
  pointer-events: none;
}
.workshop-custom-h1 {
  width: calc(100% - 138px);
  overflow: hidden;
  white-space: nowrap;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}
.style-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.star-skn-block svg {
  fill: #ffba07;
}
.subHead-all {
  margin-bottom: 1rem !important;
}
.bg-layer {
  z-index: 9;
}
.dropdown-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  transition: 0.3s;
}
.island-box {
  z-index: 11;
  top: 114px;
}
.island-box-checkout {
  z-index: 11;
  top: 74px !important;
}
.island-box-old {
  top: 85px;
}
.choose-car-top {
  top: 21px !important;
  height: calc(100vh - 112px) !important;
}
.cart-list-area {
  height: calc(100vh - 312px);
}
.cart-header {
  top: 117px;
}
.timeline {
  margin-top: 55px;
}
.sos-menu-class {
  top: 114px !important;
  padding-top: 20px !important;
  height: calc(100% - 114px);
}
.layer {
  margin-top: 8px;
  left: 57%;
}
.bg-layer {
  margin-top: -7px;
}
.nav-item.active .active {
  font-weight: 600;
  color: #0451be;
  border-bottom: 0 solid #0451be;
}
.arrow-active:before {
  bottom: -5px;
  color: #0451be;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  content: "\f106";
  font-family: FontAwesome;
  font-size: 16px;
}
.product-area-new {
  padding-top: 55px !important;
}
@media screen and (max-width: 768px) {
  .top-fix-service {
    height: 145px;
  }
  .header-fix-common {
    margin-top: -88px !important;
  }
  #service-category-area {
    padding-top: 10px;
    margin-left: -10px;
    padding-left: 10px;
  }
  .dropdown-backdrop {
    z-index: 90;
  }
  .mobile-car-selection {
    margin-top: 107px;
  }
  .search-brand {
    justify-content: space-around;
  }
  .search-brand-custom {
    justify-content: flex-start;
  }
  .my-car-button {
    width: 122px;
  }
  .top-fix {
    height: 157px;
  }
  .sos-mob {
    top: 146px;
  }
  .island-box {
    top: 138px;
    z-index: 12;
  }
  .island-box-old {
    z-index: 10;
    top: 90px;
  }
}
.quotation-scrollbar {
  position: relative;
  height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.car-brand-scroll > div:nth-child(2) div,
.scroll-workshop > div:nth-child(2) div {
  background-color: transparent !important;
}
.scroll-workshop > div:first-child {
  overflow-x: hidden !important;
}
.car-brand-scroll > div:first-child {
  padding-bottom: 20px !important;
}
.flx-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  width: 100%;
}
.bdg-txt-block img {
  width: 110px;
}
.blacktxt-bdg,
.txt-bdg {
  padding-left: 4px;
}
.txt-bdg p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}
.txt-bdg span {
  color: #ffd1d2;
  font-size: 12px;
  font-weight: 400;
  display: block;
}
.custom-span-checkout {
  display: block;
  position: absolute;
  margin-top: -16px;
  font-size: 16px;
}
.valid-txt {
  font-size: 12px;
  color: #fff;
  text-align: right;
}
.valid-txt span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: -5px;
  color: #fff;
}
.features-list {
  font-size: 11px;
  border-top: 1px solid #c3c3c3;
  margin-top: 4px;
  padding-top: 2px;
}
.lvup-price {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.cs-tooltip .tooltip-inner {
  text-align: left !important;
}
.cs-tooltip .arrow {
  bottom: 4px !important;
}


.auth-validation-div {
  position: relative;
  /* margin: 10px 5px 20px 30px !important; */
}
.custom-validation-auth {
  margin-bottom: 12px !important;
}
.auth-validation-span {
  position: absolute;
  font-size: 12px;
  left: 0px;
  bottom: -18px;
  
}
.auth-validation-span-error {
  position: absolute;
  font-size: 12px;
  left: 10px;
  bottom: -18px;
  
}

.custom-margin {
  margin: 10px 5px 12px 0 !important;
}
.scroll-bar-brand > div:first-child {
  margin-bottom: 0 !important;
  padding-bottom: 30px !important;
}
.tabby-cross-button {
  text-align: right;
  margin-bottom: 15px;
}
.tabby-cross-button button {
  border: none;
  background: 0 0;
  font-size: 22px;
  font-weight: 600;
  padding: 0;
}
.edit_address > div {
  border: none;
  line-height: 31px;
}
.opcart-block {
  padding: 2px 7px !important;
  min-height: 70px;
}
.opcart-block .op-offertxt {
  margin-top: -7px !important;
  margin-bottom: 20px !important;
}
.tabby-block-list {
  position: absolute;
  bottom: -5px;
}
.tabby-block-list label {
  font-weight: 400;
  color: #c5c5c5;
}
.tabby-block-list img {
  margin-top: 2px;
  width: 32px;
  height: auto;
  margin-bottom: -4px;
}
.sp-icon-quest {
  margin-left: 3px;
}
.checkoutpar {
  font-size: 12px;
  width: 109px;
  font-weight: 500;
  color: #7c7c7c;
}
.chekcout-page-head {
  margin-top: -60px;
}
.custom-height-checkout {
  margin-top: -12px !important;
}
.rounded-order-edge {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.rounded-order-reverse-edge {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-setting-order {
  padding: 6px 14px !important;
  margin-top: -27px;
  margin-bottom: 5px;
}
.custom-setting-package {
  padding: 6px 14px !important;
  margin-bottom: 5px;
}
.i-paid{
  display: none !important;
}
.tyre-rest-btn {
  background: #e7efff;
  color: #3a63b8;
  font-size: 13px;
  border: 1px solid #e7efff;
  cursor: pointer;
  padding: 2px 10px;
  font-weight: 500;
  border-radius: 20px;
  margin-right: 15px;
  height: 60%;
  
  margin-top: 10px;

  /* padding: 0px 0px; */
}
.tyre-back-btn {

  color: #3a63b8;
  font-size: 13px;
  cursor: pointer;
  padding: 2px 10px;
  font-weight: 500;
  margin-left: 15px;
  height: 60%;
  margin-top: 10px;

  /* padding: 0px 0px; */
}
@media only screen and (max-width: 480px) {
  .sr-service-dv {
    padding-top: 10px !important;
  }
  .sr-service-dv1 {
    padding-top: 1px !important;
  }
  .text-center-mobile {
    text-align: center;
  }
  .top-fix {
    height: 144px;
  }
  .product-area-new {
    padding-top: 88px !important;
  }
  .package-title-mobile {
    flex-wrap: wrap;
    padding-right: 60px !important;
  }
  .package-title-mobile span {
    margin-left: 0 !important;
  }
  .map-wp-container {
    height: 250px;
  }
  .cart-view {
    width: 100%;
    height: calc(100% - 146px) !important;
  }
  .cart-view-empty-workshop {
    width: 100%;
    height: calc(100% - 107px) !important;
    animation: 0.6s ease-in-out forwards slide-up;
  }
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  .cart-header {
    top: 145px;
  }
  .timeline {
    margin-top: 20px;
  }
  .line-box-custom-width {
    width: 90% !important;
  }
  .cart-list-area {
    height: calc(100vh - 389px) !important;
  }
  .subscription-btn .mb-1 {
    margin-top: -6px;
    margin-right: 15px;
  }
  .subscription-btn .mb-1 a {
    color: #000 !important;
  }
  .custom-height-checkout {
    margin-top: -12px !important;
  }
  .auth-validation-div {
    position: relative;
    margin: 10px 5px 20px 10px !important;
  }
  .custom-margin-validaiton {
    margin-top: 2px !important;
  }
  .cutsom-label-txt {
    margin: 5px 2px !important;
  }
  .chekcout-page-head {
    margin-top: -154px;
  }
  .header-fix-common {
    margin-top: -90px !important;
  }
  .logo-checkout-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .tabby-checkout {
    margin-top: -21px;
  }
  .checkoutpar {
    width: max-content;
    white-space: nowrap;
  }
  .font-minicheckout .h4 {
    font-size: 16px;
  }
  .tabby-block-list {
    bottom: -2px;
  }
  .op-offertxt {
    margin-bottom: 25px !important;
  }
  .tabby-block-list img {
    margin-bottom: -1px;
  }
}
.action-del-block {
  display: flex;
  gap: 10px;
  padding: 10px 0;
}
.workshop-button-cst:hover {
  border: 0;
  color: #fff;
}
.address-checkout-flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
}
.workshop-add-mdl {
  font-size: 11px;
  font-weight: 600;
  padding: 11px 13px;
  height: 20px;
  position: absolute;
  right: 40px;
}
.workshop-add-mdl:hover {
  background: #000;
}
.add-mdl-cont {
  padding: 10px;
  background: #eff1f1;
}
.address-checkout-div {
  background: #fff;
  border-radius: 10px;
  padding: 10px 15px 12px;
  margin-bottom: 9px;
  box-shadow: 0 0 2px 2px #cccccc1f;
  border: 2px solid #fff;
  position: relative;
}
.address-checkout-div.active-address {
  border-color: #0069d9;
}
.radio-address {
  position: absolute;
  right: 20px;
  top: 8px;
  font-size: 20px;
  color: #6f6f6f !important;
}
.radio-address.radio-address-active {
  color: #0069d9 !important;
}
.edit-pencil-op {
  font-size: 17px !important;
  color: #6f6f6f !important;
}
.margin-pencil {
  margin-right: 30px;
}
.ms-magin {
  margin-top: -4px;
}
@keyframes shinee {
  0% {
    left: -100px;
  }
  100%,
  20% {
    left: 100%;
  }
}
.glareanimation {
  overflow: hidden;
  position: relative;
}
.glareanimation:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: 2s linear infinite shinee;
}
.workshop-tag-choice-smart-selection {
  position: absolute;
  font-size: 9px;
  right: 3px;
  top: 6px;
  width: 151px;
}
.change-btn-ft-cart {
  height: 10px;
  padding-top: 27px;
}
.earning-outer-block {
  border-radius: 15px;
  border: 1px solid #d5e2eb;
  padding: 10px 20px 20px;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.03);
  margin-top: 65px;
}
.earning-container .earning-left {
  position: absolute;
  width: 230px;
}
.earning-header-txt h3 {
  color: #0451be;
  font-size: 25px;
  font-weight: 600;
  padding-top: 2px;
}
.earning-left img {
  border-radius: 16px;
}
.anm-txt li label {
  background: linear-gradient(to right, #f55f1f, #f55f1f 50%, #66788d 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: 0.4s ease-in-out;
  padding: 5px 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #66788d !important;
}
.anm-txt {
  padding: 0;
  margin: 0;
  list-style: none;
}
.anm-txt li {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.5s, -webkit-transform 0.5s, -moz-transform 0.5s;
  padding: 10px 5px;
}
.anm-txt li span {
  width: 25px;
  height: 25px;
  background: #66788d;
  border-radius: 25px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.earning-right {
  padding-left: 245px;
}
.anm-txt li label:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 0;
  transition: 0.3s ease-in-out;
}
.anm-txt li label:hover {
  background-position: 0;
}
.blacktxt-bdg p,
.blc-txt,
.lvup-price {
  color: #000 !important;
}
.custom-pdf-ob a,
.slider-text-lup .slick-dots li button:before {
  color: black !important;
}
.lvlup-logo {
  padding-left: 5px;
}
.lvlup-logo img {
  width: 50px;
  height: 50px;
  padding-top: 0;
  margin-right: 5px;
}
.cr-lg-action {
  margin-top: 4px;
  margin-bottom: -4px;
}
.chk-lvup-widget {
  background: #ebf0f5;
  border-radius: 5px;
  border: 1px solid #e1e5e9;
  color: #fff;
  padding: 4px;
}
.chk-lvup-widget-dflex {
  gap: 8px;
}
.chk-lvup-widget-dflex img {
  width: 55px;
}
.lvup-tgle-btn {
  position: absolute;
  right: 7px;
}
.text-white-download-order {
  color: #dae837;
  font-size: 12px;
}
.default-address-profile {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  padding: 8px;
}

.marquee-container {
    overflow: hidden; /* Hide the overflow content */
  }
  
  .marquee {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap; /* Prevent text wrapping */
    animation: marquee-animation 10s linear infinite; /* Adjust duration as needed */
  }
  .select-control-1{
    margin:10px 0px 22px 30px
  }
  .select-control-2{
     margin:10px 5px 22px 0px
  }
  @keyframes marquee-animation {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  

.text-white-download-order:hover {
  color: #dae837;
}
.custom-pdf-ob {
  margin-top: -13px;
  margin-right: -10px;
}
.custom-pdf-ob a {
  border: 1px solid #0452be;
  font-size: 10px;
  margin-top: -14px;
  display: inline-block;
  background: #f3f4ff;
  border-radius: 10px;
  padding: 4px 7px;
}
.custom-pdf-ob a svg {
  font-size: 17px;
}
.car-slide-mobile .slick-dots {
  bottom: 11px;
}
.custom-home-header-text {
  font-weight: 500;
  padding-top: 10px;
  color: #848484;
  font-size: 15px;
  margin-left: 5px;
}
.margin-top-custom {
  margin-top: -100px;
}
@media only screen and (max-width: 700px) {
  .earning-container .earning-right {
    padding-left: 0;
  }
  .earning-header-txt h3 {
    font-size: 18px;
  }
  .earning-container .earning-left {
    position: static;
    width: 100%;
  }
  .anm-txt li label {
    width: 80%;
  }
  .earning-outer-block {
    margin-top: 20px;
  }
  .anm-txt {
    margin-top: 10px;
  }
  .anm-txt li {
    padding: 6px 4px;
  }
}
@media only screen and (max-width: 450px) {
  .i-paid{
    display: none !important;
  }
  .card-slide-level-up .slick-dots {
    display: none !important;
  }
  .margin-top-custom {
    margin-top: -30px;
  }
  .custom-pdf-ob a {
    font-size: 11px;
  }
  .why-OB-mobile-margin {
    margin-top: 25px;
  }
  .custom-adjust-screen {
    height: 100% !important;
    justify-content: space-around;
  }
  .custom-home-header-text {
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
    margin-left: 10px;
  }
  .show-section-mobile {
    display: block !important;
  }
  .show-section-web {
    display: none;
  }
  .txtlf {
    text-align: left;
  }
  .custom-setting-order {
    flex-wrap: wrap;
  }
  .custom-setting-package {
   width: 100%;
  }
  .job-status-custom {
    width: 100%;
  }
  .text-white-download-order {
    padding-top: 10px;
  }
  .mob-mr-5 {
    margin-right: 3px !important;
  }
  .earning-container .earning-right {
    padding-left: 0;
  }
  .earning-header-txt h3 {
    font-size: 18px;
  }
  .earning-container .earning-left {
    position: static;
    width: 100%;
  }
  .anm-txt li label {
    width: 80%;
  }
  .earning-outer-block {
    margin-top: 20px;
  }
  .anm-txt {
    margin-top: 10px;
  }
  .anm-txt li {
    padding: 6px 4px;
  }
  .change-btn-ft-cart button {
    padding: 0 12px !important;
    background: red;
  }
  .change-btn-ft-cart {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 450px) and (max-width: 1023px) {
  .request-form-position-col .show-section-web {
    display: none
  }
  .i-paid{
    display: block !important;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1272px) {
  .request-form-desktop{
    flex-direction: column !important;
  }
  .request-form-desktop .request-colunm{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .select-control-2{
    margin:10px 13px 13px 40px!important
  }
  .select-control-1{
    margin:10px 13px 13px 40px!important
  }
}
.search-Text{
    color: #5e5e5e;
}


@media only screen and (min-width: 997px) and (max-width: 1279px) {
  .auth-validation-span-signup {
    font-size: 9px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 996px) {
  .auth-validation-span-signup {
    font-size: 8px !important;
  }
}
.memoModal .modal-content {
  border-radius: 1.6rem;
}
.custom-modal-backdrop {
  background-color: rgba(8, 8, 38, 0.7);
}
.no-border {
  border: 0 !important;
}
.sptxt-bloxk {
  color: #e2edfc !important;
  margin-bottom: 0;
}
.orng-txt,
.prz-lvup small,
.sptxt-bloxk span {
  color: #f47721;
}
.levelup-checkout-label img {
  width: 69px;
  height: auto;
  margin-top: -2px;
  border-radius: 3px;
  margin-left: 1px;
}
.card-banner-block-up {
  background: url("/assets/home/Level-UP-bg.webp") 0 0/100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}
.prmtxt label {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 45px;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 33px;
}
.prmtxt small {
  color: #fff;
  font-size: 19px;
  display: block;
}
.prmtxt span {
  display: block;
  font-weight: 400;
  font-size: 15px;
  margin-top: -5px;
  color: #0451be;
}
.custom-prz,
.inro-txt {
  padding-top: 30px;
}
.inro-txt span {
  background: #252b61;
  display: block;
  text-align: center;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #fff;
  padding: 4px;
  background: linear-gradient(
    90deg,
    rgba(37, 43, 97, 0) 0,
    #0451be 15%,
    #0451be 85%,
    rgba(171, 27, 131, 0) 100%
  );
}
.custom-prz label {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.custom-prz span {
  color: #f47721;
  font-size: 30px;
  padding: 0 3px;
}
.prz-lvup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
}
.img-banner-block {
  background: url(/assets/home/annual-new.webp) 0 0/100% no-repeat;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  max-height: 400px;
}
.mechanic-container-custom {
  display: flex;
  flex-direction: column;
  height: 400px;
}
.tyre-icon-annual {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 250px;
}
.title-bar-annual h2 {
  font-size: 27px;
  font-weight: 700;
  color: #000;
  padding-bottom: 0;
}
.choose-car-block,
.title-bar-annual h3 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 21px;
}
.title-bar-annual h3 {
  letter-spacing: 4px;
  color: #053977;
  margin-top: -10px;
}
.mob-mac-block h1,
.title-bar-annual label strong {
  font-size: 32px;
  font-weight: 600;
}
.title-bar-annual label {
  background: #ffef00;
  padding: 3px 10px;
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
}
.action-bar-annual {
  display: block;
  padding: 10px;
}
.title-bar-annual {
  padding: 20px 10px;
}
.btn-an-btn,
.req-an-btn {
  padding: 7px 25px;
  color: #fff;
  outline: 0;
  margin-right: 10px;
}
.empty-brand .title-bar-annual {
  padding-bottom: 0;
}
.btn-an-btn {
  background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  border: 0;
  border-radius: 20px;
}
.req-an-btn {
  background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
  border: 0;
  border-radius: 20px;
}
.list-bar-annual {
  padding: 10px;
  margin-top: 20px;
}
.new-ob-offer {
  padding: 70px 0;
}
.list-bar-annual ul li {
  list-style: disc inside;
  font-size: 13px;
  padding: 4px 0;
}
.list-bar-annual ul li span {
  position: relative;
  left: -10px;
}
.mob-mac-img {
  text-align: center;
  height: calc(100% - 168px);
}
.mob-mac-img-not-selected {
  height: calc(100% - 196px);
  text-align: center;
}
.choose-car-block {
  padding: 5px 0 10px;
  letter-spacing: 1px;
  color: #053977;
}
.empty-brand .action-bar-annual {
  padding-bottom: 49px;
}
@media screen and (max-width: 1600px) {
  .img-banner-block {
    background-size: initial;
  }
}
@media screen and (max-width: 1330px) {
  .tyre-icon-annual {
    width: 180px;
  }
  .img-banner-block {
    background-size: initial;
  }
}
@media screen and (max-width: 500px) {
  .heading-common-font-size {
    font-size: 25px !important;
  }
  .sub-heading-common-font-size {
    font-size: 16px !important;
  }
  .new-ob-offer {
    padding: 0 !important;
    margin-top: 30px;
  }
  .show-section-mobile {
    display: block;
  }
  .action-bar-annual button {
    margin-bottom: 10px;
    width: 100%;
  }
  .mob-mac-img img {
    width: 100%;
    height: 220px;
    object-fit: contain;
  }
  .tyre-icon-annual {
    display: none;
  }
  .card-banner-block-up {
    background-position: bottom;
  }
  .inro-txt {
    padding-top: 15px;
  }
  .prmtxt small {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .custom-prz,
  .prmtxt {
    padding-top: 0;
  }
  .prmtxt label {
    font-size: 30px !important;
  }
  .prmtxt span {
    border-top: 1px solid #686868;
    border-bottom: 1px solid #686868;
    padding: 6px 0;
    margin-top: 14px;
  }
  .mechanic-container-custom,
  .mob-mac-img {
    height: auto;
  }
  .empty-brand .action-bar-annual {
    padding-bottom: 0;
  }
  .list-bar-annual ul li {
    display: flex;
  }
  .action-bar-annual {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .title-bar-annual {
    padding: 7px 10px;
  }
  .title-bar-annual h3 {
    font-size: 15px;
    letter-spacing: 3px;
    margin-top: -10px;
  }
  .title-bar-annual h2 {
    font-size: 20px;
  }
  .list-bar-annual {
    margin-top: 0;
  }
  .img-banner-block {
    margin-bottom: 20px;
    max-height: initial;
  }
  .mob-mac-img {
    margin-top: -9px;
  }
  .title-bar-annual label {
    margin-top: 20px !important;
  }
  button.req-an-btn {
    margin: 0 !important;
  }
  .cart-island-on-mobile-without-header {
    height: calc(100% - 106px) !important;
  }
}
@media screen and (max-width: 390px) {
  .auth-validation-div  {

  }
  .req-an-btn-tyre{
    font-size: 12px !important;
  }
}
.heading-common-font-size {
  font-size: 32px !important;
  font-weight: 600 !important;
}
.sub-heading-common-font-size {
  font-size: 18px !important;
  color: #848484 !important;
}
.main-new-heading {
  color: #687188 !important;
}
.request-text-save-custom-mobile1 {
  font-size: 10px;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .margin-modal {
    margin-left: 10px;
    margin-right: 10px;
  }
}



.pac-container {
  z-index: 3000 !important;
}
.package-conform-btm-buttom {
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(247, 146, 34, 1) 0%,
    rgba(255, 50, 1, 1) 100%
  );
  
  border: 0px;
  outline: 0px;
  border-radius: 40px;
  cursor: pointer;
 
}
.glare-animation-package {
 
    position: relative;
    overflow: hidden;

}
.glare-animation-package:before {

    content: "";
    position: absolute;
    width: 160px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine 2s infinite linear;
    /* Animation */
 
}
.package-unconform-btm-buttom{
  background: rgb(87, 112, 139);
  color: "#ffffff";
  cursor: "initial";
  border: "none";
  border-radius: 40px;
}