._cart_island--op {
  .sidebar-crt-skin {
    background: #fff;
    height: 100vh;
    box-shadow: -4px 0 7px 0px #7777773d;
    width: 40%;
  }

  .bg-layer {
    width: 100%;
    height: 100%;
    background: #0303036e;
    position: fixed;
    z-index: 1;
    left: 0px;
    top: 0px;
  }

  .sidebar-container-block {
    z-index: 2;
    padding: 0px;
  }

  .sidebar-crt-skin {
    position: fixed;
    height: calc(100% - 119px);
    margin-top: -2px;
  }

  .scroller-box {
    height: 50%;
    overflow: hidden;
    overflow-y: scroll;
    background: #ddd;
  }

  .tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-container-island {
    padding: 15px 10px;
  }

  .island-cs-tabs {
    height: 36px;
    display: flex;
    width: 100%;
    background: #f8fbfd;
    border: 1px solid #cbdbe2;
    position: relative;
    border-radius: 3px;
    list-style: none;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    isolation: isolate;
  }

  .island-cs-tabs li {
    width: 25%;
    text-align: center;
    border-right: 1px solid #cbdbe2;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
  }

  .island-cs-tabs:after {
    content: "";
    display: block;
    width: 25%;
    height: 100%;
    position: absolute;
    left: var(--left, 0%);
    top: 0;
    background-color: #fff;

    z-index: -1;
    transition: left 0.2s ease;
  }

  .island-cs-tabs li:last-child {
    border-right: 0px;
  }

  .island-cs-tabs:before {
    background: url(../img/island/drop-down.svg);
    content: "";
    display: block;
    width: 28px;
    height: 17px;
    position: absolute;
    left: var(--left, 10%);
    bottom: -10px;
    /* background-color: #fff; */
    z-index: 1000;
    transition: left 0.2s ease;
  }

  .island-cs-tabs label {
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    display: block;
    line-height: 33px;
    cursor: pointer;
  }

  .island-cs-tabs label i {
    width: 19px;
    height: 19px;
    border-radius: 20px;
    line-height: 19px;
    font-style: normal;
    background: #6c7676;
    display: inline-block;
    color: #fff;
    font-size: 11px;
  }

  .island-cs-tabs label span {
    padding-left: 5px;
  }

  .island-cs-tabs :checked+label {
    color: #ff3201;
  }

  .island-cs-tabs :checked+label i {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs:has(#checkedone):after {
    --left: 0%;
  }

  .island-cs-tabs:has(#checkedtwo):after {
    --left: 25%;
  }

  .island-cs-tabs:has(#checkedthree):after {
    --left: 50%;
  }

  .island-cs-tabs:has(#checkedfour):after {
    --left: 75%;
  }

  .island-cs-tabs:has(#checkedone):before {
    --left: 10%;
  }

  .island-cs-tabs:has(#checkedtwo):before {
    --left: 35%;
  }

  .island-cs-tabs:has(#checkedthree):before {
    --left: 60%;
  }

  .island-cs-tabs:has(#checkedfour):before {
    --left: 85%;
  }

  .island-cs-tabs input[type="radio"] {
    display: none;
  }

  .island-scroller-box-s1 {
    max-height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: scroll;
  }

  .island-scroller-box-s5 {
    padding: 10px;
    max-height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: scroll;
  }

  .island-scroller-box-s6 {
    padding: 10px;
    max-height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: scroll;
  }

  .widget-service-island {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
  }

  .widget-service-island-ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .widget-service-island-ul li {
    display: flex;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    min-height: 85px;
  }

  .island-sr-right {
    padding-left: 75px;
  }

  .widget-service-island-ul li:last-child {
    border-bottom: 0px;
  }

  .widget-service-island-ul li small {
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 6px;
    overflow: hidden;
  }

  .widget-service-island-ul li small img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .island-sr-right h3 {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .island-sr-right p {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #737373;
  }

  .island-sr-right span {
    font-size: 13px;
    font-weight: 700;
    color: #ff3201;
  }

  .island-sr-right i {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 11px;
  }

  .island-header-bar {
    display: flex;
    justify-content: space-between;
    // padding: 0px 14px 0px 0px;
  }

  .island-header-bar h3 {
    font-size: 13px;
    color: #4a5174;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .island-header-bar span {
    color: #3a63b8;
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
  }

  /* width */
  .island-scroller-box::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .island-scroller-box::-webkit-scrollbar-track {
    background: #f3f4ff;
  }

  /* Handle */
  .island-scroller-box::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .island-scroller-box::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .island-fx-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .workshop-list-block-crt {
    border: 1px solid #cedfeb;
    background: #f4fbff;
    border-radius: 10px;
    padding: 7px 10px;
  }

  .leftwrksh {
    display: flex;
    align-items: center;
  }

  .wps-wrk-area h3 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .wps-wrk-area label {
    padding: 0px;
    margin: 0px;
  }

  .leftwrksh small img {
    max-width: 75px;
    height: 30px;
    object-fit: contain;
  }

  .workshop-list-block-crt {
    align-items: center;
  }

  .wps-wrk-area label {
    color: #0bbe28;
    font-size: 10px;
    font-weight: 500;
  }

  .wps-wrk-area {
    line-height: 13px;
    margin-left: 10px;
  }

  .rightwrksh {
    font-size: 11px;
    font-weight: 500;
  }

  .rightwrksh label {
    margin-bottom: 0px;
    color: #737373;
  }

  .grey-cart-block {
    background: #555555;
    // background: #3C3C4A;
    margin-top: 5px;
    border-radius: 10px;
    padding: 10px;
  }

  .checkout-btm-tp {
    display: flex;
    justify-content: space-between;
  }

  .ad-val-chk {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .ad-val-chk-height {
    height: 40px;
  }

  .ad-val-chk h4 {
    font-size: 16px;
    font-weight: 700;
    color: white;
    margin: 0px;
  }

  .ad-val-chk span {
    color: #b8b8b8;
    font-size: 10px;
    font-weight: 500;
  }

  .ad-val-chk label {
    color: #fff;
    margin: 0px;
    font-size: 11px;
  }

  .checkout-btm-buttom {
    color: #fff;
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
    padding: 2px 30px;
    border: 0px;
    outline: 0px;
    border-radius: 40px;
    font-weight: 600;
    height: 36px;
    cursor: pointer;
    font-size: 14px;
  }

  .checkout-btm-buttom:hover {
    background: #ff3201;
  }

  .glare-animation {
    position: relative;
    overflow: hidden;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }

    20% {
      left: 100%;
    }

    100% {
      left: 100%;
    }
  }

  .glare-animation:before {
    content: "";
    position: absolute;
    width: 160px;
    height: 100%;
    background-image: linear-gradient(120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    animation: shine 2s infinite linear;
    /* Animation */
  }

  .tby-block-ft-bar {
    display: flex;
    align-items: center;
    margin-bottom: -3px;
  }

  .tby-block-ft-bar p {
    font-size: 10px;
    color: #a4a4a4;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .tby-block-ft-bar small img {
    width: 40px;
  }

  .tby-block-ft-bar small {
    padding-right: 3px;
  }

  .tby-block-ft-bar strong {
    font-weight: 600;
  }

  .workshop-block-island-st-1 {
    display: flex;
    justify-content: space-between;
    padding: 4px 10px 10px 10px;
  }

  .workshop-block-island-st-1 h2 {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .map-view {
    border: 0px;
    border: 1px solid #3a63b8;
    padding: 2px 10px;
    color: #3a63b8;
    font-weight: 500;
    font-size: 11px;
    background: #f2f6ff;
    height: 23px;
    border-radius: 4px;
    position: relative;
  }

  .workshop-block-island-st-1 h2 {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0px;
  }

  .workshop-block-island-st-2 {
    background: #e6e7e9;
    padding: 4px 10px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .map-view-location .css-1fdsijx-ValueContainer{
  //   padding: 0 0 10px 5px
  // }
  // .map-view-location .css-1hb7zxy-IndicatorsContainer{
  //   padding: 0 0 10px 5px
  // }
  .workshop-block-island-st-lc {
    display: flex;
    flex: 1;
    font-size: 12px;
  }

  .workshop-block-island-st-lc strong {
    font-weight: 600;
    color: #2b71c4;
    padding-right: 4px;
  }

  .workshop-block-island-st-lc span {
    padding: 4px 10px;
    font-weight: 500;
    cursor: pointer;
  }

  .active-loc-ws {
    background: #fff;
    border-radius: 3px;
    font-weight: 700;
  }

  .workshop-block-island-st-nrst select {
    background: transparent;
    border: 0px;
    font-size: 12px;
    color: #000;
    font-weight: 600;
  }

  .workshop-block-island-st-nrst span img {
    width: 13px;
    margin-right: -3px;
  }

  .island-scroller-box-s2 {
    padding: 17px;
    max-height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: scroll;
  }

  .workshop-block-island-ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .drp-list-amn {
    background: #fff;
    border: 1px solid #dfdfdf;
    padding: 4px 10px;
    position: absolute;
    min-width: 140px;
    right: 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 7px 0px #ccc;
  }

  .workshop-block-island-ul>li {
    border: 1px solid #cccc;
    padding: 7px 10px 5px 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.75);
  }

  .workshop-block-island-list-t1 {
    display: flex;
    justify-content: space-between;
  }

  .workshop-block-islandlf {
    display: flex;
  }

  .garage-ws-lft h3 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .garage-ws-lft label {
    display: flex;
    margin-bottom: 3px;
    padding-top: 3px;
  }

  .garage-ws-lft span {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    display: flex;
  }

  .garage-ws-lft strong {
    color: #06990c;
    font-weight: 700;
  }

  .garage-ws-lft label span {
    color: #0451be;
    font-size: 12px;
    font-weight: 600;
    padding-right: 4px;
  }

  .garage-ws-lft label i {
    font-size: 11px;
    color: #999999;
    font-style: normal;
    font-weight: 500;
  }

  .workshop-block-islandlf small {
    margin-right: 10px;
  }

  .amenities-list-sdbar ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .amenities-list-sdbar ul li img {
    width: 21px;
    margin-right: 5px;
    margin-top: -2px;
    margin-bottom: 3px;
  }

  .amenities-list-sdbar ul li {
    font-size: 12px;
    font-weight: 500;
    color: #4385f5;
    padding-right: 12px;
    position: relative;
  }

  .amenities-list-sdbar {
    padding: 10px 0px;
    margin-top: 5px;
  }

  .more-item-amenties {
    background: #d9e7ff;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    padding-right: 0px !important;
  }

  .tyre-fet-island ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: space-around;
    color: #c86705;
    font-weight: 500;
  }

  .tyre-fet-island {
    border-bottom: 1px solid #ddd;
    padding: 6px 0px;
  }

  .amenities-list-sdbar {
    border-bottom: 1px solid #ddd;
  }

  .front-stars,
  .back-stars,
  .star-rating {
    display: flex;
    gap: 2px;
  }

  .star-rating {
    font-size: 11px;
  }

  .back-stars {
    color: #a7a7a7;
    position: relative;
  }

  .front-stars {
    color: #ffce50;
    overflow: hidden;
    position: absolute;

    top: 0;
    transition: all 0.5s;
  }

  .rating-wrs-right {
    display: flex;
    align-items: flex-start;
    padding-top: 1px;
  }

  .rating-wrs-right span {
    font-size: 12px;
    font-weight: 500;
    margin-top: -2px;
  }

  .amenities-list-sdbar ul li span i {
    font-style: normal;
  }

  .action-bard-island-wrk {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;

  }

  .action-bard-island-wrk label {
    margin-bottom: 0px;
  }

  .action-bard-island-wrk label img {
    width: 32px;
  }

  .action-bard-island-wrk label span {
    font-size: 12px;
    font-weight: 500;
  }

  .cmn-label {
    font-weight: 500;
    font-size: 10px;
    background: #577787;
    padding: 3px 4px;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: -1px;
  }

  .mor-am-list span {
    display: block;
    padding: 5px 10px;
    font-size: 12px;
    color: #4385f5;
    font-weight: 500;
  }

  .cst-skmenu {
    box-shadow: 0px 2px 5px 0px #ccc;
  }

  .sm-layerasr {
    position: absolute;
    display: flex;
    right: 96px;
    top: -6px;
  }

  .sm-layerasr small {
    background: #defdde;
    color: #2e922c;
  }

  .sm-layerasr small img {
    width: 15px;
  }

  .sm-layerasr small span {
    font-size: 11px;
    color: #27952e;
  }

  .sm-layerasr small {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    height: 21px;
    padding: 7px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.5s, -webkit-transform 0.5s, -moz-transform 0.5s;
  }

  .workshop-block-island-list-t1 {
    position: relative;
    padding: 1px 0px;
  }

  .skip-wrp {
    text-align: center;
    padding-bottom: 10px;
  }

  .skip-wrp .checkout-btm-buttom {
    box-shadow: 0px 0px 4px 0px #f7a04c7a;
  }

  .island-scroller-box-s3 {
    padding: 10px;
    max-height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: scroll;
  }

  .widget-addon-block {
    border: 1px solid #ccc;
    margin: 0px 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .widget-addon-ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .widget-addon-ul li {
    display: flex;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    min-height: 85px;
  }

  .widget-addon-ul li:last-child {
    border-bottom: 0px;
  }

  .widget-addon-ul li small {
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 6px;
    overflow: hidden;
  }

  .island-sr-right span {
    font-size: 13px;
    font-weight: 700;
    color: #ff3201;
  }

  .island-addons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .red-val {
    color: #ff3201;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    padding: 10px;
  }

  .green-val {
    color: rgb(40, 167, 69);
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    padding: 10px;
  }

  .cart-bottom-box {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
  }

  .island-addonsr-right {
    padding-left: 65px;
  }

  .wth-addonsr-right {
    width: calc(100% - 192px);
  }

  .wth-addons-right {
    width: 39%;
    text-align: right;
  }

  .island-addonsr-right h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .island-addonsr-right p {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #737373;
  }

  .offr-labels-island img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .offr-labels-island {
    font-size: 11px;
    font-weight: 500;
    color: #f06212;
    align-items: center;
    cursor: pointer;
  }

  .island-offer-control {
    background: #ebf0f5;
    border: 1px dashed #000;
    padding: 5px 8px;
    margin-top: 5px;
    border-radius: 10px;
    padding-bottom: 0px;
  }

  .island-offer-tp {
    display: flex;
    justify-content: space-between;
  }

  .island-offer-tp .island-offertp-lf small {
    position: absolute;
  }

  .island-offer-tp .island-offertp-lf small img {
    width: 50px;
  }

  .island-offer-tp .island-offertp-lf label {
    padding-left: 58px;
    padding-top: 3px;
    margin: 0px;
  }

  .island-offertp-lf label h3 {
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-weight: 700;
  }

  .island-offertp-lf label strong {
    font-size: 12px;
  }

  .island-offertp-lf {
    display: flex;
    height: 50px;
    align-items: center;
  }

  .island-offertp-rght {
    display: flex;
    flex-direction: column;
  }

  .island-offertp-rght button {
    background: #000;
    border: 0px;
    color: #ffff;
    padding: 4px 18px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
  }

  .island-offertp-rght span {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 4px;
    cursor: pointer;
  }

  .island-offer-bt {
    border-top: 1px solid #ccc;
  }

  .island-offer-bt ul {
    font-size: 11px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
  }

  .island-offer-bt ul li {
    display: inline-block;
    padding: 2px;
  }

  .island-offer-bt {
    margin-top: 3px;
  }

  .other-garage-service {
    min-height: auto !important;
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
  }

  .other-garage-service button {
    text-align: center;
    margin: 0px auto;
  }

  .mr-service-blk-ttl {}

  .othr-grg-serv ul {}

  .othr-grg-serv {
    margin: 0px;
    padding: 0px;
  }

  .othr-grg-serv li {
    display: flex;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .othr-grg-serv li div h3 {
    font-size: 14px;
    color: #525252;
    padding: 0px;
    margin-bottom: 0px;
  }

  .othr-grg-serv li .othr-grg-serv .action-bar {}

  .modal-lg-cs-gr {
    max-width: 600px;
  }

  .action-bar span {
    font-size: 13px;
    padding: 0px 10px;
  }

  .othr-grg-serv li .red-val {
    padding-left: 0px;
  }

  .othr-grg-serv li {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 15px;
  }

  .othr-grg-serv li:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
  }

  .widget-sladdon-island {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
  }

  .widget-sladdon-island ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
  }

  .widget-sladdon-island ul li {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 7px;
  }

  .widget-sladdon-island ul li small img {
    width: 50px;
  }

  .island-addonse {
    padding-left: 8px;
  }

  .island-addonsr-righte h3 {
    font-size: 14px;
    font-weight: 700;
    margin: 0px;
  }

  .island-addonsr-righte .red-val {
    padding: 0px;
  }

  //======== css /
  .island-cs-tabs-sv {
    height: 36px;
    display: flex;
    width: 100%;
    background: #f8fbfd;
    border: 1px solid #cbdbe2;
    position: relative;
    border-radius: 3px;
    list-style: none;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    isolation: isolate;
  }

  .island-cs-tabs-sv li {
    width: 33.33%;
    text-align: center;
    border-right: 1px solid #cbdbe2;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
  }

  .island-cs-tabs-sv:after {
    content: "";
    display: block;
    width: 33.33%;
    height: 100%;
    position: absolute;
    left: var(--left, 0%);
    top: 0;
    background-color: #fff;

    z-index: -1;
    transition: left 0.2s ease;
  }

  .island-cs-tabs-sv li:last-child {
    border-right: 0px;
  }

  .island-cs-tabs-sv:before {
    background: url(../img/island/drop-down.svg);
    content: "";
    display: block;
    width: 28px;
    height: 17px;
    position: absolute;
    left: var(--left, 14%);
    bottom: -10px;
    /* background-color: #fff; */
    z-index: 1000;
    transition: left 0.2s ease;
  }

  .island-cs-tabs-sv label {
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    display: block;
    line-height: 33px;
    cursor: pointer;
  }

  .island-cs-tabs-sv label i {
    width: 19px;
    height: 19px;
    border-radius: 20px;
    line-height: 19px;
    font-style: normal;
    background: #6c7676;
    display: inline-block;
    color: #fff;
    font-size: 11px;
  }

  .island-cs-tabs-sv label span {
    padding-left: 5px;
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }

  .hide-scroll {
    scrollbar-width: none;
    /* Firefox */
  }

  .hide-scroll {
    max-height: 100%;
  }

  #checkedfourlable {
    color: #ff3201;
  }

  #checkedfouricon {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs:has(#checkedfour)+label i {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs-sv:checked+label i {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs-sv:checked+label i {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs-sv:checked+label i {
    background: linear-gradient(0deg,
        rgba(247, 146, 34, 1) 0%,
        rgba(255, 50, 1, 1) 100%);
  }

  .island-cs-tabs-sv:has(#checkedone):after {
    --left: 0%;
  }

  .island-cs-tabs-sv :has(#checkedtwo):after {
    --left: 14% !important;
  }

  .island-cs-tabs-sv:has(#checkedthree):after {
    --left: 14%;
  }

  .island-cs-tabs-sv:has(#checkedfour):after {
    --left: 66%;
  }

  .island-cs-tabs-sv:has(#checkedone):before {
    --left: 14%;
  }

  .island-cs-tabs-sv:has(#checkedtwo):before {
    --left: 14%;
  }

  .island-cs-tabs-sv:has(#checkedthree):before {
    --left: 48%;
  }

  .island-cs-tabs-sv:has(#checkedfour):before {
    --left: 81%;
  }

  .island-cs-tabs-sv input[type="radio"] {
    display: none;
  }

  .grey-cart-block {
    width: 100% !important;

    position: relative;
  }

  .glare-animation {
    position: absolute !important;
    right: 12px;
  }

  .block-islandlf-lgo img {
    width: 50px;
  }

  .island-scroller-box-s1 {
    max-height: 100vh !important;
  }

  .island-scroller-box-s2 {
    max-height: 100vh !important;
  }

  .workshop-scroll-v2 {
    max-height: calc(100vh - 245px) !important;
  }

  .workshop-scroll-v2::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  /* Track */
  .workshop-scroll-v2::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 6px;
  }

  /* Handle */
  .workshop-scroll-v2::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 6px;
  }

  /* Handle on hover */
  .workshop-scroll-v2::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
    border-radius: 6px;
  }

  .workshop-scroll-v2 .workshop-block-island-ul {
    margin-bottom: 66px !important;
  }

  .island-scroller-box-s3 {
    max-height: 85% !important;
  }

  .island-fx-bottom {
    position: relative !important;
  }

  .island-offer-control {
    width: 100% !important;
  }

  .island-fx-bottom {}

  .cart-bottom-in {
    width: 40%;
    position: relative;
    padding: 5px 10px;
  }

  .add-sub-list {
    font-size: 13px;
    background: rgb(0, 0, 0);
    padding: 4px 15px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: none;
  }

  .lgbtn-sub {
    font-size: 12px;
    padding: 3px 5px 0px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-top: -8px;
    display: block;
    text-align: center;
  }

  .island-offer-tp {
    align-items: center;
  }

  @-webkit-keyframes sploosh {
    0% {
      box-shadow: 0 0 0 0px rgb(0, 230, 96);
      background: rgba(0, 230, 96, 0.7);
    }

    80% {
      background: rgba(66, 166, 223, 0);
    }

    100% {
      box-shadow: 0 0 0 10px rgba(66, 166, 223, 0);
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }

    3.3% {
      -webkit-transform: scale(1.1);
    }

    16.5% {
      -webkit-transform: scale(1);
    }

    33% {
      -webkit-transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
    }
  }

  .relative-btn {
    position: absolute;
    margin: 0px auto;
    width: 10px;
    height: 10px;
    left: -5px;
    top: -5px;
    display: flex;
  }

  .span-layer {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 10px;

    -webkit-animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-animation-iteration-count: infinite;
  }

  .span-layer:nth-child(2) {
    -webkit-animation-delay: 0.33s;
    -webkit-animation-duration: 2.2s;
  }

  .btn-dot {
    border: 0;
    margin: 0px auto;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    display: flex;
    background-color: rgba(0, 230, 96, 1);
    padding: 2px;
    -webkit-animation: pulse 2s ease-out;
    -webkit-animation-iteration-count: infinite;
  }

  @media screen and (max-width: 768px) {
    .other-garage-service {
      bottom: 140px;
    }



    .sidebar-crt-skin {
      width: 100%;
      box-shadow: 0px 0px 0px 0px #fff;
    }

    .cart-bottom-in {
      width: 100%;
    }

    .amenities-list-sdbar {
      padding: 8px 0px;
      margin-top: 0px;
    }

    .checkout-btm-buttom {
      font-size: 12px;
      padding: 2px 20px;
    }

    .island-offertp-lf label h3 {
      font-size: 11px;
    }

    .island-offertp-lf label strong {
      font-size: 11px;
    }

    .island-cs-tabs label i {
      width: 16px;
      height: 16px;
      font-size: 10px;
      line-height: 16px;
    }

    .island-cs-tabs label span {
      font-size: 10px;
    }
  }
}

// view more service modal

.othr-grg-serv li div h3 {
  font-size: 14px;
  color: #525252;
  padding: 0px;
  margin-bottom: 0px;
}

.red-val {
  color: #ff3201;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.red-val label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.othr-grg-serv li {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 15px;
}

.othr-grg-serv li {
  display: flex;
  padding: 5px;
  width: 100%;
  justify-content: space-between;
}

.othr-grg-serv label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.action-bar span {
  font-size: 13px;
  padding: 0px 10px;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.modal-body {
  padding: 10px;
}

// .modal-content {
//   width: 75%;
//   margin: auto;
// }
.sm-layerasr small {
  margin-right: 0px !important;
}

.smly-mob {
  position: initial !important;
}

.rating-wrs-mob {
  padding-top: 3px;
  padding-left: 4px;
}

.cst-blck {
  font-weight: 500 !important;
}

.widget-addon-block .srv-act-action {
  width: 100px !important;
}

.smtxt-dladon {
  font-size: 12px;
}

.cst-scroll-sum {
  max-height: calc(100vh - 405px);
  overflow-x: hidden;
}

.cst-scroll-sumadn {
  max-height: calc(100vh - 385px);
  overflow-x: hidden;
}

.addonspace-element {
  padding: 10px 0px;
}

/* width */
.cst-scroll-sum::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

/* Track */
.cst-scroll-sum::-webkit-scrollbar-track {
  background: #f3f4ff;
  border-radius: 6px;
}

/* Handle */
.cst-scroll-sum::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 6px;
}

/* Handle on hover */
.cst-scroll-sum::-webkit-scrollbar-thumb:hover {
  background: #6d6d6d;
  border-radius: 6px;
}

.rightpr-label-block {
  display: flex;
}

.prc-label-wps {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-right: 3px;
}

.offr-labels-island {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.offr-labels-island.offer_island {
  position: absolute;
  left: 62px !important;
  top: 43px !important;
  width: 200px !important;
}

.higher-price-label {
  font-size: 11px !important;
  justify-content: flex-end;
  text-align: start;
  font-weight: 600 !important;
}

@media screen and (max-width: 480px) {
  ._cart_island--op .other-garage-service {
    margin-bottom: 10px !important;
  }

  ._cart_island--op .sm-layerasr {
    top: -3px !important;
    right: 0px !important;
  }

  .higher-price-label {
    font-size: 10px;
    justify-content: flex-end;
    text-align: start;
    font-weight: 600;
  }

  .action-bard-island-wrk label img {
    position: absolute;
    left: 18px;
  }

  .action-bard-island-wrk label span {
    padding-left: 0px;
    display: block;
  }

  .offr-labels-island {
    position: initial;
  }

  .prc-label-wps {
    padding-top: 0px;
    display: initial;
    margin-top: 7px;
  }

  .workshop-price-difference-low-del {
    justify-content: flex-end;
    font-size: 11px !important;
  }

  .workshop-price-difference-low {
    justify-content: flex-end;
    font-size: 11px;
  }

  .workshop-block-island-st-1 {
    padding: 4px 10px 2px 10px !important;
  }

  .cst-scroll-sum {
    max-height: calc(100vh - 400px);
  }

  .widget-service-island-ul li {
    padding: 10px 5px !important;
  }

  .island-addonsr-right {
    padding-left: 54px !important;
  }

  .garage-ws-lft h3 {
    font-size: 12px;
  }

  .workshop-scroll-v2 .workshop-block-island-ul {
    margin-bottom: 114px !important;
  }

  .cart-bottom-in {
    padding: 4px 5px !important;
  }

  .workshop-block-islandlf {
    align-items: center;
  }

  .action-bard-island-wrk label span {
    font-size: 10px !important;
  }

  .sm-layerasr small span {
    font-size: 10px !important;
  }

  .sm-layerasr small {
    margin-right: 0px;
  }

  .island-addonsr-right h3 {
    font-size: 14px !important;
  }

  .widget-addon-ul li small {
    width: 42px !important;
    height: 51px;
  }

  .smtxt-dladon {
    font-size: 12px !important;
  }

  .action-bar span {
    display: block;
    padding: 0px 8px !important;
  }

  .rightwrksh label {
    width: 108px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wth-addonsr-right {
    width: 100% !important;
  }

  .amenities-list-sdbar ul li img {
    width: 16px !important;
  }

  .map-view {
    width: 90px;
    padding: 2px 1px !important;
  }

  .amenities-list-sdbar ul li span i {
    font-size: 10px !important;
  }
}

.workshop-map-data {
  .slick-prev {
    left: 6px;
    z-index: 15;
  }

  .slick-next {
    right: 7px;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.75;
  }
}