
/*=============================================
=            login register            =
=============================================*/

.login-wrap {
  padding: 40px;

  box-shadow: 0 0 10px rgba($black, 0.2);

@include respond(extra-small-mobile) {
    padding: 20px;
  }

  form {
    .form-control {
      height: 50px;
    }
  }

  .form-note {
    color: $black--three;
  }
}

.login-register-wrap {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.login-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  .check-form {
    input[type="checkbox"] {
      display: none;
      &:checked {
        & + .form-check-label {
          &:after {
            opacity: 1;
          }
          &:before {
            border-color: $theme-color--default;
            background-color: $theme-color--default;
          }
        }
      }
    }
    .form-check-label {
      position: relative;

      cursor: pointer;

      color: $black--three;

@include respond(extra-small-mobile) {
        font-size: 14px;
      }

      &:before {
        display: inline-block;

        width: 17px;
        height: 17px;
        margin: 0 8px 0 0;

        content: "";
        vertical-align: middle;

        border: 2px solid $grey--ten;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 3px;

        width: 11px;
        height: 6px;

        content: "";
        transform: translateY(-65%) rotate(-45deg);

        opacity: 0;
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
      }
    }
  }

  a {
    color: $black--three;

@include respond(extra-small-mobile) {
      font-size: 14px;
    }
  }
}

.different-login {
  position: relative;

  margin: 20px 0;

  text-align: center;

  span {
    position: relative;

    padding: 0 15px;

    text-transform: uppercase;

    background-color: $white;
  }

  &:before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    content: "";
    transform: translateY(-50%);

    border-top: 1px solid $grey--two;
  }
}

.btn-login {
  li {
    display: inline-block;

    margin: 0 5px 0;
  }
  a {
    display: block;

    padding: 10px 20px;

    color: $white;
    border-radius: 5px;

@include respond(extra-small-mobile) {
      padding: 5px 10px;
    }


    svg {
      font-size: 18px;

      margin-right: 10px;
      margin-left: 0;
    }
  }
}

.btn-facebook {
  border-color: $blue--two;
  background: $blue--two;
  &:hover {
    color: $white;
    border-color: $blue--three;
    background: $blue--three;
  }
}

.btn-google {
  border-color: $red--four;
  background: $red--four;
  &:hover {
    color: $white;
    border-color: $red--five;
    background: $red--five;
  }
}


/*=====  End of login register  ======*/

