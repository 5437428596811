.comparison-body {
  padding: 0px;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
}

.comparison-table th {
  border: 1px solid #ccc;
}

.comparison-table tbody tr td {
  border: 1px solid #ccc;
}

.comparison-table tfoot tr td {
  border: 1px solid #ccc;
}

.comparison-table tbody tr td {
  padding: 10px;
  font-size: 13px;
  text-align: center;
}

.comparison-table tbody tr td:nth-child(1) {
  font-weight: 600;
  text-align: left;
}

.comparison-table tfoot td {
  padding: 10px;
  text-align: center;
}

.comparison-table .price-pl-rght {
  justify-content: center;
  padding: 10px;
}

.tyree-com-title {
  font-size: 25px;
  padding: 25px;
}

.tyree-com-title span {
  display: block;
}

.tyre-comp-lst {
  padding: 30px 20px 25px;
}

.tyre-comp-lst h3 {
  font-size: 18px;
  color: #3a63b8;
  font-weight: 600;
  margin-bottom: 10px;
}

.pr-tb-lis-cmp {
  display: flex;
}

.pr-tb-lis-cmp .lis-cmp-img img {
  width: 100%;
  object-fit: cover;
}

.pr-tb-lis-cmp .lis-cmp-img {
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  padding: 4px;
}

.price-comp strong {
  font-size: 13px;
  font-weight: 600;
  color: #656565;
}

.price-comp {
  padding-left: 10px;
}

.price-comp h4 {
  font-size: 15px;
  font-weight: 700;
}

.price-comp p {
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  color: #566b7e;
}

.applicable-comp i {
  color: #898989;
}

.close-action-btn-comparison {
  position: absolute;
  right: 13px;
  top: 8px;
  font-size: 41px;
}

@media (min-width: 992px) {
  .modal-lgcs {
    max-width: 1000px;
  }
}

@media (max-width: 768px) {
  .scroll-table-cop {
    width: 100%;
    overflow: scroll;
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0px;
  }

  .tyree-com-title {
    font-size: 13px;
    padding: 25px;
  }

  .comparison-table th:nth-child(1) {
    position: sticky;
    left: 0;
    background: #f2f9ff;
    z-index: 2;
  }

  .comparison-table th:nth-child(1):after,
  .comparison-table tbody tr td:nth-child(1):after,
  .comparison-table tfoot td:nth-child(1):after {
    width: 20px;
    box-shadow: 4px 0px 3px 0px #cccccc59;
    content: "";
    height: 100%;
    right: -1px;
    /* background: red; */
    position: absolute;
    top: 0px;
  }

  .comparison-table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    background: #f2f9ff;
    z-index: 1;
  }

  .comparison-table tfoot td:nth-child(1) {
    position: sticky;
    left: 0;
    background: #f2f9ff;
    z-index: 1;
  }

  .comparison-table tbody tr td {
    font-size: 11px;
  }

  .price-comp strong {
    font-size: 12px;
  }

  .comparison-table .price-pl-rght {
    width: 170px;
  }

  .close-action-btn-comparison {
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 41px;
    z-index: 10000;
    background: #fff;
  }

  .price-comp h4 {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .price-comp p {
    font-size: 12px;
  }

  .comparison-table th:nth-child(1) {
    background: #f2f9ff;
  }

  .comparison-view.modal {
    bottom: 0;
    top: initial;
  }

  .animate-bottom {
    position: relative;
    animation: animatebottom 0.4s;
  }

  @keyframes animatebottom {
    from {
      bottom: -300px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
}

.tyre-shadow-box {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.35);
  padding: 4px 10px 10px;
  border-radius: 7px;
  margin: 0 8px;
}

@media (max-width: 768px) {
  .tyre-shadow-box input::placeholder {
    color: #5a5a5a;
  }
}

.tyre-manufacturing-year {
  border: 1.5px solid #ff3201;
  border-radius: 50%;
  padding: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff3201;
}
