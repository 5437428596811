
/*=============================================
=            brand logo            =
=============================================*/

.cl-logo {
  img {
    transition: all 0.5s ease-in-out;

    opacity: 0.7;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

/*=====  End of brand logo  ======*/

