.about-us-openbonnet {
  .about-first {
    background: #f0f6fd;
    padding: 40px 0px;
  }

  .about-page-scroll {
    display: flex;
    flex-wrap: wrap;
  }

  .about-first-img img {
    width: 100%;
  }

  .center-align {
    align-items: center;
  }

  .abt-title {
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
  }

  .about-page-bullet {
    font-size: 16px;

    font-weight: bold
  }

  .about-frst-cont p {
    line-height: 35px;
    color: #585757;
  }

  .stry-abt-section {
    padding: 60px 0px;
  }

  .heading-about {
    padding: 20px 0px 60px;
  }

  .heading-about {
    text-align: center;
  }

  .ab-timeline {
    position: relative;
  }

  .ab-timeline-item:nth-child(odd) {
    text-align: right;
  }

  .ab-timeline-item {
    width: 100%;
    position: relative;
  }

  .about-mail {
    cursor: pointer;
  }

  .about-mail a {
    color: #F47721
  }

  .ab-timeline-img {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 5px #030303 solid;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 45px;
    margin: auto;
  }

  .ab-timeline-content p {
    margin-bottom: 0px;
    font-size: 15px;
    padding-bottom: 6px;
    color: #696969;
  }

  .ab-timeline-item .ab-timeline-content {
    position: relative;
    width: 45%;
  }

  .ab-timeline-item h4,
  .ab-timeline-item .h4 {
    color: #F47721;
    font-weight: 700;
  }

  .ab-timeline-item h3,
  .ab-timeline-item .h3 {
    color: #25282d;
    font-weight: 800;
    font-size: 20px;
  }

  .ab-timeline-item:nth-child(even) {
    text-align: left;
  }

  .ab-timeline-item::after {
    content: "";
    display: block;
    clear: both;
  }

  .ab-timeline-item:nth-child(even) .ab-timeline-content {
    float: right;
  }

  .ab-timeline::before {
    content: "";
    background: #F47721;
    width: 5px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
  }

  .elementor-icon-abt-vsn {
    text-align: center;
    color: #fff;
  }

  .vson-block {
    background: linear-gradient(180deg, #0c1892 0%, #0451be 100%);
    padding: 60px 0px;
  }

  .elementor-icon-abt-vsn span {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .elementor-icon-abt-vsn h3 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .elementor-icon-abt-vsn p {
    font-size: 14px;
  }

  .offer-about-setion {
    padding: 60px 0px;
  }

  .abt-offer-element {
    padding-bottom: 15px;
  }

  .abt-offer-element h3 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 5px;
  }

  .abt-offer-element span {
    display: inline-flex;
    padding-bottom: 20px;
  }

  .abt-offer-element p {
    font-size: 14px;
    color: #57617c;
  }

  .review-section {
    padding: 60px 0px;
  }

  .purple {
    color: #8a3c90;
    text-decoration: underline;
  }

  .rating-blk .purple {
    padding-left: 2px;
    margin-top: -2px;
    display: inline-block;
  }

  .google-icon {
    position: absolute;
    left: -10px;
  }

  .google-head {
    position: relative;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    transition: all 0.75s ease;
    padding: 45px 20px;
  }

  .google-card {
    height: auto;
    width: 100%;
    border-radius: 30px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;

    background: #fff;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0/10%);
    box-shadow: 0 0 10px rgb(0 0 0/10%);
    padding: 10px;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    transition: all 0.75s ease;
    display: -moz-box;
    display: flex;
    -moz-box-pack: start;
    justify-content: flex-start;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    cursor: pointer;
  }

  .google-container,
  .google-image-container {
    width: 100%;
    border-radius: 30px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
  }

  .google-container {
    height: auto;
    padding-top: 10px;
  }

  .google-text {
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-right: 20px;
    text-align: left;
    min-height: 150px;
  }

  .displayed-text {
    font-size: 14px;
  }

  .profile {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
  }

  .pro-image {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
  }

  .pro-image img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .pro-info {
    font-weight: 700 !important;
    color: rgb(0, 0, 0);
  }

  .pro-info-span {
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-size: 13px;
  }

  .google-card:hover {
    transform: translateY(-20px);
  }

  .pro-info {
    min-height: auto;
  }

  .review-section .slick-prev:before,
  .review-section .slick-next:before {
    color: #ccc;
  }

  .header-all {
    font-size: 32px;
    font-weight: 600;
    color: #000;
  }

  .star-skn-block {
    display: inline-block;
  }

  .stars {
    position: relative;
    white-space: nowrap;
  }

  .stars svg {
    width: 18px;
  }

  .cover {
    background: white;
    height: 100%;
    overflow: hidden;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    right: 0;
  }

  svg {
    fill: #ffba07;
  }

  .review-section {
    padding: 60px 0px;
  }

  .offer-value-block {
    background: url(/assets/about/value-bg.webp);
    padding: 60px 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .values-list {
    color: #fff;
    margin: 0px auto;
  }

  .values-list ul {
    list-style: none;
    margin: 0px;
    width: 100%;
    padding: 0px;
  }

  .values-list ul li {
    padding: 10px;
  }

  .values-list ul li {
    display: flex;
  }

  .values-list ul li:nth-child(1) {}

  .values-list ul li:nth-child(2) {
    padding-left: 8vw;
  }

  .values-list ul li:nth-child(3) {
    padding-left: 16vw;
  }

  .values-list ul li:nth-child(4) {
    padding-left: 24vw;
  }

  .values-list ul li:nth-child(5) {
    padding-left: 32vw;
  }

  .values-list ul li:nth-child(6) {
    padding-left: 40vw;
  }

  .values-list ul li:nth-child(7) {
    padding-left: 48vw;
  }

  .values-list ul li:nth-child(8) {
    padding-left: 56vw;
  }

  .values-list ul li:nth-child(9) {
    padding-left: 64vw;
  }

  .values-list ul li:nth-child(10) {
    padding-left: 72vw;
  }

  .listval-block {
    display: flex;
    align-items: center;
  }

  .listval-block span {
    padding-left: 10px;
  }

  .listval-block label {
    background: #fff;
    width: 48px;
    height: 40px;
    color: #000;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-radius: 9px;
    line-height: 39px;
    margin-bottom: 0px;
  }

  .about-our-partners {
    background: #f0f6fd;
    padding: 60px 0px;
  }

  .partners-section {
    text-align: center;
  }

  .partners-section .slick-arrow {
    display: none !important;
  }

  .about-awwards-section {
    padding: 60px 0px;
  }

  .border-heading .abt-title strong {
    padding: 2px 30px;
    background: #fff;
  }

  .border-heading span {
    width: 100%;
    height: 1px;
    background: #ccc;
    display: block;
    margin-top: -28px;
  }

  .ach-blc {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 15px;
    padding-bottom: 50px;
  }

  .fnd-blc {
    padding-top: 30px;
  }

  .about-contact-block {
    background: url(/assets/about/cnt-banner.webp);
    position: relative;
    padding: 60px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
  }

  .about-elemet-section {
    position: absolute;
    right: 100px;
    margin-top: 80px;
  }

  .about-elemet-section h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .about-elemet-section h4 {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 14px;
    font-size: 30px;
  }

  .about-elemet-section p {
    background: #fff;
    color: #ff3201;
    padding: 10px 20px;
    text-align: center;
    font-size: 28px;
  }

  .mob-lst {
    display: none;
  }

  @media screen and (max-width: 768px) {

    .abt-offer-element h3 {
      font-size: 14px;

    }

    .abt-offer-element p {
      font-size: 12px;

    }

    .about-page-offer p {
      font-size: 10px;
    }

    .mob-lst {
      display: block;
    }

    .dsk-lst {
      display: none;
    }

    .abt-offer-element {
      padding-bottom: 0px;
    }

    .border-heading .abt-title strong {
      padding: 2px 10px;
    }

    .about-us-openbonnet .border-heading .abt-title strong {
      display: inline-block;
    }

    .about-page-offer {
      display: flex;
      align-items: center;
      margin-top: 10px;

    }

    .about-page-offer h3 {
      margin-left: 10px;
      margin-bottom: 18px;
    }

    .about-page-offer img {
      height: 40px;
    }

    .about-page-scroll::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .about-page-scroll::-webkit-scrollbar-track {
      background: #f3f4ff;
    }

    /* Handle */
    .about-page-scroll::-webkit-scrollbar-thumb {
      background: #0461b4;
    }

    /* Handle on hover */
    .about-page-scroll::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .about-page-scroll {

      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      height: 450px;
      margin-left: 10px;
      margin-right: 10px;
      border: 1px solid #DFE1E5;
      box-shadow: 0px 0px 14.899999618530273px -3px #5482FF40;
      border-radius: 10px;
    }

    .about-contact-block {
      height: 318px;
    }

    .about-elemet-section {
      margin-top: 19px;
    }

    .about-frst-cont {
      padding-bottom: 30px;
    }

    .abt-title {
      font-size: 24px;
    }

    .about-awwards-section {
      padding: 30px 0px;
    }

    .ach-blc {
      padding-top: 15px;
      padding-bottom: 20px;
    }

    .about-elemet-section {
      position: initial;
      padding: 15px;
    }

    .about-elemet-section h3 {
      font-size: 19px;
    }

    .about-elemet-section h4 {
      letter-spacing: 6px;
      font-size: 26px;
    }

    .about-elemet-section p {
      padding: 5px 20px;
      text-align: center;
      font-size: 20px;
    }

    .ab-timeline-item h3,
    .ab-timeline-item .h3 {
      font-size: 17px;
    }

    .listval-block label {
      width: 43px;
      height: 37px;
      font-size: 26px;
    }

    .ach-blc img {
      width: 100%;
    }

    .parter-logo img {
      width: 100%;
    }

    .values-list ul li {
      padding: 10px 0px;
    }

    .values-list {
      margin: 10px;
      padding-left: 0px;
    }

    .values-list ul {
      margin: 0px;
      padding: 0px;
    }

    .values-list ul li:nth-child(1) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(2) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(3) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(4) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(5) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(6) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(7) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(8) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(9) {
      padding-left: 2vw;
    }

    .values-list ul li:nth-child(10) {
      padding-left: 2vw;
    }

    .ab-timeline-item:nth-child(odd) {
      text-align: left;
    }

    .ab-timeline {
      position: relative;
    }

    .ab-timeline::before {
      left: 18px;
    }

    .ab-timeline-img {
      left: 8px;
      right: auto;
      top: 16px;
    }

    .ab-timeline-item .ab-timeline-content {
      max-width: 100%;
      width: auto;
      margin-left: 50px;
      padding-bottom: 20px;
    }

    .vson-block,
    .offer-about-setion,
    .offer-value-block {
      padding: 26px 0px;
    }

    .elementor-icon-abt-vsn h3 {
      font-size: 22px;
    }

    .heading-about {
      padding-bottom: 25px;
    }
  }
}