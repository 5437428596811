// fonts
$roboto: "Roboto";
$poppins: "Poppins", sans-serif;

// color
$black: #000;
$black--two: #292b2c;
$black--three: #687188;
$black--four: #333;
$black--five: #f3f4ff;
$black--six: #222;
$black--seven: #171717;
$black--eight: #2b2f4c;
$black--nine: #16181b;
$black--ten: #fff;
$black--eleven: #252a2c;
$black--twelve: #1b1e20;
$grey: #eee;
$grey--two: #ddd;
$grey--three: #f7f8fb;
$grey--four: #dee2e6;
$grey--five: #f7f7f7;
$grey--six: #d9d9d9;
$grey--seven: #908f8f;
$grey--eight: #e9e9e9;
$grey--nine: #efefef;
$grey--ten: #ced4da;
$grey--eleven: #f4f4f4;
$grey--twelve: #c6c6c6;
$grey--thirteen: #fafafa;
$grey--fourteen: #ced4da;
$grey--fifteen: #bfbfbf;
$grey--sixteen: #848484;
$grey--seventeen: #6c757d;
$grey--eighteen: #f2f2f2;
$white: #fff;
$red: #ff324d;
$red--two: #fff1f1;
$red--three: #ff324d;
$red--four: #d85040;
$red--five: #d33d2b;
$green--one: #388e3c;
$yellow--one: #f6bc3e;
$yellow--two: #ff9f00;
$yellow--three: #fdbb99;
$blue: #f4f9fc;
$blue--two: #3b5998;
$blue--three: #344e86;
$blue--four: #53c0e9;
$theme-color--default: #3b5998;
$theme-color--heading: $black;
$transition--default: all 0.3s ease-in-out;
$transition--two: all 0.5s ease-in-out;