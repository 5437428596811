
/*=============================================
=            grid            =
=============================================*/

.custom-container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .custom-container {
    max-width: 1650px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*=====  End of grid  ======*/

