.thank-you-page-checkout {

  .slick-prev,
  .slick-next {
    display: block !important;
  }

  .dropdown-bottom-thank-you {
    position: absolute;
    transform: translate3d(-2px, 21px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
  }

  .top-banner-thk {
    background: url(/assets/thank-you-bg.jpg) no-repeat;
    height: 350px;
    width: calc(100% - 50px);
    background-size: cover;
    margin: 30px auto 0;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .top-banner-thk img {
    height: 200px;
    margin: 0px auto;
  }

  .headind-area-thk {
    text-align: center;
    padding-top: 30px;
  }

  .headind-area-thk h1 {
    color: #0451be;
    font-size: 45px;
    font-weight: 600;
  }

  .headind-area-thk h5 {
    color: #0451be;
    padding-bottom: 20px;
  }

  .thank-pr-block {
    border: 1px solid #c1d5ea;
    border-radius: 10px;
    padding: 15px;
  }

  .thankt-ord-tp {
    border-radius: 10px;
    background: #deeff9;
    padding: 20px 15px 17px;
  }

  .ls-item-thk {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-bottom: 5px;
  }

  .ls-item-thk:last-child {
    padding-bottom: 0px;
  }

  .ls-item-thk span {
    font-weight: 500;
    color: #555454;
  }

  .ls-item-thk strong {
    font-weight: 600;
    color: #363636;
  }

  .sr-list-items {
    max-width: 350px;
    font-size: 13px;
    color: #555454;
    padding: 10px;
    border: 1px solid rgb(171 191 203 / 15%);
    border-radius: 0.25rem;
    box-shadow: 0px 2px 9px 2px #4048532e;
    border-radius: 10px;
  }

  .sr-list-items .sr-drp-ls {
    padding: 4px;
    border-bottom: 1px solid #ddd;
    margin: 2px 0px;
    font-weight: 500;
  }

  .sr-list-items .sr-drp-ls label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .sr-list-items .sr-drp-ls:last-child {
    border-bottom: 0px;
  }

  .sr-list-items .sr-drp-ls span {
    display: block;
    color: #0451be;
    font-weight: 600;
  }

  .more-items-ls {
    background: #fff;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #7777777d;
    text-align: center;
    margin-top: -3px;
    padding-top: 3px;
    font-weight: 600;
    font-size: 13px;
    position: relative;
  }

  .more-items-ls a:after {
    display: none;
  }

  .btn-tk {
    padding: 4px 10px;
    border-radius: 55px;
    background: #189752;
    color: #fff !important;
    font-size: 13px;
  }

  .rightsr-blk-itn:before {
    content: "";
    height: 95%;
    width: 2px;
    background: #fff;
    /* height: 30px; */
    position: absolute;
    left: 5px;
  }

  .thk-right {
    padding-left: 10px;
  }

  .thk-sr-price {
    color: #0451be !important;
    font-weight: 700 !important;
    font-size: 16px;
  }

  .submit-btn {
    border: 1px solid #0e9594;
    background-color: #0e9594;
  }

  .mt-3 {
    margin-top: 2rem;
  }

  .d-none {
    display: none;
  }

  .form-step {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 3rem;
  }

  .font-normal {
    font-weight: normal;
  }

  ul.form-stepper {
    counter-reset: section;
    margin-bottom: 3rem;
    padding-top: 50px;
  }

  ul.form-stepper .form-stepper-circle {
    position: relative;
  }

  ul.form-stepper .form-stepper-circle span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .form-stepper-horizontal {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  ul.form-stepper>li:not(:last-of-type) {
    margin-bottom: 0.625rem;
    -webkit-transition: margin-bottom 0.4s;
    -o-transition: margin-bottom 0.4s;
    transition: margin-bottom 0.4s;
  }

  .form-stepper-horizontal>li:not(:last-of-type) {
    margin-bottom: 0 !important;
  }

  .form-stepper-horizontal li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .form-stepper-horizontal li:not(:last-child):after {
    position: absolute;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 3px;
    content: "";
    top: 8%;
  }

  .stp-8 li:not(:last-child):after {
    left: 28%;
    background: #e6ecfc;
    width: 69%;
  }

  .stp-4 li:not(:last-child):after {
    left: 14%;
    background: #e6ecfc;
    width: 83%;
  }

  .form-stepper-horizontal li:after {
    background-color: #dee2e6;
  }

  .form-stepper-horizontal li.form-stepper-completed:after {
    background-color: #005c9f;
  }

  .form-stepper-horizontal li:last-child {
    flex: unset;
  }

  ul.form-stepper li a .form-stepper-circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 0;
    line-height: 1.7rem;
    text-align: center;

    border: 4px solid #e6ecfc;
    background: #fff;
    border-radius: 50%;
  }

  .form-stepper .form-stepper-active .form-stepper-circle {
    background-color: #005c9f !important;
    border-color: #005c9f !important;
    color: #fff;
    animation: 1750ms linear infinite pulse;
    z-index: 10;
  }

  .form-stepper .form-stepper-active .label {
    color: #005c9f !important;
    font-weight: 700;
  }

  .form-stepper .form-stepper-active .form-stepper-circle:hover {
    background-color: #005c9f !important;
    color: #fff !important;
  }

  .form-stepper .form-stepper-unfinished .form-stepper-circle {
    background-color: #f8f7ff;
  }

  .form-stepper .form-stepper-completed .form-stepper-circle {
    background-color: #005c9f !important;
    color: #fff;
  }

  .form-stepper .form-stepper-completed .label {
    color: #005c9f !important;
    margin-left: -20px;
    padding-right: 20px;
  }

  .form-stepper .form-stepper-completed .form-stepper-circle:hover {
    background-color: #005c9f !important;
    color: #fff !important;
  }

  .form-stepper .form-stepper-active span.text-muted {
    color: #fff !important;
    margin-left: -20px;
    padding-right: 20px;
  }

  .form-stepper .form-stepper-completed span.text-muted {
    color: #fff !important;
  }

  .form-stepper .label {
    font-size: 13px;
    color: #939393;
    margin-top: 0.5rem;
    margin-left: -20px;
    padding-right: 20px;
  }

  .form-stepper a {
    cursor: default;
    max-width: 120px;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
  }

  .sl-car-active {
    position: absolute;
    top: -48px;
    left: -7px;
  }

  .sl-car-active img {
    width: 62px;
  }

  .stg-head-txt {
    padding: 40px 0px;
  }

  .stg-head-txt h2 {
    color: #0451be;

    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .stp-wizard-elm {
    padding: 10px 30px;
  }

  .pclc-bar {
    display: flex;
    justify-content: space-between;
  }

  .pc-lc-elm {
    border-top: 1px solid #d1e3f4;
    padding-top: 10px;
  }

  .pcl-head h3 {
    color: #000;

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .lf-pclc label {
    font-size: 13px;
    font-weight: 500;
    color: #969191;
  }

  .lf-pclc strong {
    font-weight: 600;
    color: #696969;
  }

  .lf-pclc img {
    margin-right: 3px;
  }

  .lf-pclc i {
    font-style: normal;
    padding: 0px 4px;
    opacity: 0.5;
    display: inline-block;
    font-size: 12px;
  }

  .rghtpclc ul {
    display: flex;
    margin: 0px;
    padding: 0px;
    list-style: none;
    gap: 25px;
  }

  .rghtpclc {
    margin-top: -22px;
  }

  .rghtpclc ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
  }

  .rghtpclc ul li strong {
    font-weight: 500;
  }

  .rghtpclc ul li span {
    height: 28px;
  }

  .my-ord {
    color: #0451be;
    cursor: pointer;
  }

  .svlist {
    color: #189752;
    cursor: pointer;
  }

  .note-element-tp {
    padding: 10px;
    border-top: 1px solid #d1e3f4;
    margin-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .note-element-tp ul {
    padding: 0px;
    padding-left: 18px;
    margin-bottom: 0px;
    list-style: disc !important;
  }

  .note-element-tp li {
    color: #805e46;
    font-size: 13px;
    padding-bottom: 4px;
  }

  .note-element-tp h3 {
    color: #805e46;

    font-size: 14px;

    font-weight: 600;
    line-height: normal;
  }

  .ds-orange-block {
    background: linear-gradient(180deg, #ff3201 0%, #f79222 100%);
    border-radius: 15px;
    padding: 15px;
    margin-top: 35px;
  }

  .ds-ref-tp-block {
    color: #fff;
  }

  .ds-ref-tp-block h3 {
    font-size: 35px;
    font-weight: 600;
  }

  .ds-ref-tp-block h3 label {
    color: #0451be !important;
    margin-bottom: 0px;
  }

  .ds-ref-tp-block p {
    font-size: 20px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: inline-block;
    border-radius: 10px;
  }

  .ds-ref-tp-block {
    padding-top: 10px;
  }

  .ds-ref-code-bl {
    display: flex;
    background: #fff;
    border-radius: 100px;
    justify-content: space-between;
    width: 300px;
    align-items: center;
    padding: 4px;
  }

  .ds-ref-code-bl label input {
    border: 0px;
    width: 200px;
    margin-top: -10px;
    outline: 0px;
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 20px;
  }

  .ds-ref-code-bl label {
    padding-left: 30px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .ds-ref-code-bl p {
    text-align: center;
    padding: 0px 20px 0 0;
    margin: 0px;
    font-size: 12px;
    color: #0f8514;
    border-left: 1px solid;
    padding-left: 9px;
  }

  .cs-gp-shr {
    gap: 10px;
  }

  .ds-friends-image img {
    position: absolute;
    width: 359px;
    right: 0px;
    bottom: -82px;
  }

  .ds-cpy-txt {
    color: #fff;
    font-size: 13px;
    padding-top: 10px;
    margin: 0px;
  }

  .share-action {
    border-radius: 888px;
    cursor: pointer;
    background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
    width: 147px;
  }

  .share-action span {
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .share-action:hover {
    background: #0451be
  }

  .rd-list {
    display: flex;
    gap: 15px;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  .mid-block-frm {
    padding-bottom: 45px;
    border-bottom: 1px solid #ddd;
  }

  .rd-list input[type="radio"] {
    display: none;
  }

  .rd-list label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 13px;
    min-width: 37px;
    height: 37px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #d2d8df;
  }

  .gapft {
    gap: 40px;
  }

  .rd-list input[type="radio"]:checked+label {
    color: #ffffff;
    color: #4189e0;
    border-color: #4189e0;
  }

  .wrd-radiolst {
    display: flex;
    gap: 15px;
    position: relative;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
  }

  .rdlist input[type="checkbox"] {
    display: none;
  }

  .rdlist label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 13px;
    min-width: 37px;
    height: 37px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #d2d8df;

    padding: 5px 7px;
    font-size: 13px;
    border-width: 1px;
    color: #8f8f8f;
    font-weight: 500;
  }

  .gapft {
    gap: 40px;
  }

  .rdlist input[type="checkbox"]:checked+label {
    color: #ffffff;
    color: #4189e0;
    border-color: #4189e0;
  }

  .header-rt-block {
    padding-top: 20px;
  }

  .header-rt-block button {
    position: absolute;
    top: 10px;
    right: 14px;
  }

  .header-rt-block h3 {
    font-size: 13px;
    color: #4d4f57;
    padding-bottom: 14px;
    text-align: center;
  }

  .header-rt-block strong {
    font-weight: 600;
  }

  .header-rt-block h5 {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    padding-bottom: 12px;
  }

  .rt-dl img {
    width: 16px;
  }

  .rt-dl {
    position: absolute;
    display: flex;
    bottom: -19px;
    font-size: 12px;
    justify-content: space-between;
    width: 364px;
  }

  .nt-sat {
    color: #c41818;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 11px;
  }

  .ys-sat {
    color: #26c726;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .wrd-radio-lst {
    flex-wrap: wrap;
    gap: 8px;
  }

  .wrd-radio-lst label {
    padding: 5px 7px;
    font-size: 13px;
    border-width: 1px;
    color: #8f8f8f;
    font-weight: 500;
  }

  .btm-blockfdm {
    padding: 20px 0px;
  }

  .btm-blc-fdm-hdr h3 {
    font-size: 17px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .other-input-txt {
    padding: 20px;
  }

  .other-input-txt textarea {
    font-size: 13px;
  }

  .btn-primary-sc {
    background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
  }

  .act-oth-act {
    text-align: center;
    margin: 20px 0 0 0;
  }

  .checked-ico {
    padding: 10px;
    display: block;
  }

  .checked-ico img {
    width: 32px;
  }

  .d-none {
    display: none;
  }

  .headind-area-thk {
    position: relative;
  }

  .fdback-btn {
    position: absolute;
    right: 30px;
    cursor: pointer;
    top: -15px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #a9a9a9;
    padding: 5px 9px;
    border-radius: 4px;
    text-align: left;
  }

  .fdback-btn span {
    display: block;
    font-size: 11px;
    font-weight: 100;
    color: #403e3e;
  }

  .fdback-btn strong {
    font-weight: 500;
  }

  .fdback-btn img {
    width: 15px;
    margin-left: 3px;
  }

  .wizard {
    display: grid;
    width: 100%;
  }

  .process {
    box-model: border-box;
    align-self: center;
    justify-self: center;
    padding: 30px 0;
    width: 100%;
    padding-left: 39px;
  }

  .process ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .process ul li.step .name {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 500;
    color: #939393;
    padding-left: 31px;
    min-height: 36px;
  }

  .process ul li span {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    background: #fff;
    position: absolute;
    border: 3px solid #e6ecfc;
    font-size: 11px;
    font-weight: 600;
    color: #525252;
    left: 0;
    text-align: center;
  }

  .process .step {
    position: relative;
  }

  .process ul li.step.current img {
    position: absolute;
    left: -29px;
    top: -5px;
  }

  .process ul li.step.completed .name {
    color: #005c9f;
  }

  .process ul li.step.completed span {
    background: #005c9f;
    color: #fff;
    text-align: center;
    border-color: #005c9f;
  }

  .process ul li.step.current .name {
    color: #005c9f;
    font-weight: 600;
  }

  .process ul li.step.current span {
    background: #005c9f;
    box-shadow: 0 0 18px #93ccf6;
    border-color: #005c9f;
    color: #fff;
    animation: 1750ms linear infinite pulse;
  }

  .process ul li.step:not(:last-child):after {
    height: 35px;
    width: 2px;
    content: "";
    display: block;
    background-color: #ddd;
    margin-left: 9px;
    margin-top: -8px;
    margin-bottom: 8px;
  }

  .process ul li.step.completed:not(:last-child):after {
    background-color: #005c9f;
  }

  .process.done ul li.step .name {
    color: #33673b;
  }

  .process.done ul li.step .name:before {
    background: #33673b;
    box-shadow: none;
    content: "✓";
    color: white;
    font-size: 0.8em;
    text-align: center;
  }

  .process.done ul li.step:not(:last-child):after {
    background-color: #33673b;
    box-shadow: 0 0 8px #88c492;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 2px #164d74;
    }

    80% {
      box-shadow: 0 0 18px #164d74;
    }

    100% {
      box-shadow: 0 0 2px #164d74;
    }
  }

  .mobil-arrow {
    display: none;
  }

  @media screen and (max-width: 1366px) {
    .stp-8 li:not(:last-child):after {
      left: 31%;
    }

    .stp-4 li:not(:last-child):after {
      left: 16%;
    }
  }

  .mb-wiz {
    display: none;
  }

  .mob-act-lnk {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .more-items-ls {
      position: static;
    }

    .action-buttons {
      max-width: 162px !important;
      width: 100% !important;
    }

    .mobil-arrow {
      display: block;
      position: absolute;
      right: 28px;
      margin-top: -48px;
    }

    .mob-act-lnk {
      display: block;
    }

    .mb-wiz {
      display: block;
    }

    .pcl-head h3 {
      margin-bottom: 0px;
    }

    .stp-wizard-elm {
      display: none;
    }

    .top-banner-thk {
      margin-top: 44px;
      width: 100%;
      height: 135px;
      margin-bottom: 15px;
    }

    .main-block {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 5px;
    }

    .headind-area-thk h1 {
      font-size: 25px;
      margin-top: -28px;
    }

    .headind-area-thk h5 {
      font-size: 18px;
    }

    .fdback-btn span {
      font-size: 10px;
    }

    .fdback-btn {
      position: inherit;
      right: 0px;
      cursor: pointer;
      top: -36px;
      font-size: 10px;
      font-weight: 600;
      border: 1px solid #7ba2c1;
      padding: 3px 5px;
      border-radius: 4px;
      background: #ffffff;
      color: #0451be;
      margin: 0px auto;

      margin-left: 12px;
    }

    .headind-area-thk {
      padding-top: 24px;
    }

    .top-banner-thk img {
      height: 110px;
      margin-top: -97px;
    }

    .thank-pr-block {
      padding: 0px;
    }

    .thankt-ord-tp {
      background: #fff;
    }

    .ls-item-thk {
      padding-bottom: 15px;
    }

    .thk-right {}

    .rightsr-blk-itn {
      border-top: 1px solid #d1e3f4;
      padding-left: 0px;
      border-bottom: 1px solid #d1e3f4;
      margin-top: 13px;

      background: #f3f9ff;
    }

    .rightsr-blk-itn .ls-item-thk {
      padding-top: 12px;
      padding-bottom: 8px;
    }

    .sr-list-items {
      width: initial !important;
    }

    .mob-act-lnk .rghtpclc {
      margin-top: 0px;
      padding-bottom: 14px;
    }

    .rghtpclc ul li strong {
      font-size: 12px;
    }

    .mob-act-lnk {
      border-bottom: 1px solid #d1e3f4;
    }

    .svlist {
      position: relative;
    }

    .ls-item-thk span {
      font-size: 13px;
    }

    .thk-sr-price {
      font-size: 13px;
    }

    .mob-act-lnk .rghtpclc ul {
      gap: 0;
      width: 100%;
    }

    .rghtpclc ul li {
      width: 50%;
    }

    .stg-head-txt {
      padding: 10px 0px;
    }

    .stg-head-txt h2 {
      font-size: 14px;
      text-align: left;
      padding-left: 15px;
      padding-right: 25px;
      color: #484949;
    }

    .pclc-bar .rghtpclc {
      display: none;
    }

    .pc-lc-elm {
      padding: 13px;
    }

    .note-element-tp {
      padding: 13px;
      margin-top: 0px;
    }

    .pclc-bar {
      position: relative;
    }

    .tm-lb {
      position: absolute;
      right: 0pc;
      top: -27px;
    }

    .quote-request-date .tm-lb {
      position: static;
    }

    .lf-pclc i {
      display: none;
    }

    .ds-ref-tp-block h3 {
      font-size: 20px;
      font-weight: 600;
    }

    .ds-ref-tp-block p {
      font-size: 15px;
      font-weight: 500;
    }

    .ds-friends-image img {
      position: initial;
      margin-bottom: -29px;
      margin-top: 50px;
      width: 100%;
    }

    .share-action {
      width: 150px;
      margin: 15px auto;
      padding: 13px;
      margin-top: 70px;
    }

    .cs-gp-shr {
      flex-wrap: wrap;
      gap: 0;
    }

    .ds-ref-code-bl {
      width: 100%;
    }

    .ds-cpy-txt {
      margin-top: -117px;
      text-align: center;
    }

    .gapft {
      gap: 25px;
    }

    .rt-dl {
      width: 316px;
    }
  }

  .action-buttons {
    border-radius: 5px;
    border: 1px solid #b7ceea;
    background: #f8fbff;
    width: 183px !important;
    height: 67px !important;
    justify-content: center;
  }

  .confirmation-tag {
    background-color: #0451be;
    color: #fff;
  }

  .btn-pending {
    background-color: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
  }

  .header-rt-block {
    padding-top: 20px;
  }

  .header-rt-block button {
    position: absolute;
    top: 10px;
    right: 14px;
  }

  .header-rt-block h3 {
    font-size: 13px;
    color: #4d4f57;
    padding-bottom: 14px;
    text-align: center;
  }

  .header-rt-block strong {
    font-weight: 600;
  }

  .header-rt-block h5 {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    padding-bottom: 12px;
  }

  .checked-ico {
    padding: 10px;
    display: block;
  }

  .checked-ico img {
    width: 32px;
  }

  .mid-block-frm {
    padding-bottom: 45px;
    border-bottom: 1px solid #ddd;
  }

  .rd-list input[type="radio"] {
    display: none;
  }

  .rd-list {
    display: flex;
    gap: 15px;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  .rd-list label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 13px;
    min-width: 37px;
    height: 37px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #d2d8df;
  }

  .gapft {
    gap: 27px;
  }

  .rd-list input[type="radio"]:checked+label {
    color: #ffffff;
    color: #4189e0;
    border-color: #4189e0;
  }

  .wrd-radiolst {
    display: flex;
    gap: 15px;
    position: relative;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
  }

  .rdlist input[type="checkbox"] {
    display: none;
  }

  .rdlist label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 13px;
    min-width: 37px;
    height: 37px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #d2d8df;

    padding: 5px 7px;
    font-size: 13px;
    border-width: 1px;
    color: #8f8f8f;
    font-weight: 500;
  }

  .gapft {
    gap: 27px;
  }

  .rdlist input[type="checkbox"]:checked+label {
    color: #ffffff;
    color: #4189e0;
    border-color: #4189e0;
  }

  .header-rt-block {
    padding-top: 20px;
  }

  .header-rt-block button {
    position: absolute;
    top: 10px;
    right: 14px;
  }

  .header-rt-block h3 {
    font-size: 13px;
    color: #4d4f57;
    padding-bottom: 14px;
    text-align: center;
  }

  .header-rt-block strong {
    font-weight: 600;
  }

  .header-rt-block h5 {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    padding-bottom: 12px;
  }

  .rt-dl img {
    width: 16px;
  }

  .rt-dl {
    position: absolute;
    display: flex;
    bottom: -23px;
    font-size: 12px;
    justify-content: space-between;
    width: 364px;
  }

  .nt-sat {
    color: #c41818;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 11px;
  }

  .ys-sat {
    color: #26c726;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .wrd-radio-lst {
    flex-wrap: wrap;
    gap: 8px;
  }

  .wrd-radio-lst label {
    padding: 5px 7px;
    font-size: 13px;
    border-width: 1px;
    color: #8f8f8f;
    font-weight: 500;
  }

  .btm-blockfdm {
    padding: 20px 0px;
  }

  .btm-blc-fdm-hdr h3 {
    font-size: 17px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .other-input-txt {
    padding: 20px;
  }

  .other-input-txt textarea {
    font-size: 13px;
  }

  .btn-primary-sc {
    background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
  }

  .act-oth-act {
    text-align: center;
    margin: 20px 0 0 0;
  }

  element.style {}

  .modal.show .modal-dialog {
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out, -moz-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out,
      -moz-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -moz-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }

  @media (max-width: 768px) {
    .gapft {
      gap: 15px;
    }

    .rt-dl {
      width: 300px;
    }

    .btm-blc-fdm-hdr h3 {
      font-size: 14px;
    }

    .rating-modal .modal-dialog {
      margin: 5px !important;
    }

    .rdlist .form-check {
      padding-left: 0;
    }
  }
}

.qr-code-dashboard {
  background: #0451be;
  padding: 6px;
  border-radius: 3px;
}

.quote-request-button {
  height: 47px;
  font-weight: bold;
  border-radius: 50px;
}