
/*=============================================
=            product tab            =
=============================================*/

.product-tab-navigation {
  .nav-item {
    .nav-link {
      font-weight: 500;

      border-radius: 0;
      &.active {
        color: $theme-color--default;
        background-color: transparent;
      }
    }
  }

  &--style-two {
    @include respond (extra-small-mobile) {
      justify-content: center;
    }
    .nav-item {
      .nav-link {
        @include respond (small-mobile) {
          padding-right: 5px;
          padding-bottom: 0;
        }
      }
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }
      &:last-child {
        .nav-link {
          padding-right: 0;
        }
      }
    }
  }
}

/*=====  End of product tab  ======*/

