/*=============================================
=            category            =
=============================================*/

.category-overlap {
  position: relative;
  z-index: 1;

  margin-top: -70px;
  padding: 30px;

  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 0 10px rgba($black, 0.2);

  .slider-nav-wrapper {
    .ht-swiper-button-nav {
      font-size: 25px;
      line-height: 40px;

      position: absolute;
      z-index: 9;

      width: 60px;
      height: 60px;
      padding: 0;
      border-radius: 30px;
      opacity: 0;
      color: $black--six;
      border: none;
      border-radius: 4px;
      background: none;
      background-color: $white;
      box-shadow: 0 0 10px rgba($black, 0.1);

      &:hover {
        background-color: $theme-color--default;

        i {
          color: $white;
        }
      }

      i {
        font-size: 17px;

        color: $black--six;
      }

      &.swiper-button-prev {
        left: -10;

        &:after {
          display: none;
        }
      }

      &.swiper-button-next {
        right: -10;

        &:after {
          display: none;
        }
      }
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      opacity: 1;

      &.swiper-button-prev {
        left: 35px;
      }

      &.swiper-button-next {
        right: 35px;
      }
    }
  }
}

.input-overlap {
  position: relative;
  z-index: 1;
  margin-top: -30px;
  padding: 10px;
  border-radius: 35px;
  background-color: $white;
  border: 2px solid rgba(0, 114, 188, 0.5);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}

.inputService {
  border: none;
  height: 40px;
  width: 70%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.categories-box {
  text-align: center;

  a {
    display: block;

    // padding: 35px 20px;

    // background-color: $grey--five;

    @include respond (extra-small-mobile) {
      padding: 20px 10px;
    }
  }

  i {
    font-size: 40px;

    display: block;
  }

  span {
    display: block;

    margin-top: 5px;

    text-transform: capitalize;

    @include respond (extra-small-mobile) {
      font-size: 14px;
    }
  }

  &--style-two {
    a {
      padding: 0;

      background-color: transparent;
    }
  }
}

.item-box {
  text-align: center;

  a {
    display: block;

    padding: 35px 20px;

    background-color: transparent;

    @include respond (extra-small-mobile) {
      padding: 20px 10px;
    }
  }

  i {
    font-size: 15px;

    display: block;
  }

  span {
    display: block;

    margin-top: 5px;

    text-transform: capitalize;

    @include respond (extra-small-mobile) {
      font-size: 14px;
    }
  }

  &--style-two {
    a {
      padding: 0;

      background-color: transparent;
    }
  }

}

.item-overlap {
  position: relative;
  z-index: 1;

  .slider-nav-wrapper-new {
    position: initial;

    .ht-swiper-button-nav-new {
      font-size: 25px;
      line-height: 40px;

      position: absolute;
      z-index: 9;

      width: 40px;
      height: 40px;
      padding: 0;

      opacity: 0;
      color: $black--six;
      border: none;
      border-radius: 4px;
      background: none;
      background-color: $white;
      box-shadow: 0 0 10px rgba($black, 0.1);

      &:hover {
        background-color: $theme-color--default;

        i {
          color: $white;
        }
      }

      i {
        font-size: 17px;

        color: $black--six;
      }

      &.swiper-button-prev-new {
        left: 0;

        &:after {
          display: none;
        }
      }

      &.swiper-button-next-new {
        right: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  &:hover {
    .ht-swiper-button-nav-new {
      opacity: 1;

      &.swiper-button-prev {
        left: 35px;
      }

      &.swiper-button-next-new {
        right: 35px;
      }
    }
  }
}

.select {
  border: 2px solid #0451be;
  border-radius: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.obImage {
  position: relative;
  width: 400px;
  height: 300px;
  padding: 4px;
  border-radius: 15px;
  resize: none;
}

.rowRec {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.discount {
  position: absolute;
  top: 60px;
  background: #fff;
  height: 35px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  align-self: center;
  padding: 5px;
  padding-left: 5px;
  color: #F58B1F;
}

.user {
  padding: 5px;
  position: absolute;
  top: 240px;
  margin-left: 2%;
  background: #fff;
  border-radius: 15px;
  align-items: center;
}

.card-partners {
  border-radius: 20px;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  background: #fff;
  padding: 3px;
}

.selected {
  background: #fff;
  border-radius: 15px;
  width: 100%;
}

.selected:hover {
  border: 2px solid #0451be;
}

.mobile-stick {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
  margin-left: -20px;
}

.app-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.googleImg {
  position: absolute;
  left: -150px;
  top: -100px;
  width: 400px;
  height: 400px;
  z-index: -1;
}

.brandsImg {
  position: absolute;
  left: 0px;
  top: -200px;
  width: 550px;
  height: 650px;
  z-index: -1;
}


// .cardRev:hover {
//   height: 350px;
//   border: 3px solid #0451be;
//   transition: all ease 0.25s;
// }

.rent-center {
  height: 600px;
  width: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.rent-center-col {
  height: 600px;
  width: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-rent {
  width: 110px;
  height: 450px;
  margin: 5px;
  background: #fff;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  overflow: hidden;
  transition: all ease 1s;
}

.card-rent:hover {
  width: 310px;
  margin: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.card-rentN {
  width: 270px;
  height: 450px;
  margin: 5px;
  background: #fff;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  overflow: hidden;
  transition: all ease 0.25s;
}

.card-rentN-col {
  width: 100%;
  height: 250px;
  margin: 5px;
  background: #fff;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  overflow: hidden;
  transition: all ease 0.25s;
}

.img-col {
  display: flex;
  flex-direction: column;
}

.img-card {
  width: 270px;
  height: 450px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.img-card-col {
  width: 100%;
  height: 250px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.card-rent-col {
  width: 100%;
  height: 60px;
  margin: 5px;
  background: #fff;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  overflow: hidden;
  transition: all ease 1s;
}

.card-rent-col:hover {
  width: 100%;
  height: 250px;
  margin: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.card-offer {
  height: 100%;
  width: '100%';
  background: #529890;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px;
  margin: 10px;
  box-shadow: 0px 4.64117px 18.5647px rgba(158, 158, 158, 0.25);
}


.card-offer-one {
  height: 100%;
  width: "100%";
  background: #4771DE;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px;
  margin: 10px;
  box-shadow: 0px 4.64117px 18.5647px rgba(158, 158, 158, 0.25);
}




.sm-card {
  width: 100%;
  background: #FFF200;
  color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.sm-card-one {
  width: 100%;
  background: #fff;
  color: #000;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.profile {
  width: 60px;
  height: 60px;
}

.star {
  width: 23px;
  height: 23px;
}

.job-box {
  text-align: center;
  width: auto;

  a {
    display: block;


    background-color: transparent;

    @include respond (extra-small-mobile) {
      padding: 20px 10px;
    }
  }

  i {
    font-size: 15px;

    display: block;
  }

  span {
    display: block;

    margin-top: 5px;

    text-transform: capitalize;

    @include respond (extra-small-mobile) {
      font-size: 14px;
    }
  }

  &--style-two {
    a {
      padding: 0;

      background-color: transparent;
    }
  }

}

.job-overlap {
  position: relative;
  z-index: 1;

  .slider-nav-wrapper-job {
    position: initial;

    .ht-swiper-button-nav-job {
      font-size: 25px;
      line-height: 40px;

      position: absolute;
      z-index: 9;

      width: 40px;
      height: 40px;
      padding: 0;

      opacity: 0;
      color: $black--six;
      border: none;
      border-radius: 4px;
      background: none;
      background-color: $white;
      box-shadow: 0 0 10px rgba($black, 0.1);

      &:hover {
        background-color: $theme-color--default;

        i {
          color: $white;
        }
      }

      i {
        font-size: 17px;

        color: $black--six;
      }

      &.swiper-button-prev-job {
        left: 0;

        &:after {
          display: none;
        }
      }

      &.swiper-button-next-job {
        right: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  &:hover {
    .ht-swiper-button-nav-job {
      opacity: 1;

      &.swiper-button-prev-job {
        left: 35px;
      }

      &.swiper-button-next-job {
        right: 35px;
      }
    }
  }
}

.jobImage {
  width: 400px;
  height: 250px;
  padding: 4px;
  border-radius: 15px;
}

.JobTitle1 {
  position: absolute;
  bottom: 20px;
  margin-left: 6%;
}

.JobTitle {
  position: absolute;
  margin-left: 6%;
  bottom: 40px;
}

/*=====  End of category  ======*/