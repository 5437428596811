/* third party styles */
@import "~bootstrap/scss/bootstrap";
@import "~swiper/css/swiper.css";
@import "~simple-line-icons/scss/simple-line-icons.scss";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-slidedown/lib/slidedown.css";
@import "../css/flaticon.css";

/* project styles */
@import "abstracts/variables";
@import "abstracts/mixins";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";
@import "base/reset";

@import "components/button";
@import "components/newsletter";
@import "components/icon-box";
@import "components/testimonial";
@import "components/banner";
@import "components/hero-slider";
@import "components/section-title";
@import "components/product-thumb";
@import "components/product-slider";
@import "components/product-modal";
@import "components/product-tab";
@import "components/breadcrumb";
@import "components/product-image-gallery";
@import "components/product-description";
@import "components/empty-area";
@import "components/team";
@import "components/pagination";
@import "components/brand-logo";
@import "components/countdown";
@import "components/image-slider";
@import "components/category";
@import "components/_tyre-comparison";
@import "components/workshop-detail";
@import "components/dashboard";
@import "components/product-listing";
@import "components/_cart_island";
@import "components/quotation_master_item";
@import "components/_order_download_popup";

@import "layouts/footer";
@import "layouts/grid";
@import "layouts/header";
@import "layouts/sidebar";

@import "pages/cart";
@import "pages/compare";
@import "pages/checkout";
@import "pages/order-complete";
@import "pages/my-account";
@import "pages/login-register";
@import "pages/terms";
@import "pages/not-found";
@import "pages/faq";
@import "pages/contact";
@import "pages/blog";
@import "pages/shop";
@import "pages/about_us";
@import "pages/garage_marketing";
@import "pages/_thank_you.scss";
@import "pages/_sign_up.scss";
@import "./common.scss";
@import "./animations.scss";
