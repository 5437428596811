body {
    padding-top: 0px;
}

.bg-transparent {
    background-color: transparent !important;
}

.contact-section {
    background-image: url('/assets/garage/header-img.webp');
    background-size: cover;
    background-position: center;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 15px;
}

.grgtp-links {
    .navbar-nav {
        .nav-item {
            .nav-link.selling-btn {
                display: none;
                background: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
                height: 43px;
                border-radius: 6px;
                padding: 10px 20px !important;
                color: #fff !important;
                font-size: 15px;
                font-weight: 500;
            }
        }

        .nav-item {
            .nav-link {
                color: #000 !important;

                span {
                    padding-left: 5px;
                }
            }
        }
    }
}

.whatsapp-right {
    display: flex;
}

.mob-num {
    width: 70%;
    padding-right: 15px;
}

.vdeo-blok {
    padding: 0px 30px;
}

.contact-info-light {
    padding: 15px 0px 0px;
}

.whatsapp-left {
    display: flex;
}

.whatsapp-txt {
    padding-left: 20px;
    color: #fff;
}

.whatsapp-txt h5 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
    color: white;
}

.whatsapp-txt span {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}


.activeNumber {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid rgb(121, 182, 252);
    background-color: rgb(228, 238, 250);
    margin-right: 1.8rem;
    color: rgb(39, 129, 231);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    animation-name: bounceIn;
    /* Change to your desired animation name */
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

@keyframes bounceIn {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}



.custom-header-control {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 1rem 1rem;
}

.gr-contact-info {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    margin-right: 70px;
    color: white;
}

.mob-num input {
    border: 0px;
    border-radius: 45px;
    height: 45px;
    width: 100%;
    padding-left: 90px;
    font-size: 15px;
}

.mob-num span {
    position: absolute;
    top: 12px;
    color: #404344;
    font-size: 15px;
    left: 64px;
}

.cm-orange-btn {
    color: #fff;
    background: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
    height: 45px;
    border-radius: 45px;
    padding: 10px 20px;
    font-weight: 600;
}

.whatsapp-container {
    background: #0451be;
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 30px;
}

.gr-contact-form {
    background-color: #2A3238;
    padding: 30px;
    margin-top: 20px;
    box-shadow: 0px 4px 5.3px 0px rgba(0, 0, 0, 0.44);
}

.grgtp-links {
    ul {
        align-items: center;
        column-gap: 10px;
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: #000 !important;

                span {
                    padding-left: 5px;
                }
            }
        }
    }
}

.grg-title {
    font-size: 32px;
    font-weight: 600;
}

.gr-contact-form {
    h4 {
        font-weight: 700;
        font-size: 21px;
        padding-bottom: 10px;
        color: #fff;
    }

    .form-label {
        margin-bottom: 3px;
        color: #dfdfdf;
        font-size: 14px;
        font-weight: 300;
    }

    .form-control {
        border-color: #ffffff;
        height: 40px;
        box-shadow: 0px 0px 0px 0px;
    }
}


.navbar-fixed {
    top: 0;
    z-index: 100;
    position: fixed;
    width: 100%;
    transition: position 10s;

    .selling-btn {
        display: block !important;
    }
}

.navbar {
    transition: position 10s;
}


.mobile-num-input {
    position: relative;

    span {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 5px;
        opacity: .7;
        font-size: 14px;
        top: 10px;
        left: 10px;
        border-right: 1px solid #ccc;
        padding-right: 10px;

        img {
            width: 13px;
        }
    }

    input {
        padding-left: 70px;
    }
}

.gps-location {
    position: relative;

    span {
        position: absolute;
        right: 5px;
        top: 6px;

        img {
            width: 29px;
        }
    }
}

.submit-btn-contrl {
    padding-top: 10px;

    .btn-orange-sbtn {
        background: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
        color: #fff;
        width: 100%;
        text-align: center;
        font-weight: 600;
        height: 40px;
    }

    .submit-btn {
        padding: 0;
        padding-top: 5px;
    }
}

.abt-txt-grg {
    background: #E8EEF5;
    padding: 0px 15px;
}

.abt-mrk-section {
    padding: 30px 0px;

    p {
        font-size: 15px;
        line-height: 30px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

.abt-platform-grg {
    padding: 70px 15px 25px;

    .grg-head-block {
        padding-bottom: 50px;

        h2 {
            font-weight: 700;
            font-size: 32px;
        }
    }

    .platform-grg-element {
        h2 {
            font-weight: 700;
            font-size: 45px;
            color: #2B66D9;

            span {
                display: inline-block;
                padding-right: 5px;
            }
        }

        h3 {
            color: #424A55;
            font-size: 22px;
            font-weight: 700;
            min-height: 56px;
        }

        p {
            color: #666666;
            font-size: 15px;
            line-height: 26px;
        }
    }
}

.partnership-grg-section {
    padding: 70px 15px;
}

//

.main-footer {
    position: relative;
    overflow: visible;
    background-color: #f3f4ff;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    margin-top: 80px;
    z-index: 3;
}

.ftlogo-bar {
    position: absolute;
    top: -55px;
    left: 45px;
}

.ftlogo-bar img {
    border-radius: 50%;
    width: 151px;
}

.footer-top {
    padding: 110px 0 50px;
    box-sizing: border-box;
}

.ft-header {
    font-size: 24px;
    font-weight: 700;
    color: #0451be;
    padding-top: 10px;
    padding-bottom: 25px;
}



.contact-info-light li i {
    padding-right: 10px;
}

.fa-solid,
.fas {
    font-weight: 900;
}

.fa-brands,
.fab {
    font-weight: 400;
}

.style-ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.footer-links-bl li a {
    font-size: 15px;
    color: #231f20;
}

.grg-head-block {
    padding-bottom: 50px;
}

.contact-info-light li span {
    color: #0451be;
    font-size: 18px;
}

.elem-bordered {
    border-radius: 10px;
    height: 100%;
    border: 1px solid #DFE1E5;
    padding: 15px;
    margin-bottom: 20px;
    background: #FFF;
    box-shadow: 0px 0px 14.9px -3px rgba(84, 130, 255, 0.25);

    span {
        padding-bottom: 10px;
        height: 54px;
        display: flex;
        align-items: center;
    }

    h3 {
        font-size: 17px;
        font-weight: 600;
    }

    p {
        font-size: 15px;
        color: #525252;
        line-height: 25px;
        margin-bottom: 0px;
    }
}

.cst-offring-block {
    .col-md-4 {
        margin-bottom: 40px;
    }
}

.stories-section {
    background: linear-gradient(180deg, #343A40 62.5%, #6C757D 100%);
    padding: 60px 15px;
}



.image-layer-about {
    width: 100%;
    height: 264px;
    border-radius: 10px;
    background-image: url('/assets/garage/video.png');
    background-size: cover;
    position: relative;

    &.second-vdeo-image {
        background-image: url('/assets/garage/video2.png');
    }
}

#playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background-image: url('/assets/garage/play.svg');
    text-align: center;
    cursor: pointer;
}

.video-elm {
    background-size: cover;
    display: none;
    width: 100%;
    height: 264px;
    border-radius: 10px;
    object-fit: cover;
}

.test-cl-img {
    display: flex;
    gap: 10px;
    color: #fff;

    >span {
        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 45px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    label {
        display: flex;
        flex-direction: column;

        span {
            font-size: 15px;
            font-weight: 600;
        }

        strong {
            color: #d1d1d1;
            font-weight: 400;
            font-size: 15px;
        }
    }
}

.vdeo-bottom-block {
    p {
        color: #d1d1d1;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        padding-bottom: 10px;
        padding-top: 20px;
    }
}

.border-right-mrk {
    border-right: 1px dashed #788087;
}

.video-elm-div {
    position: relative;
    display: none;
}

#pauseButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background-image: url('/assets/garage/pause.svg');
    text-align: center;
    cursor: pointer;
}

//   
.modal-close-button {
    position: absolute;
    right: 23px;

    .close {
        color: #fff !important;
    }
}

.mobile-sl-btn {
    display: none;
}

.signup--gr-block {
    padding: 60px 15px;
}

.grg-spg-ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding-bottom: 60px;

        h3 {
            font-size: 17px;
            color: #3e4043;
            margin-bottom: 0;
            left: 30px;
            padding-left: 52px;
        }

        span {
            width: 42px;
            line-height: 42px;
            font-weight: 600;
            height: 42px;
            border: 1px solid #3e4043;
            border-radius: 50%;
            background: #eaeaea;
            text-align: center;
            position: absolute;
        }
    }
}



.fODnTg {
    font-size: 24px;
    line-height: 32px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(39, 129, 231) !important;
    animation-name: bcCCNc;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    font-weight: 500;
}

@keyframes bcCCNc {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.jyyoOs {
    font-size: 24px;
    line-height: 32px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(54, 54, 54);
    animation-name: none;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    font-weight: 500;
}

.ggxxHo {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid rgb(156, 156, 156);
    background-color: transparent;
    margin-right: 1.8rem;
    color: rgb(54, 54, 54);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    animation-name: none;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

.dOeUIS {
    height: 36px;
    width: 36px;
    border-radius: 50% !important;
    border: 1px solid rgb(27, 128, 242) !important;
    background-color: rgb(125, 176, 238);
    margin-right: 1.8rem;
    color: rgb(39, 129, 231) !important;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    animation-name: bcCCNc;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}




.slider-block-spn-bl img {
    width: 490px;
}

.slider-block-spn {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;

    &-tp {
        position: absolute;
        z-index: 11;
        left: -5px;
    }

    &-bl {
        position: absolute;
        top: 0;
        z-index: 10;
    }
}

.activespg span {
    background: #e5edfa !important;
    // color: #0451be;
    border: 1px solid #03080d !important;
}

.activespg h3 {
    // color: #0451be !important;
    font-weight: 600 !important;
}

.query-action {
    text-align: center;
    padding-top: 100px;

    button {
        border: 2px solid #0451be;
        border-radius: 5px;
        background: #fff;
        outline: 0;
        padding: 5px 30px;

        span {
            display: block;
            color: #ff8f21;
            font-weight: 500;
        }

        label {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0451be;
            margin-top: -6px;
        }
    }
}

.map-block {
    background: #e9edf2;
    padding: 60px 0;

    .map-element {
        border-radius: 20px;
        overflow: hidden;
        border: 2px solid #fff;
    }

    .mnbtm {
        padding-bottom: 30px;
    }

    .vist-str {
        text-align: right;
        padding: 20px 0;

        .vst-str-btn {
            border: 1px solid #ff3201;
            background: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
            border-radius: 30px;
            padding: 10px 30px;
            color: #fff;
        }
    }

    .mob-map {
        display: none;
    }

    .mobile-form-div {
        display: none;
    }


}

.mob-num img {
    position: absolute;
    top: 8px;
    width: 30px;
    left: 26px;
}



.mob-num {
    width: 70%;
    padding-right: 15px;
}

.social-icons-ft li a {
    color: #505050;
    padding-right: 15px;
}



// 
@media screen and (max-width: 767px) {
    .gr-contact-info {
        margin-top: 20px;
    }

    .gr-contact-info {
        margin-right: 0px;
    }

    .mobile-form-div {
        display: block;
    }

    .query-action {
        padding-top: 20px;
    }

    .map-block,
    .signup--gr-block {
        padding: 30px 0 !important;
    }

    .mob-map {
        display: block;
        height: 286px;
        object-fit: cover;
        width: 100%;
    }

    .dsk-map {
        display: none;
    }

    .grg-spg-ul li {
        padding-bottom: 40px;

        span {
            line-height: 39px;
            width: 39px;
            height: 39px;
        }

        h3 {
            font-size: 16px;
        }
    }

    .slider-block-spn-tp {

        height: 350px;
        position: absolute;
        width: 100%;

        left: initial;
    }

    .slider-block-spn-bl img {
        width: 100%;
    }

    .slider-block-spn {
        height: 275px;

        &-tp,
        &-bl {
            img {
                width: 300px;
            }
        }
    }


    .req-an-btn-view {
        background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
        padding: 10px 15px;
        color: #fff;
        border: 0px;
        outline: 0;
        border-radius: 20px;
        margin-right: 10px;
        margin-left: 5px;
    }


    .mb-sl-none {
        display: none !important;
    }

    .custom-header-control .navbar-brand {
        padding-left: 0;
    }

    .mobile-sl-btn {
        display: block;
        top: 16px;
        position: absolute;
        right: 62px;

        .selling-btn {
            background: linear-gradient(0deg, rgba(247, 146, 34, 1) 0%, rgba(255, 50, 1, 1) 100%);
            height: 30px;
            border-radius: 4px;
            padding: 5px 13px !important;
            color: #fff !important;
            font-size: 13px;
            font-weight: 500;
        }
    }

    .mobscrl-bar {
        border-radius: 10px;
        padding: 10px;
        border: 1px solid #dfe1e5;
        background: #fff;
        box-shadow: 0 0 14.9px -3px rgba(84, 130, 255, 0.25);
        max-height: calc(100vh - 131px);
        overflow-x: hidden;
        overflow-y: scroll;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ddd;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #2b66d9;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #2b66d9;
        }
    }

    .tp-bar-elm {
        display: flex;
        align-items: center;
        column-gap: 10px;

        span img {
            width: 35px;
        }
    }

    .elem-bordered {
        padding: 5px;
        box-shadow: 0px 0px 0px 0px #fff;
        border: 0;
        margin-bottom: 10px;
        border-radius: 0;
        border-bottom: 1px solid #ddd;
    }

    .cst-offring-block .col-md-4 {
        margin-bottom: 0;
    }

    .platform-grg-element p {
        font-size: 14px;
        line-height: 23px;
    }

    .platform-grg-element {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
    }

    .partnership-grg-section {
        padding: 10px 0 30px;
    }

    .custom-header-control {
        padding: 10px;
    }

    .border-right-mrk {
        border-right: 0;
    }



    .stories-section {
        padding: 30px 15px;
    }

    // .slick-prev:before,
    // .slick-next:before {
    //     content: url('/assets/garage/prev.svg') !important;
    // }

    // .slick-prev {
    //     left: -16px !important;
    // }

    // .slick-next {
    //     right: -16px !important;
    // }

    // .slick-prev,
    // .slick-next {
    //     top: 32% !important;
    // }


}