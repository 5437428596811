.ds-bl-gradient {
  border-radius: 15px;
  margin-bottom: 15px;
  background: linear-gradient(180deg, #0c1892 0%, #0451be 100%);
  height: 145px;
  padding: 10px;
}



.single-chart {
  width: 75px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.5;
}

.circle {
  fill: none;
  stroke-width: 3.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.show_offer-mobile {
  display: none !important;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}



.circular-chart.blue .circle {
  stroke: #0DF54E;
}

.percentage {
  fill: #fff;
  font-weight: 600;
  font-size: 9px;
  text-anchor: middle;
}

.ds-user-lft {
  display: flex;
  color: #fff;
  align-items: center;
}

.ds-user-lft h2 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.ds-user-lft h4 {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.ds-cashpt-fixed {
  position: absolute;
  background: #E3F4FD;
  display: flex;
  left: 5px;
  height: 50px;
  bottom: -7px;
  padding: 5px 27px 5px 8px;
  border-radius: 0 0 0 13px;
}

.relative-path {
  position: relative;
}

.ds-cashpt-fixed label span {
  font-size: 14px;
  font-weight: 300;
  display: block;
  color: #2F2F2F;
}

.ds-cashpt-fixed label strong {
  font-weight: 600;
  font-size: 15px;
  color: #004EA9;
  display: block;
  margin-top: -4px;
}

.ds-cashpt-fixed i {
  padding-right: 5px;
  display: inline-block;
}

.ds-cashpt-fixed small {
  width: 0;
  height: 0;
  border-top: 50px solid #E3F4FD;
  border-right: 32px solid transparent;
  position: absolute;
  right: -32px;
  top: 0px;

}

.ds-missing-info {
  list-style: none;
  margin: 20px 0px;
  padding: 0px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
}

.ds-missing-info li {
  font-size: 13px;
  font-weight: 400;
  display: block;
  padding: 5px 0px;
  width: 50%;
}

.false-icon {
  background: url(/assets/dashboard/cross.svg)no-repeat;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 6px;
}

.true-icon {
  background: url(/assets/dashboard/tick.svg)no-repeat;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 6px;
}

.ds-arrow-right {
  background: url(/assets/dashboard/arrow-right.svg)no-repeat;
  width: 15px;
  height: 9px;
  display: inline-block;
}

.ds-act-header {
  display: flex;
  justify-content: space-between;
}

.ds-act-header h3 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
}

.ds-act-header a {
  color: #0451be;
  font-size: 13px;
  font-weight: 500;
}

.ds-act-header {
  padding: 10px 0px;
}

.ds-clock {
  background: url(/assets/dashboard/clock.svg)no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 3px;
}

.ds-ac-job-widget {
  border: 1px solid #D5E2EB;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.ds-tp-bl {
  display: flex;
  justify-content: space-between;
}

.ds-tp-bl label {
  font-size: 14px;
  font-weight: 500;
}

.ds-tp-bl label strong {
  color: #000;
  font-weight: 500;
}

.ds-tp-bl label span {
  color: #66788D;
}

.ds-clock-block {
  font-size: 13px;
  font-weight: 500;
  color: #BEBEBE;
  display: flex;
  align-items: center;
}

.ds-act-jb-car-block {
  justify-content: space-between;
  padding: 10px 0px;
}

.ds-act-jb-car-block-lf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ds-act-jb-car-block-rg {
  display: flex;
  justify-content: space-between;
  width: 45%;
}

.s-act-jb-pl span,
.s-act-jb-pymt span {
  display: block;
  font-size: 13px;
  color: #808080;
}

.s-act-jb-pl strong {
  font-size: 13px;
  font-weight: 500;
  color: #313541;
}

.ds-cr-br h2 {
  font-size: 15px;
  padding: 0px;
  margin: 0px;
  font-weight: 600;
}

.ds-cr-br h4 {
  font-size: 16px;
  color: #0451be;
  font-weight: 500;
}

.ds-cr-br {
  padding-left: 10px;
}

.s-act-jb-pymt strong {
  color: #CE7C00;
  font-weight: 500;
  font-size: 13px;
}

.ds-sr-lst-bar {
  border-top: 1px solid #D5E2EB;
  padding: 10px 0px 0;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.ds-sr-lst {
  display: flex;
  width: 70%;
}

.ds-sr-lst ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.ds-sr-lst ul li {
  white-space: nowrap;
  width: 100%;
  padding: 0px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ds-dropdown-more {}

.ds-dropdown-more .ds-dropdowntoggle {
  background: #E2ECF5;
  border: 0px;
  outline: 0px;
  padding: 2px 6px;
  cursor: pointer;
}

.ds-drp-menu-item {
  font-size: 12px;
}

.ds-or-am strong {
  color: #808080;
  font-weight: 500;
}

.ds-or-am span {
  font-weight: 600;
  color: #0451be;
}

.ds-by-offer {
  background: url(/assets/dashboard/offer-bg.png);
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  background-size: cover;
}

.ds-offer-hd {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 30px;
}

.ds-offer-hd h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  padding-left: 7px;
  margin: 0px;
}

.ds-by-count-outer {
  background: #fff;
  width: 200px;
  line-height: 35px;
  margin: 0px auto;
  position: relative;

  border-radius: 12px;
}

.ds-by-count-outer {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}

.ds-by-count-outer img {
  position: absolute;
  right: 0px;
}

.ds-by-count-outer label {
  display: block;
  margin: 0px;
  font-weight: 500;
  padding-right: 20px;
}

.ds-by-count-outer strong {
  display: block;
  background: #F2752F;
  color: #fff;
  font-weight: 600;
  padding-right: 20px;
}

.ds-by-count-outer span {
  color: #F2752F;
  font-weight: 600;
  padding-right: 20px;
}

.btn-yellow {
  background: #F9F828;
  color: #000;
  margin-bottom: 20px;
  border-radius: 20px;
  font-weight: 600;
  padding: 7px 22px;
  border-radius: 30px;
  font-size: 15px;
}

.ds-yellow-ob {
  background: #FFF2C2;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}

.ds-yellow-inner label span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.ds-yellow-inner label strong {
  border-radius: 5px;
  line-height: 17px;
  border: 1px solid #FFDF00;
  background: #FFDF00;
  padding: 2px 10px;
  display: inline-block;
}

.ds-yellow-inner label {
  padding-left: 20px;
  padding-top: 8px;
}

.ds-yellow-ob .action-anchor-ds {
  position: absolute;
  right: 13px;
  color: #0451be;
  font-size: 13px;
  font-weight: 500;
  top: 14px;
}

.ds-yellow-inner {
  display: flex;
  align-items: center;
}

.bl-rel-smry {
  background: #D2F4FF;
  padding: 15px;
  border-radius: 15px;
}

.bl-rel-smry-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.bl-rel-smry-head h3 {
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
}

.bl-rel-smry-cnt {
  display: flex;
  column-gap: 5px;
  border-radius: 10px;
}

.bl-rel-smry-cnt label {
  background: #fff;
  padding: 15px 10px;
  font-size: 13px;
  width: 100%;
  border-radius: 10px;
}

.bl-rel-smry-cnt label span {
  display: block;
  font-weight: 600;
}

.ds-rd-bl {
  color: #8C2B2B;
}

.ds-bl-bl {
  color: #4551B0;
}

.ds-gr-bl {
  color: #0FA679;
}

.bl-rel-smry-head a {
  color: #0451be;
  font-size: 13px;
  font-weight: 500;
}


.ds-orange-block {
  background: linear-gradient(180deg, #ff3201 0%, #f79222 100%);
  border-radius: 15px;
  padding: 15px;
  margin-top: 35px;
}

.ds-ref-tp-block {
  color: #fff;
}

.ds-ref-tp-block h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.ds-ref-tp-block p {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
}

.ds-ref-tp-block {
  padding-top: 10px;
}

.ds-ref-code-bl {
  display: flex;
  background: #fff;
  border-radius: 100px;
  justify-content: space-between;
  width: 300px;
  align-items: center;
  padding: 8px;
}

.ds-ref-code-bl label i {
  border: 0px;

  margin-top: -6px;
  outline: 0px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 4px;
  font-size: 20px;
  display: block;
  overflow: hidden;
}

.ds-ref-code-bl label {
  padding-left: 30px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.ds-ref-code-bl p {
  text-align: center;
  padding: 0px 20px 0 0;
  margin: 0px;
  font-size: 12px;
  color: #0F8514;
  border-left: 1px solid;
  padding-left: 9px;
}

.ds-ref-code-bl p span {
  display: block;
}

.ds-friends-image img {
  position: absolute;
  max-width: initial;
  width: 359px;
  right: 0px;
  bottom: -82px;
}

.ds-cpy-txt {
  color: #fff;
  font-size: 13px;
  padding-top: 10px;
  margin: 0px;
}

.no-job-list {
  text-align: center;
}

.no-job-list h3 {
  font-size: 20px;
  color: #5e6d7c;
}

.yellowbtn-ds {
  background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
  padding: 10px 36px;
  border: 0px;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .show_offer-mobile {
    display: block !important;
  }

  .hide_offer-mobile {
    display: none !important;
  }

  .hide-side-mobile {
    display: none;
  }

  .ds-cpy-txt {
    text-align: center;
  }

  .ds-sr-lst ul {
    width: 86%;
  }

  .ds-clock-block {
    margin-top: -7px;
  }

  .ds-act-jb-car-block-lf {}

  .ds-bl-gradient {
    height: auto;
  }

  .ds-cashpt-fixed {
    position: relative;
    width: 86%;
    border-radius: 0px;
    margin-bottom: 20px;
  }

  .ds-act-jb-car-block {
    flex-wrap: wrap;
  }

  .ds-tp-bl {
    flex-wrap: wrap;
  }

  .ds-act-jb-car-block-rg {
    width: 100%;
    padding-top: 20px;
  }

  .ds-sr-lst-bar {
    flex-wrap: wrap;
  }

  .ds-sr-lst {
    flex-wrap: wrap;
    width: 100%;
  }

  .main-block {
    padding: 0px;
  }

  .ds-by-offer {
    margin-top: -12px;
    margin-bottom: 10px;
  }

  .ds-by-offer-custom {
    margin-bottom: 0px !important;
  }

  .ds-offer-hd-custom {
    padding: 0px 10px 14px !important;
  }

  .ds-yellow-inner i img {
    width: 45px;
  }

  .ds-yellow-inner label span {
    font-size: 15px;
  }

  .ds-yellow-ob .action-anchor-ds {
    bottom: 10px;
    top: inherit;
  }

  .bl-rel-smry-cnt {
    flex-wrap: wrap;
  }

  .ds-friends-image img {
    position: initial;
    width: 100%;
  }

  .ds-missing-info {
    margin: 0px;
    flex-wrap: wrap;
    display: flex;
  }

  .ds-missing-info li {
    padding: 5px 8px;
    width: 50%;
    display: flex;
  }

  .ds-sr-lst ul li {
    padding-left: 0px;
  }

  .true-icon,
  .false-icon {
    margin-top: 2px;
    width: 15px;
  }

  .ds-or-am {
    padding-top: 7px;
  }

  .ds-ref-code-bl {
    width: initial;
  }

  .bl-rel-smry-cnt label {
    display: flex;
    justify-content: space-between;
  }
}