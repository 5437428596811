.sidebar-crt-skin {
  background: #fff;
  height: 100vh;
}

.popover {
  max-width: none;
}

.position-relative-pr {
  position: relative !important;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.position-relative-pr-tyre {
  position: relative !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 31px 30px 1px 19px;
}

.position-relative-pr label {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: #535353;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px;
  right: 0px;
  bottom: 0px;
}

.position-relative-pr-tyre label {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: #535353;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px;
  right: 0px;
  bottom: 0px;
}

.fa-clock-cs {
  padding-right: 3px;
}

.fa-clock-cs img {
  width: 14px;
}

.product-image-container-pl {
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.product-image-container-pl-tyre {
  object-fit: fill;
  width: 141px;
  height: 140px;
  border-radius: 10px;
}

.listing-container-pl {}

.tyreLogo {
  left: 4px;
  position: absolute;
  top: -11px;
  width: 109px;
}

.tyreMYear {
  max-width: 60px;
  font-size: 10px;
  left: 10px;
  position: absolute;
  bottom: 14px;
}

::-webkit-scrollbar {
  display: none;
}

.tyreWarrenty {
  right: 10px;
  position: absolute;
  bottom: 10px;
}

.left-pr-main {
  padding: 15px 0px;
}

.pl-list-outer {
  border: 1px solid #d5e2eb;
  padding: 20px 15px 0px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
}

.pl-list-scoller {
  max-height: calc(100vh - 450px);
  padding-bottom: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  margin-bottom: 20px;
}

/* width */
.pl-list-scoller::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.pl-list-scoller::-webkit-scrollbar-track {
  background: #f3f4ff;
}

/* Handle */
.pl-list-scoller::-webkit-scrollbar-thumb {
  background: #0451be;
}

/* Handle on hover */
.pl-list-scoller::-webkit-scrollbar-thumb:hover {
  background: #125199;
}

.border-pl-list {
  border: 1px solid #d5e2eb;
  padding: 13px 15px 5px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.pl-fl-lst {
  padding-top: 5px;
  min-height: 104px;
}

.pl-fl-box {
  display: flex;
  width: 100%;
  position: relative;
}

.pl-fl-img {
  position: absolute;
}

.pl-fl-ft-sec {
  padding-left: 220px;
  width: 100%;
}

.pl-fl-lst ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.pl-fl-lst ul li {
  font-size: 12px;
  font-weight: 500;
  width: 50%;
  color: #434343;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.pl-fl-lst ul li i {
  padding-right: 8px;
}

.product-section-pl-hd-flex {
  display: flex;
  justify-content: space-between;
}

.product-section-pl-hd-flex label {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.product-section-pl-hd-flex label span {
  margin: 0px 4px;
  display: inline-block;
  margin-top: 2px;
}

.rec-lab {
  color: #09a018;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #09a018;
  padding: 2px 8px;
  border-radius: 3px;
}

.free-lab {
  margin-left: 5px;
  color: #83690c;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #83690c;
  padding: 2px 8px;
  border-radius: 3px;
}

.product-section-pl-hd-flex h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}

.mnf-yrs {
  font-size: 12px;
  color: #434343;
  font-weight: 500;
  margin-top: -6px;
}

.pl-fl-box {
  padding: 10px 15px;
}

.pl-fl-lst label {
  color: #3a63b8;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.pl-feture-first {
  display: flex;

  width: 100%;
  align-items: center;
  padding-top: 10px;
  justify-content: space-between;
  padding-bottom: 10px;
}

.pl-feture-first-space {
  display: flex;

  width: 100%;
  align-items: center;
  padding: 0px 20px !important;
}

.pl-offer-label {
  background: #ffe5cb;
  font-size: 12px;
  font-weight: 600;
  color: #ff3201;
  cursor: pointer;
  padding: 3px 10px;
  line-height: 25px;
  border-radius: 3px;
  height: 32px;
  position: relative;
  padding-left: 30px;
}

.pl-offer-label img {
  width: 21px;
  position: absolute;
  left: 4px;
  top: 5px;
}

.pl--tl-workshop {
  font-size: 12px;
  color: #3a63b8;
  font-weight: 600;
  padding: 10px;
}

.oft-lbs {
  display: flex;
  align-items: center;
}

.oft-lbs label {
  margin-bottom: 0px;
}

.chk-label {
  display: block;
  position: relative;
  padding-left: 21px;
  color: #616670;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chk-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.chk-label:hover input~.check-mark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.chk-label input:checked~.check-mark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chk-label input:checked~.check-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chk-label .check-mark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.price-pl-blck {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0px 3px;
  border-top: 1px dashed #aeb5bd;
  align-items: center;
}

.price-tag-pl p {
  margin-bottom: 0px;
}

.price-tag-pl {
  margin-bottom: 0px;
}

.price-tag-pl span {  
  line-height: 15px;
  display: block;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
}

.dst-price-item {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.price-tag-pl strong {}

.fitting-checkbox .chk-label {
  color: #232323;
}

.fitting-checkbox .check-mark {
  top: 4px;
}

.fitting-checkbox small {
  padding-left: 22px;
  font-size: 9px;
  margin-top: -2px;
  display: block;
  color: #757373;
  font-weight: 600;
}

.price-pl-rght {
  display: flex;
  gap: 10px;
  align-items: center;
}

.srv-act-action {
  background: #000;
  font-size: 13px;
  border: 1px solid #000;
  cursor: pointer;
  padding: 0px 25px;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
  height: 32px;
  color: #ffff;
  font-weight: 500;
  line-height: 25px;
  outline: 0;
}

.srv-act-action-sl {
  background: linear-gradient(0deg,
      rgba(247, 146, 34, 1) 0%,
      rgba(255, 50, 1, 1) 100%);
  font-size: 13px;
  border: 1px solid #ff3201;
  cursor: pointer;
  padding: 0px 26px;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
  height: 32px;
  color: #ffff;
  font-weight: 500;
  line-height: 25px;
  outline: 0;
}

.srv-act-action:hover {
  background: #fff;
  color: #000;
}

.select-active {
  border-bottom: 3px solid #ff3201;
  color: #ff3201 !important;
}

.pl-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.car-select {
  background: none !important;
  color: #3a63b8 !important;
  cursor: pointer !important;
}

.tire-select {
  background: none !important;
}

.tire-banner-imgae {
  width: 100%;
  object-fit: fill;
  height: 330px;
}

.tyre-banner {
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
}

.tire-not-select {
  background: none !important;
}

.pl-list-header-lf h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.pl-list-header-lf p {
  color: #808080;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.pl-list-header-rt button {
  background: #e7efff;
  color: #3a63b8;
  font-size: 10px;
  border: 1px solid #e7efff;
  cursor: pointer;
  // padding: 5px 20px;
  font-weight: 500;
  border-radius: 20px;
}

.pl-list-header {
  border-bottom: 1px solid #d5e2eb;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.pl-list-outer {
  position: relative;
}

.view-comparison-btn {
  position: fixed;
  top: 93vh;
  left: 53%;
  z-index: 10;
  cursor: pointer;
  border-radius: 55px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  outline: 0px;
  padding: 7px 20px;
  color: #fff;
  background: linear-gradient(0deg,
      rgba(247, 146, 34, 1) 0%,
      rgba(255, 50, 1, 1) 100%);
  margin-left: -106px;
}

.view-comparison-btn-disabled {
  position: fixed;
  top: 93vh;
  left: 50%;
  z-index: 10;
  border-radius: 55px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  outline: 0px;
  padding: 7px 20px;
  color: #fff;
  background: grey;
  margin-left: -106px;
}

.srolled-list-pl {
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tyre-fet-usr {
  background: #f1f3f8;
  padding: 15px;
  border-radius: 10px;
  margin: 30px 0px 14px;
}

.tyre-fet-usr h3 {
  color: #3a63b8;
  font-weight: 700;
  font-size: 18px;
}

.tyre-fet-usr ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  gap: 4px;
}

.tyre-fet-usr ul li {
  border: 1px solid #42567d;
  background: #fff;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
  padding: 3px 10px;
  font-weight: 500;
  align-items: center;
}

.tyre-fet-usr ul li img {
  width: 14px;
  margin-top: -2px;
}

.tyre-fet-usr ul li span {
  padding-left: 2px;
}

.custompr-col {
  padding-top: 20px;
}

.oil-brand-sl {
  border: 1px solid #3a63b8;
  position: relative;
  height: 32px;
  border-radius: 4px;
  width: 129px;
}

.oil-brand-sl span {
  position: absolute;
  background: #fff;
  font-size: 11px;
  font-weight: 600;
  top: -8px;
  left: 5px;
  color: #3a63b8;
  padding: 0px 5px;
}

.blink-service .listing-container-pl {
  border-color: #97b6cb;
}

.oil-brand-sl strong {
  color: #3a63b8;
  font-weight: 500;
  font-size: 12px;
  line-height: 34px;
  padding-left: 15px;
  width: 123px;
  cursor: pointer;
  display: inline-block;
}

.blink-service .oil-brand-sl span {
  background: #f3f4ff;
}

.oil-brand-sl i {
  position: absolute;
  right: 13px;
  font-size: 12px;
  top: 11px;
  color: #3a63b8;
}

.oil-brand-modal-list {
  margin: 0px;
  padding: 0px;
}

.oil-brand-modal-list li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.oil-brand-modal-name h2 {
  font-size: 18px;
  margin: 0px;
  color: #0451be;
}

.oil-brand-modal-name span {
  color: #8d8d8d;
  font-size: 11px;
  font-weight: 400;
}

.oil-br-prz span {
  font-size: 12px;
  font-weight: 500;
  color: #5f5f5f;
  display: block;
}

.oil-br-prz label {
  font-weight: 700;
  color: #000;
  margin: 0px;
  font-size: 13px;
}

.oil-brand-modal-name {
  width: 40.33%;
}

.oil-br-prz {
  width: 26.33%;
}

.oil-br-sl {
  width: 33.33%;
  text-align: right;
}

.mobile-pl-bar-action {
  display: none;
}

.ofr-slider-ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.ofr-slider-inner-pl {
  border-radius: 10px;
  position: relative;
  border: 1px dashed #e97e14;
  background: #fff5ea;
  color: #e96e00;
  margin-bottom: 10px;
  padding: 0px 10px;
}

.ofr-slider-inner-pl p {
  font-size: 12px;
  font-weight: 500;
  padding-top: 9px;
  color: #e96e00;
  margin-bottom: 1rem;
  line-height: inherit;
}

label.mob-compare {
  display: none;
}

.tabby-bar-pr-list {
  display: flex;
  align-items: center;
  margin-top: -7px;
}

.tabby-bar-pr-list img {
  width: 40px;
  max-height: "22px";
  margin-right: 3px;
}

.tabby-bar-pr-list p {
  font-size: 11px;
  color: #868686;
}

.tabby-bar-pr-list p strong {
  font-weight: 600;
  color: #484848;
}

.tabby-bar-pr-list p svg {
  cursor: pointer;
}

.cst-badge-sc {
  color: #119122;
  border: 1px solid #0f9320;
  font-weight: 500;
  font-size: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 1px 6px 1px 7px;
  border-radius: 6px;
  margin-bottom: 12px;
}

.sr-contract-tabby {
  margin-top: -7px !important;
}

.custompr-col {
  padding-right: 0px;
}

.sc-custom-margin {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
  .pl-list-header-tyre {
    margin-left: 0px !important;
  }

  .position-relative-pr-tyre {
    padding: 32px 28px 4px 10px;
  }

  .tire-banner-imgae {
    width: 100%;
    height: 220px !important;
  }

  .product-image-container-pl {
    width: 140px;
    height: 140px;
  }

  .tyreWarrenty {
    right: 3px !important;
  }

  .tyreMYear {
    left: 3px;
    bottom: 6px;
  }

  .tyreLogo {
    top: -7px;
    width: 90px;
  }

  .product-image-container-pl-tyre {
    width: 105px;
    height: 105px;
  }

  .price-tag-pl strong {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .mobile-pl-bar-action .price-tag-pl {
    display: flex;
    flex-direction: column;
  }

  .custompr-col {
    padding-right: 15px;
  }

  .product-area-new .custompr-col {
    padding-right: 0px;
    padding-left: 0px;
  }

  .mobstyle {
    font-size: 20px;
  }

  .mobstyle .workshop-title {
    font-weight: 700;
    font-size: 20px;
    display: block;
  }

  .pl-fl-lst {
    min-height: 139px;
  }

  .price-tag-pl strong {
    font-size: 16px;
  }

  .oft-lbs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pl-offer-label {
    font-size: 11px;
  }

  .free-lab,
  .rec-lab {
    font-size: 10px;
  }

  .lbl-flex {
    display: flex;
    align-items: center;
  }

  label.mob-compare {
    display: block;
    padding-top: 0px !important;
    margin-left: 10px;
  }

  .mobile-bottom-sr-select {
    display: flex;
    width: 100%;
    justify-content: end;
    padding-top: 8px;
    border-top: 1px solid #ccc;
    align-items: center;
    margin-top: 10px;
  }

  .mobile-pl-bar-action {
    display: block;
  }

  .dsk-tabby {
    display: none;
  }

  .mobile-pl-bar-action {
    padding: 0px 15px;
    width: 100%;
  }

  .mobile-pl-bar-action .pl-feture-first {
    padding: 0px;
  }

  .mobile-pl-bar-action .pl-offer-label {
    padding-left: 10px;
  }

  .mobile-pl-bar-action .price-tag-pl {
    padding-right: 10px;
  }

  .tyre-fet-usr ul {
    flex-wrap: wrap;
  }

  .pl-list-header-rt button {
    font-size: 9px;
    // padding: 5px 10px;
  }

  .dsk-view {
    display: none;
  }

  .pl-list-scoller {
    max-height: initial;
    overflow-y: initial;
    overflow-x: initial;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .pl-fl-lst ul li i {
    display: none;
  }

  .pl-fl-lst ul li {
    width: 100%;
  }

  .pl-fl-ft-sec {
    padding-left: 154px;
  }

  .custom-height-sec {
    min-height: initial !important;
  }

  .mobile-pl-sr-cont-mg {
    padding: 0px !important;
  }

  .pl-list-header {
    flex-wrap: wrap;
  }

  .pl-list-header-rt {
    // padding-top: 6px;
    display: flex;
  }

  .product-section-pl-hd-flex {
    padding-bottom: 2px;
    flex-wrap: wrap;
  }

  .product-section-pl-hd-flex label span {
    margin: 0px;
    margin-right: 5px;
  }

  .product-section-pl-hd-flex label {
    padding-top: 5px;
  }

  .srolled-list-pl:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .border-pl-list {
    padding-bottom: 16px;
    padding-top: 12px;
  }

  .cst-badge-sc {
    color: #0f9320;
    background-color: #f4fff9;
    font-weight: 500;
    font-size: 10px;
    display: inline-flex;
    flex-wrap: wrap;

    padding: 1px 6px 1px 7px;
    border-radius: 6px;
    margin-bottom: 12px;
    letter-spacing: -0.3px;
  }

  .pl-list-header {
    margin-bottom: 6px;
  }

  .product-section-pl-hd-flex h3 {
    font-size: 16px;
  }

  .mob-tabby p {
    font-size: 10px;
    letter-spacing: -0.2px;
  }

  .tabby-bar-pr-list img {
    width: 35px;
  }

  .tabby-bar-pr-list {
    margin-top: 0px;
  }
}
@media screen and (max-width: 400px) {
  .pl-feture-first-space2 {
    margin-left: 15px !important;
  }
  .pl-feture-first-space {
    padding: 0px 15px !important;
  }
  .cst-badge-sc {
    font-size: 9px;
  }
}

.custom-nav-link {
  color: #000;
  /* Default color for non-active items */
}

/* Custom style for active Nav.Link item */
.custom-nav-link.active {
  color: #f00;
  /* Change the color of the active item */
}

/* Custom style for the underline */
.custom-nav .nav-link {
  border-bottom: 2px solid #f00;
  /* Change the color of the underline */
}

/* Optional: Add hover effect */
.custom-nav .nav-link:hover {
  border-bottom: 2px solid #00f;
  /* Change the color of the underline on hover */
}

.tyre-fixed-header {
  position: fixed;
  top: 110px;
  padding-top: 10px;
  background-color: #fff;
  z-index: 1;
}

.package-list-empty {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 500;
  border: 1px solid gray;
  border-radius: 15px;
}