/*=============================================
=            order complete            =
=============================================*/

.order-complete {
  svg {
    font-size: 80px;

    margin-bottom: 20px;

    color: $theme-color--default;
  }
}

.pay-now-btn-a-order {
  margin-top: 28px;
}

@media screen and (max-width: 450px) {
  .pay-now-btn-a-order {
    margin-top: 0px;
  }
  .apply-active-offer .slick-track {
    left: 0 !important;
  }
} /*=====  End of order complete  ======*/


.apply-active-offer {
  border: 2px solid orange;
  border-radius: 5px;
  margin: 0px;
  padding-bottom: 15px;
}
